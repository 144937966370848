import { FC, ReactNode, forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for VuiSwitch
import MDSwitchRoot from "components/MDSwitch/MDSwitchRoot";

const MDSwitch: any = forwardRef(({ color, size, ...rest }: any, ref) => <MDSwitchRoot {...rest} ref={ref} color="white" size={size} ownerState={{ color, size }} />);

// Setting default values for the props of VuiSwitch
MDSwitch.defaultProps = {
    size: "large",
    color: "white",
};

// Typechecking props for the MDSwitch
MDSwitch.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    color: PropTypes.oneOf(["white", "primary", "secondary", "info", "success", "warning", "error", "light", "dark"]),
};

export default MDSwitch;
