import { Skeleton, Stack, Tab, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { createTheme } from "@mui/material/styles";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import colors from "assets/theme-dark/base/colors";
import AddIcon from "@mui/icons-material/Add";
import MyTab from "./Tab";
import DeleteIcon from "@mui/icons-material/Delete";
import { context } from "utils/Provider";
import { scrollbar } from "utils/scrollBar";
import { default as MuiTabs } from "@mui/material/Tabs";
import MDBox from "components/MDBox";
import { testIds } from "utils/testIds";
import { Pipeline } from "../../utils/types";
import { addOpacityToColor } from "utils/addOpacityToColor";

function Tabs(props: {
    pipelines: Pipeline[];
    handleChange: (value: number) => void;
    saveNew: () => void;
    saveExistingHeader: (id: string, name: string, cruxyId: string) => void;
    deletePipeline: () => void;
    pipelineIndex: number;
}) {
    const useStyles: any = makeStyles((theme: any) => ({
        tabs: {
            "& button": {
                minWidth: "160px",
            },
        },
    }));
    const classes = useStyles();

    const { pipelines, handleChange, saveNew, saveExistingHeader, deletePipeline, pipelineIndex } = props;
    const [selected, setSelected] = useState(0);
    const { selectedCompany, isLoading } = useContext(context);

    useEffect(() => {
        setSelected(0);
    }, [selectedCompany]);

    useEffect(() => {
        setSelected(pipelineIndex);
    }, [pipelineIndex]);

    return (
        <Stack
            flexDirection={{ xxl: "row", lg: "row", md: "column-reverse", xs: "column-reverse" }}
            sx={{ background: "transparent", perspective: "100px" }}
        >
            <MDBox
                sx={{
                    width: { xxl: "50%", lg: "50%", md: "100%", xs: "100%" },
                }}
            >
                <MuiTabs
                    value={selected}
                    //TabIndicatorProps={{ style: { background: "transparent", color: colors.white.main } }}
                    disableRipple
                    sx={{
                        background: "transparent",
                        backgroundColor: "transparent",
                        ".MuiTabs-scrollButtons": {
                            color: colors.white.main,
                            fontWeight: 600,
                            background: "transparent",
                            backgroundColor: "transparent",
                        },
                        ".MuiTabs-root": {
                            fontWeight: 600,
                            height: "3rem",
                        },
                        ".MuiTabs-flexContainer": {
                            height: "3rem",
                        },

                        padding: 0,
                    }}
                    className={classes.tabs}
                    variant={pipelines.length > 3 || true ? "scrollable" : "standard"}
                    scrollButtons
                    allowScrollButtonsMobile
                >
                    {pipelines?.length > 0 && !isLoading ? (
                        pipelines.map((pipeline: Pipeline, index: number) => {
                            return (
                                <MyTab
                                    last={index === pipelines.length - 1}
                                    key={pipeline.pipelineId}
                                    classes={classes}
                                    handleChange={handleChange}
                                    index={index}
                                    id={pipeline.pipelineId}
                                    name={pipeline.name}
                                    selected={index === selected}
                                    saveNew={saveNew}
                                    setSelected={setSelected}
                                    saveExistingHeader={saveExistingHeader}
                                />
                            );
                        })
                    ) : (
                        <Skeleton
                            animation={"wave"}
                            variant="rectangular"
                            sx={{ backdropFilter: colors.primary.main }}
                            width={"10rem"}
                            height={"4rem"}
                        />
                    )}
                </MuiTabs>
            </MDBox>

            <MDBox
                sx={{
                    display: "flex",
                    marginLeft: "auto",
                    alignItems: "flex-end",
                    justifyContent: { xxl: "start", lg: "start", md: "space-between", xs: "space-between" },
                    width: { xxl: "auto", lg: "auto", md: "100%", xs: "100%" },
                    borderBottom: { xxl: "auto", lg: "auto", md: "auto", xs: `3px solid ${colors.background.default}` },
                }}
            >
                <STab
                    data-testid={testIds.newPipeBtn}
                    style={{
                        //width: "100%",
                        minWidth: "10rem",
                        borderRight: `3px solid ${colors.background.default}`,
                        background: "rgba(255, 255, 255, 0.2)",
                        boxShadow: "0 4px 30px rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(15.9px)",
                        WebkitBackdropFilter: "blur(15.9px)",
                        fontSize: "1rem",
                        color: addOpacityToColor(colors.primary.main, 0.8),
                    }}
                    onClick={saveNew}
                >
                    <AddIcon fontSize="medium" />
                    New pipeline
                </STab>
                <STab
                    data-testid={testIds.deletePipeBtn}
                    style={{
                        //width: "100%",
                        minWidth: "10rem",
                        fontSize: "1rem",
                        color: addOpacityToColor(colors.primary.main, 0.8),
                        borderRight: `3px solid ${colors.background.default}`,
                        background: "rgba(255, 255, 255, 0.2)",
                        boxShadow: "0 4px 30px rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(15.9px)",
                        WebkitBackdropFilter: "blur(15.9px)",
                        display: pipelines.length === 0 ? "none" : "flex",
                    }}
                    onClick={deletePipeline}
                >
                    <DeleteIcon fontSize="medium" />
                    Delete pipeline
                </STab>
            </MDBox>
        </Stack>
    );
}

export default Tabs;
const STab = styled.div`
    width: 6rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;
