import { ReactNode, useEffect, useState } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { TextField, Tooltip } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import { Duration } from "graphql/types/graphql";
import { testIds } from "utils/testIds";
import { PageData } from "../../utils/types";

interface Props {
    props: any;
    darkMode: boolean;
    currentlyEditing: boolean;
    updatePageData: (object: Partial<PageData>) => void;
    removeCard: any;
    board: any;
    getColumn: any;
    handleDuration: (value: Duration) => void;
    handleConversionRate: (value: { stageId: string; conversion: number }) => void;
    handleInstanceName: (id: string, name: string) => void;
    isConversionVisible: number;
}

function Card(prop: Props): JSX.Element {
    const {
        props,
        darkMode,
        currentlyEditing,
        handleInstanceName,
        handleDuration,
        handleConversionRate,
        updatePageData,
        isConversionVisible,
    } = prop;
    const [value, setValue] = useState(props.template);
    const theme = colors;

    useEffect(() => {
        switch (isConversionVisible) {
            case 0:
                setValue(props.template);
                break;
            case 1:
                setValue(props.value2 * 100);
                break;
            case 2:
                setValue(props.value3);
                break;
        }
    }, [isConversionVisible]);

    const handeSetFormValue = ({ currentTarget }: any) => {
        setValue(currentTarget.value);
        if (props.isConversion) {
            return;
        } else if (props.column === "instances") {
            handleInstanceName(props.id, currentTarget.value);
        } else {
            if (isConversionVisible === 1) {
                handleDuration({
                    duration: props.template,
                    pipelineInstanceId: props.pipelineInstanceId,
                    stageId: props.stageId,
                    conversionRate: currentTarget.value / 100,
                    numberOfDeals: props.value3,
                });
            } else if (isConversionVisible === 0) {
                handleDuration({
                    duration: parseInt(currentTarget.value),
                    pipelineInstanceId: props.pipelineInstanceId,
                    stageId: props.stageId,
                    conversionRate: props.value2,
                    numberOfDeals: props.value3,
                });
            } else {
                handleDuration({
                    duration: props.template,
                    pipelineInstanceId: props.pipelineInstanceId,
                    stageId: props.stageId,
                    conversionRate: props.value2,
                    numberOfDeals: parseInt(currentTarget.value),
                });
            }
        }
    };

    function outputHandler() {
        if (isConversionVisible === 1 && props.column !== "instances") {
            const twoDecimalValue = typeof props.value2 === "number" ? (props.value2 * 100).toFixed(0) : props.value2;

            return props?.template?.length > 8 ? props.template.substring(0, 8) + "..." : twoDecimalValue;
        } else if (isConversionVisible === 0) {
            return props?.template?.length > 8 ? props.template.substring(0, 8) + "..." : props?.template;
        } else {
            return props?.value3?.length > 8 ? props?.value3?.substring(0, 8) + "..." : props?.value3;
        }
    }

    return (
        <MDBox
            data-testid={testIds.card}
            onDoubleClick={() =>
                updatePageData({
                    currentlyEditing: isConversionVisible === 2 ? "" : props.ownId,
                })
            }
            key={JSON.stringify(props)}
            bgColor={darkMode ? "transparent" : "white"}
            borderRadius="xl"
            mt={2.5}
            mb={props.isConversion ? 5 : 0}
            width="150px"
            height="50px"
            textAlign={"center"}
            sx={{
                fontSize: ({ typography: { size } }: any) => size.md,
                display: props.isConversion ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
                visibility: !props.isFirst ? "visible" : "hidden",
                color: colors.white.main,
                //border:'1px solid white'
                //textOverflow: "clip !important",
            }}
        >
            {currentlyEditing &&
            props.column !== "total" &&
            props.column !== "instances" &&
            Boolean(!props.isConversion || props.column !== "instances") ? (
                <div
                    style={{
                        width: "60%",
                        minHeight: "50px",
                        border: !props.isConversion ? `2px solid rgba(255, 255, 255, 1)` : "none",
                        boxShadow: `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px",
                        backgroundColor: "transparent",
                    }}
                >
                    <TextField
                        inputProps={{ "data-testid": testIds.cardInput }}
                        type="number"
                        sx={{ width: "80%" }}
                        value={value}
                        onChange={handeSetFormValue}
                        variant="standard"
                    />
                </div>
            ) : (
                <Tooltip
                    title={
                        isConversionVisible === 1 && props.column !== "instances"
                            ? (props.value2 * 100).toFixed(0)
                            : isConversionVisible === 0
                            ? props.template
                            : props.value3
                    }
                    sx={{ width: `58ch` }}
                    placement="top"
                >
                    <div
                        style={{
                            width: "120px",
                            height: "50px",
                            border: !props.isConversion ? `2px solid rgba(255, 255, 255, 1)` : "none",
                            borderRadius: "15px",
                            boxShadow: `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                            textOverflow: "ellipsis !important",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            paddingTop: "12.5px",
                            paddingBottom: "12.5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                        }}
                    >
                        {outputHandler()}
                    </div>
                </Tooltip>
            )}
        </MDBox>
    );
}

export default Card;
