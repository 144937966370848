import { useContext, useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { context } from "utils/Provider";
import {
    InvoiceLog,
    useInvoiceLogDatesQuery,
    useInvoiceLogsLazyQuery,
    useInvoiceLogsQuery,
} from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import colors from "assets/theme-dark/base/colors";
import { MenuItem, TextField, Typography } from "@mui/material";
import { scrollbar } from "utils/scrollBar";
import DateCard from "./DateCard";
import MatchesTable from "./MatchesTable";
import ChangesColumn from "./ChangesColumn";

const titleStyle = {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: colors.primary.main,
    textAlign: "center",
    marginBottom: "2rem",
};

function Logs({ dates }: { dates: { original: string; formatted: string }[] }): JSX.Element {
    const { setIsLoading, selectedCompany } = useContext(context);

    const [dateIndex, setDateIndex] = useState(0);
    const [matchesIndex, setMatchesIndex] = useState(0);

    const [matches, setMatches] = useState<InvoiceLog[]>();
    const [errorMess, setErrorMess] = useState<string | undefined>();

    const { data, loading, error } = useInvoiceLogsQuery({
        variables: { cruxyId: selectedCompany.cruxyId, date: dates[dateIndex].original },
    });

    useEffect(() => {
        if (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            console.log(error);
        }

        if (data) {
            data.invoiceLogs;
            prepareMatches(data.invoiceLogs.companies);
            setErrorMess(undefined);
        }
    }, [error, data]);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setDateIndex(0);
        setMatchesIndex(0);
    }, [selectedCompany]);

    function prepareMatches(invoiceLogs: InvoiceLog[]) {
        if (invoiceLogs.length === 0) {
            setErrorMess("No logs were found for the selected date!");
            return;
        } else {
            setMatches(invoiceLogs);
        }
    }

    return (
        <MDBox
            sx={{
                backgroundColor: "transparent",
                width: "100%",
            }}
        >
            {errorMess && (
                <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", color: colors.white.main }}>
                    {errorMess}
                </Typography>
            )}
            {matches && (
                <>
                    <TextField
                        size="small"
                        sx={{
                            width: "12rem",
                            minWidth: "140px",
                            marginBottom: "1rem",
                            marginTop: "1rem",
                        }}
                        value={dates[dateIndex].formatted}
                        id="outlined-select-currency"
                        select
                        label="Dates"
                    >
                        {dates.map((item: { original: string; formatted: string }, index: number) => {
                            return (
                                <MenuItem
                                    key={item.formatted}
                                    onClick={() => {
                                        setDateIndex(index);
                                    }}
                                    value={item.formatted}
                                >
                                    {item.formatted}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    <MDBox
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
                            //border: "1px solid green",
                            flex: 1,
                        }}
                    >
                        <MDBox
                            sx={
                                {
                                    //maxHeight: "700px",
                                    //border: "1px solid white",
                                }
                            }
                        >
                            <Typography sx={titleStyle}>Matches</Typography>
                            <MDBox
                                sx={{
                                    overflow: "auto",
                                    ...scrollbar,
                                    marginBottom: "1.5rem",

                                    borderRadius: 0,
                                }}
                            >
                                <MatchesTable
                                    matchesIndex={matchesIndex}
                                    matches={matches}
                                    setMatchesIndex={setMatchesIndex}
                                />
                            </MDBox>
                        </MDBox>
                        <MDBox
                            sx={{
                                position: "relative",
                                //maxHeight: "700px",
                                //border: "1px solid white",
                            }}
                        >
                            <Typography sx={titleStyle}>Changes</Typography>
                            <ChangesColumn buttonVisible={false} changes={matches[matchesIndex].changes} />
                        </MDBox>
                    </MDBox>
                </>
            )}
        </MDBox>
    );
}

export default Logs;
