import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDBox from "components/MDBox";
import colors from "assets/theme-dark/base/colors";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "@mui/material";
import MDButton from "components/MDButton";
import cruxy from "assets/images/cruxy-icon.svg";
import { CrmTables, useEditCompanyMutation, useEditDealMutation } from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import { scrollbar } from "utils/scrollBar";
import ScrollBar from "react-perfect-scrollbar";
import PerfectScrollbar from "react-perfect-scrollbar";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px",
    width: "40%",
    maxHeight: "80vh",
    //minWidth: "25rem",
    overflowY: "scroll",
    background: colors.background.state,
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    opacity: 1,
    ...scrollbar,
};

export default function TransitionsModal(props: {
    open: boolean;
    handleClose: () => void;
    selectedRow: any;
    column: any;
    customColumns: string[];
    currentTable: CrmTables;
    setIsLoading: (value: boolean) => void;
}) {
    const { open, handleClose, selectedRow, column, customColumns, currentTable, setIsLoading } = props;
    const { background } = colors;

    const [editDeal] = useEditDealMutation();
    const [editCompany] = useEditCompanyMutation();

    if (!selectedRow || !column) {
        return null;
    }
    const { id, ...initial } = selectedRow;

    const objectProps = Object.keys(initial).filter((item: any) => {
        const index = customColumns.indexOf(item);
        return item !== "associatedCompany" && item !== "associatedContact" && item !== "cruxyId" && index !== -1;
    });

    const newColumn = column.filter((item: any) => {
        const index = customColumns.indexOf(item);
        return item !== "associatedCompany" && item !== "associatedContact" && item !== "cruxyId" && index !== -1;
    });

    async function updateCompany(values: any) {
        try {
            setIsLoading(true);
            const response = await editCompany({
                variables: {
                    fields: {
                        annualRevenue: values.annualRevenue,
                        country: values.country,
                        createDate: values.createDate,
                        createdById: values.createdById,
                        industry: values.industry,
                        name: values.name,
                        parentCompanyId: values.parentCompanyId,
                        type: values.type,
                    },
                    editCompanyId: selectedRow.id,
                },
            });
            if (response) {
                enqueueSnackbar("Deal updated!", { variant: "success" });
                handleClose();
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar("Something went wrong!", { variant: "error" });
        }
        setIsLoading(false);
    }

    async function updateDeal(values: any) {
        try {
            setIsLoading(true);
            const response = await editDeal({
                variables: {
                    fields: {
                        acv: values.acv,
                        amount: values.amount,
                        amountInHomeCurrency: values.amountInHomeCurrency,
                        analyticsLatestSource: values.analyticsLatestSource,
                        arr: values.arr,
                        closeDate: values.closeDate,
                        createDate: values.createDate,
                        createdById: values.createdById,
                        dealCurrencyCode: values.dealCurrencyCode,
                        dealName: values.dealName,
                        dealOwnerId: values.dealOwnerId,
                        dealStage: values.dealStage,
                        fiscalYear: values.fiscalYear,
                        pipeline: values.pipeline,
                        type: values.type,
                    },
                    editDealId: selectedRow.id,
                },
            });
            if (response) {
                enqueueSnackbar("Deal updated!", { variant: "success" });
                handleClose();
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
        }
        setIsLoading(false);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <MDBox sx={style} border={"none"}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <img style={{ width: "6rem", height: "6rem" }} src={cruxy} alt="cruxy" />
                    </div>
                    <Formik
                        initialValues={initial}
                        //validationSchema={RowSchema}
                        onSubmit={async (values) => {
                            if (currentTable.name === "Deals") {
                                updateDeal(values);
                            }
                            if (currentTable.name === "Companies") {
                                updateCompany(values);
                            }
                        }}
                    >
                        <Form style={{ width: "100%", marginTop: "2rem" }}>
                            {objectProps.map((item, index) => {
                                return (
                                    <Field key={item} sx={{ width: "100%" }} name={item}>
                                        {(props: FieldProps<string, any>) => {
                                            if (index === 0)
                                                return (
                                                    <TextField
                                                        sx={{ width: "100%", marginBottom: "1.5rem" }}
                                                        name={item}
                                                        label={newColumn[index]
                                                            .split(" ")
                                                            .map(
                                                                (word: any) =>
                                                                    word.charAt(0).toUpperCase() +
                                                                    word.slice(1).toLowerCase()
                                                            )
                                                            .join(" ")}
                                                        color="secondary"
                                                        autoFocus
                                                        //required
                                                        value={props.field.value}
                                                        onChange={(e) => {
                                                            props.form.setFieldValue(
                                                                props.field.name,
                                                                e.target.value,
                                                                true
                                                            );
                                                        }}
                                                    />
                                                );
                                            return (
                                                <TextField
                                                    sx={{ width: "100%", marginBottom: "1.5rem" }}
                                                    name={item}
                                                    label={newColumn[index]
                                                        .split(" ")
                                                        .map(
                                                            (word: any) =>
                                                                word.charAt(0).toUpperCase() +
                                                                word.slice(1).toLowerCase()
                                                        )
                                                        .join(" ")}
                                                    color="secondary"
                                                    //required
                                                    value={props.field.value}
                                                    onChange={(e) => {
                                                        props.form.setFieldValue(
                                                            props.field.name,
                                                            e.target.value,
                                                            true
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    </Field>
                                );
                            })}

                            <MDBox mt={2} mb={1}>
                                <MDButton type="submit" variant="gradient" color="info" size="large" fullWidth>
                                    Update
                                </MDButton>
                            </MDBox>
                        </Form>
                    </Formik>
                </MDBox>
            </Fade>
        </Modal>
    );
}
