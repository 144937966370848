import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { ClientsResponse } from "graphql/types/graphql";
import { useEffect, useRef, useState } from "react";
import { FilterUpdateType, TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import React from "react";

interface Props {
    selectedCompany: ClientsResponse;
    token: string;
    link: string;
}

function TableauView({ selectedCompany, token, link }: Props) {
    const viz: any = useRef(null);
    const [width, setWidth] = useState<number>(1300);
    const [height, setHeight] = useState<number>(800);
    const idRef: any = useRef(null);
    idRef.current = selectedCompany.cruxyId;

    useEffect(() => {
        viz?.current?.addFilter("Cruxy Id", idRef.current);
    }, [selectedCompany]);

    return (
        <MDBox
            sx={{
                bottom: 0,
                flex: 1,
                overflowX: "auto",
                backgroundColor: colors.background.sidenav,
                height: "800px",
                //border: "1px solid white",
            }}
        >
            <TableauEmbed
                key={selectedCompany.cruxyId}
                ref={(el) => {
                    if (el !== viz.current) {
                        el?.addFilter("Cruxy Id", idRef.current);
                    }
                    viz.current = el;
                }}
                height={800}
                toolbar="hidden"
                token={token}
                sourceUrl={link}
            />
        </MDBox>
    );
}

export default TableauView;
