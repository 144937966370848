import React, { JSXElementConstructor, Key, ReactElement, useState } from "react";
import { context } from "./Provider";
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "layouts/authentication/sign-in/illustration";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

interface MyRoute {
    collapse: any;
    availableFor: string[];
    route: string;
    component: ReactElement<any, string | JSXElementConstructor<any>>;
    key: Key;
}

function RequireAuth({ routes }: { routes: any }) {
    const { token, selectedCompany, user } = React.useContext(context);
    const getRoutes = (allRoutes: any[]): any => {
        return allRoutes.map((route: any) => {
            if (route.availableFor.indexOf(user.role) === -1) {
                return;
            }
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                if (route.route === "/pipelines" || route.route === "/invoice-table") {
                    return (
                        <Route path={route.route} element={route.component(selectedCompany.cruxyId)} key={route.key} />
                    );
                }
                return <Route path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });
    };

    return token && selectedCompany ? (
        <>
            <DashboardLayout>
                {<DashboardNavbar />}
                <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/pipelines" />} />
                </Routes>
            </DashboardLayout>
        </>
    ) : (
        <Login />
    );
}

export default RequireAuth;
