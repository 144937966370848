export function addOpacityToColor(rgbString: string, alpha: number) {
    // Regular expression to extract the RGB values from the input string
    const regex = /rgb\((\d+), (\d+), (\d+)\)/;

    // Extract RGB values using regular expression
    const match = rgbString.match(regex);

    if (match && match.length === 4) {
        const [, r, g, b] = match;
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
        throw new Error("Invalid RGB string format. Please provide a valid RGB string.");
    }
}
