import { Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import DataTable from "examples/Tables/DataTable";
import { CrmTables } from "graphql/types/graphql";
import { useContext } from "react";
import { context } from "utils/Provider";
import { CurrentClient } from "utils/types";
import EditModal from "./EditModal";

interface Props {
    currentTable: CrmTables;
    total: number;
    loading: boolean;
    table: {
        columns: { [key: string]: any }[];
        rows: { [key: string]: any }[];
    };
    openModal?: (row: any, column: any) => void;
    pageI: number;
    setPage: (value: number) => void;
    pageSize: number;
    setPageSize: (value: number) => void;
    currentPage: number;
    setCurrentPage: (value: number) => void;
    open: boolean;
    customColumns: string[];
    handleClose: () => void;
    selectedRow: any;
    column: any;
}

function MyTable(props: Props) {
    const {
        customColumns,
        handleClose,
        selectedRow,
        column,
        table,
        currentTable,
        total,
        open,
        loading,
        openModal,
        currentPage,
        pageSize,
        setPageSize,
        setCurrentPage,
    } = props;
    const { isLoading, selectedCompany, setIsLoading } = useContext(context);

    function setNextCursor() {
        setCurrentPage(currentPage + 1);
    }

    function setPreviousCursor() {
        setCurrentPage(currentPage - 1);
    }

    return (
        <>
            {table.columns.length === 0 ? (
                <Typography
                    sx={{
                        fontWeight: "600",
                        color: colors.white.main,
                        fontSize: "1.25rem",
                        padding: "1.5rem",
                        opacity: isLoading ? 0 : 1,
                    }}
                >
                    <span
                        style={{
                            fontWeight: "600",
                            color: colors.primary.main,
                            fontSize: "1.25rem",
                        }}
                    >
                        {selectedCompany?.name ? selectedCompany.name + " " : "Your company "}
                    </span>
                    doesn{"'"}t have CRM data for{" "}
                    <span
                        style={{
                            fontWeight: "600",
                            color: colors.secondary.main,
                            fontSize: "1.25rem",
                        }}
                    >
                        {currentTable.name}
                    </span>
                    !
                </Typography>
            ) : (
                <DataTable
                    currentTable={currentTable}
                    total={total}
                    loading={loading}
                    table={table}
                    openModal={openModal}
                    nextPageHandler={setNextCursor}
                    previousPageHandler={setPreviousCursor}
                    pageI={parseInt(currentPage + "") + 1}
                    setPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSizeData={setPageSize}
                    currentPage={currentPage}
                />
            )}
            <EditModal
                customColumns={customColumns}
                open={open}
                handleClose={handleClose}
                selectedRow={selectedRow}
                column={column}
                currentTable={currentTable}
                setIsLoading={setIsLoading}
            />
        </>
    );
}

export default MyTable;
