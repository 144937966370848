import { ReactNode, useState } from "react";

// @mui material components
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import colors from "assets/theme-dark/base/colors";
import { Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { scrollbar } from "utils/scrollBar";

// Declaring prop types for DataTableBodyCell
interface Props {
    children: ReactNode;
    noBorder?: boolean;
    thicc?: boolean;
    list?: string[];
    align?: "left" | "right" | "center";
}

function DataTableBodyCell({ noBorder, align, children, thicc, list }: Props): JSX.Element {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "none",
        },
    });

    return (
        <MDBox
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            component="td"
            textAlign={align}
            py={1.5}
            px={3}
            sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
                fontSize: size.sm,
                fontWeight: thicc ? "900" : "300",
                borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
                backgroundColor: "transparent",
            })}
        >
            {list ? (
                <NoMaxWidthTooltip
                    title={
                        <MDBox
                            sx={{
                                minWidth: "200px",
                                color: colors.white.main,
                                padding: "0.5rem",
                                maxHeight: "350px",
                                overflow: "auto",
                                ...scrollbar,
                            }}
                        >
                            {list.map((string: string) => {
                                return <Typography key={string}>- {string}</Typography>;
                            })}
                        </MDBox>
                    }
                >
                    <MDBox
                        display="inline-block"
                        width="max-content"
                        color={thicc ? `${colors.secondary.main}` : `${colors.white.main} !important`}
                        sx={{ verticalAlign: "middle" }}
                    >
                        {children}
                    </MDBox>
                </NoMaxWidthTooltip>
            ) : (
                <MDBox
                    display="inline-block"
                    width="max-content"
                    color={thicc ? `${colors.secondary.main}` : `${colors.white.main} !important`}
                    sx={{ verticalAlign: "middle" }}
                >
                    {children}
                </MDBox>
            )}
        </MDBox>
    );
}

// Declaring default props for DataTableBodyCell
DataTableBodyCell.defaultProps = {
    noBorder: false,
    align: "left",
};

export default DataTableBodyCell;
