import _ from "lodash";
import { objectPropHandler } from "./objectPropHandler";

export default function repairTree(tree: any, relation: string, id: string) {
    if (tree.length === 1) {
        const key = objectPropHandler(tree[0]);
        return {
            id: id,
            [key]: tree[0][key],
        };
        //return _.omit(tree[0][key], ["alias"]);
    }
    return {
        id: id,
        [relation]: tree.map((item: any) => {
            const key = objectPropHandler(item);
            if (key === "AND" || key === "OR") {
                if (item[key].length === 1) {
                    return _.omit(item[key][0], ["alias"]);
                }
                return repairTree(item[key], key, item.id);
            } else {
                return _.omit(item, ["alias"]);
            }
        }),
    };
}
