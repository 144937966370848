import TreeItem from "@mui/lab/TreeItem";
import { Stack, Tooltip, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import colors from "assets/theme-dark/base/colors";
import { labelGiver } from "./labelGiver";
import { v4 } from "uuid";
import { objectPropHandler } from "./objectPropHandler";
import { valueFormatter } from "./valueFormatter";
import { testIds } from "utils/testIds";

const styles = {
    treeBranch: {
        border: "1px solid white",
        borderRadius: "5px",
        padding: "0.5rem",
        display: "flex",
        alignItems: "center",
        ".MuiTreeItem-iconContainer": {
            display: "none !important",
        },
        boxShadow: `inset  0 0 0.25rem 0 ${colors.white.main}, 0 0 0.25rem 0 ${colors.white.main}`,
        minWidth: "200px",
    },
    treeBranchNested: {
        boxShadow: `inset  0 0 0.25rem 0 ${colors.white.main}, 0 0 0.25rem 0 ${colors.white.main}`,
        border: "1px solid white",
        borderRadius: "5px",
        padding: "10px",
    },
    labelBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        //border: "1px solid white",
    },
    labelValue: {
        color: colors.white.main,
        fontSize: "1rem",
        //border: "1px solid white",
    },
    labelOperator: {
        backgroundColor: colors.primary.main,
        marginLeft: "2rem",
        marginRight: "2rem",
        width: "3rem",
        height: "2rem",
        //border: "1px solid white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        borderRadius: "50%",
        padding: "0.5rem",
        color: colors.dark.main,
        //transform: "scale(1.5,1.5)",
        fontWeight: "600",
        boxShadow: "0 4px 30px rgba(255, 255, 255, 0.5)",
    },
    relation: {
        color: colors.dark.main,
        boxShadow: "0 4px 30px rgba(255, 255, 255, 0.5)",
        width: "2rem",
        height: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        fontSize: "0.75rem",
        fontWeight: "600",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        backgroundColor: colors.primary.main,
    },
    containBox: {
        display: "flex",
        alignItems: "center",
        color: colors.white.main,
        gap: 2,
        height: "2rem",
        justifyContent: "space-between",
    },
    nickname: {
        fontWeight: 600,
        color: colors.secondary.main,
        marginLeft: "0.5rem",
    },
};

export default function treeMaker(rule: any, setSelectedSubTree: (value: any) => void, alias: string) {
    const relation = objectPropHandler(rule);
    if (relation !== "AND" && relation !== "OR") {
        if (relation === "CONTAINS") {
            return (
                <>
                    <TreeItem
                        //@ts-ignore
                        ContentProps={{ "data-testid": testIds.conditionBranch }}
                        key={rule.id}
                        nodeId={rule.id}
                        onClick={() => {
                            setSelectedSubTree(rule);
                        }}
                        label={
                            <MDBox sx={styles.containBox}>
                                <Typography sx={styles.nickname} key={v4()}>
                                    ({alias})
                                </Typography>
                                {rule[relation][0].value.split(".")[1]}:
                                <Tooltip
                                    title={rule[relation][1].map((subItem: any) => {
                                        return (
                                            <Typography
                                                sx={{ textAlign: "center", fontSize: "1rem" }}
                                                key={subItem.value}
                                            >
                                                {subItem.value}
                                            </Typography>
                                        );
                                    })}
                                >
                                    <MDBadge
                                        data-testid={testIds.containsBadge}
                                        circular
                                        badgeContent={rule[relation][1].length.toString()}
                                        color={colors.primary.main}
                                    >
                                        <FormatListBulletedIcon
                                            sx={{ color: colors.white.main, width: "2rem", height: "2rem" }}
                                        />
                                    </MDBadge>
                                </Tooltip>
                            </MDBox>
                        }
                        sx={styles.treeBranch}
                    />
                </>
            );
        }

        return (
            <TreeItem
                //@ts-ignore
                ContentProps={{ "data-testid": testIds.conditionBranch }}
                nodeId={rule.id}
                onClick={() => {
                    setSelectedSubTree(rule);
                }}
                label={
                    <MDBox sx={styles.labelBox}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={styles.labelValue}>{rule[relation][0].value.split(".")[1]}</Typography>
                            <MDBox sx={styles.labelOperator}>{labelGiver(relation)}</MDBox>
                            <Typography sx={styles.labelValue}>{valueFormatter(rule[relation][1])}</Typography>
                        </div>
                        <Typography sx={styles.nickname} key={v4()}>
                            ({alias + "1"})
                        </Typography>
                    </MDBox>
                }
                sx={styles.treeBranch}
            />
        );
    }
    return rule[relation].map((item: any, index: number) => {
        const key = objectPropHandler(item);
        if (key === "CONTAINS") {
            return (
                <>
                    <TreeItem
                        //@ts-ignore
                        ContentProps={{ "data-testid": testIds.conditionBranch }}
                        key={item.id}
                        nodeId={item.id}
                        onClick={() => {
                            setSelectedSubTree(item);
                        }}
                        label={
                            <MDBox sx={styles.containBox}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {item[key][0].value.split(".")[1]
                                        ? item[key][0].value.split(".")[1]
                                        : item[key][0].value}
                                    :
                                    <Tooltip
                                        title={item[key][1].map((subItem: any) => {
                                            return (
                                                <Typography
                                                    sx={{ textAlign: "center", fontSize: "1rem" }}
                                                    key={subItem}
                                                >
                                                    {subItem.value}
                                                </Typography>
                                            );
                                        })}
                                    >
                                        <MDBadge
                                            data-testid={testIds.containsBadge}
                                            circular
                                            badgeContent={item[key][1].length.toString()}
                                            color={colors.primary.main}
                                        >
                                            <FormatListBulletedIcon
                                                sx={{
                                                    color: colors.white.main,
                                                    width: "2rem",
                                                    height: "2rem",
                                                    marginLeft: "0.5rem",
                                                }}
                                            />
                                        </MDBadge>
                                    </Tooltip>
                                </div>
                                <Typography sx={styles.nickname} key={v4()}>
                                    ({alias + (index + 1)})
                                </Typography>
                            </MDBox>
                        }
                        sx={styles.treeBranch}
                    />
                    {rule[relation].length - 1 !== index && <MDBox sx={styles.relation}>{relation}</MDBox>}
                </>
            );
        }

        if (key === "AND" || key === "OR") {
            const newIdentifier = alias + (index + 1) + ".C";
            return (
                <>
                    <TreeItem
                        //@ts-ignore
                        ContentProps={{ "data-testid": testIds.conditionBranch }}
                        onClick={() => {
                            setSelectedSubTree(item);
                        }}
                        key={item.id}
                        nodeId={item.id}
                        label={
                            <Stack justifyContent="space-between" flexDirection="row">
                                <Typography sx={{ color: colors.white.main }}>Criteria</Typography>
                                <Typography sx={styles.nickname}>({alias + (index + 1)})</Typography>
                            </Stack>
                        }
                        sx={styles.treeBranchNested}
                    >
                        {treeMaker(item, setSelectedSubTree, newIdentifier)}
                    </TreeItem>
                    {rule[relation].length - 1 !== index && <MDBox sx={styles.relation}>{relation}</MDBox>}
                </>
            );
        } else {
            return (
                <>
                    <TreeItem
                        //@ts-ignore
                        ContentProps={{ "data-testid": testIds.conditionBranch }}
                        key={JSON.stringify(item)}
                        nodeId={JSON.stringify(item)}
                        onClick={() => {
                            setSelectedSubTree(item);
                        }}
                        label={
                            <MDBox sx={styles.labelBox}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography sx={styles.labelValue}>{item[key][0].value.split(".")[1]}</Typography>
                                    <MDBox sx={styles.labelOperator}>{labelGiver(key)}</MDBox>
                                    <Typography sx={styles.labelValue}>{valueFormatter(item[key][1])}</Typography>
                                </div>
                                <Typography sx={styles.nickname}>({alias + "" + (index + 1)})</Typography>
                            </MDBox>
                        }
                        sx={styles.treeBranch}
                    />
                    {rule[relation].length - 1 !== index && <MDBox sx={styles.relation}>{relation}</MDBox>}
                </>
            );
        }
    });
}
