import { Pipeline } from "./types";

export default function newInstanceHandler(newPipeline: Pipeline): {
    averageAmount: any;
    name: any;
    orderNumber: any;
}[] {
    return newPipeline.input.pipeline.schema.pipelineInstances.map((instance: any) => {
        return {
            averageAmount: instance.averageAmount,
            name: instance.name,
            orderNumber: instance.orderNumber,
        };
    });
}
