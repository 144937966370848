import { useMemo, useEffect, useState, useRef } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import { MenuItem, TextField, Tooltip } from "@mui/material";
import colors from "assets/theme-dark/base/colors";

import { debounce, DebouncedFunc, isObject } from "lodash";
import { CrmTables } from "graphql/types/graphql";
import columnObjectHandler from "./utils/columnObjectHandler";
import { scrollbar } from "utils/scrollBar";
import { testIds } from "utils/testIds";
import linearGradient from "assets/theme/functions/linearGradient";
import { linearGradientStyle } from "utils/linearGradientsStyle";
// Declaring props types for DataTable

const dates = ["createDate", "closeDate", "date", "endDate", "startDate"];

interface Props {
    entriesPerPage?:
        | false
        | {
              defaultValue: number;
              entries: number[];
          };
    canSearch?: boolean;
    showTotalEntries?: boolean;
    table: {
        columns: { [key: string]: any }[];
        rows: { [key: string]: any }[];
    };
    pagination?: {
        variant: "contained" | "gradient";
        color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
    };
    isSorted?: boolean;
    noEndBorder?: boolean;
    openModal?: (row: any, column: any) => void;
    total: number;
    nextPageHandler: () => void;
    previousPageHandler: () => void;
    pageI: number;
    setPage: (value: number) => void;
    pageSize: number;
    setPageSizeData: (value: number) => void;
    loading: boolean;
    currentTable: CrmTables;
    currentPage: number;
}

const rowsPerPage = ["5", "10", "15", "20", "25"];

function DataTable({
    entriesPerPage,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    openModal,
    total,
    nextPageHandler,
    previousPageHandler,
    pageI,
    setPage,
    pageSize,
    setPageSizeData,
    currentPage,
}: Props): JSX.Element {
    let defaultValue: any;
    let entries: any[];

    const { light, primary, secondary, background } = colors;
    const debounceRef = useRef<DebouncedFunc<(criteria: any) => void> | undefined>();
    const scrollRef: any = useRef(null);
    if (entriesPerPage) {
        defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : "10";
        entries = entriesPerPage.entries ? entriesPerPage.entries : ["10", "25", "50", "100"];
    }

    const columns = useMemo<any>(() => table.columns, [table]);
    const data = useMemo<any>(() => table.rows, [table]);

    const tableInstance = useTable(
        { columns, data, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, globalFilter },
    }: any = tableInstance;
    const pageNumber = Math.ceil(total / pageSize);
    const [pageInput, setPageInput] = useState<number>(pageI);

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [table]);

    const pageOptions = new Array(total);
    pageOptions.fill(0);

    function unixTimeToDayMonthYear(unixTimestamp: number) {
        try {
            const date = new Date(unixTimestamp * 1); // Convert from seconds to milliseconds
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are zero-based, so we add 1
            const year = date.getFullYear();

            // Add leading zeros if necessary
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            return `${formattedDay}/${formattedMonth}/${year}`;
        } catch (error) {
            return "-";
        }
    }

    function cellRenderHandler(cell: any, key: any) {
        if (Array.isArray(cell.value)) {
            if (cell.value.length === 0) {
                return (
                    <DataTableBodyCell
                        key={key}
                        noBorder={true}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                    >
                        -
                    </DataTableBodyCell>
                );
            }
            if (cell.value.length === 1) {
                return (
                    <DataTableBodyCell
                        key={key}
                        noBorder={true}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                    >
                        {columnObjectHandler(cell.column.id, cell.value[0])}
                    </DataTableBodyCell>
                );
            }
            const list = cell.value.map((item: any) => {
                return columnObjectHandler(cell.column.id, item);
            });
            return (
                <DataTableBodyCell
                    key={key}
                    noBorder={true}
                    align={cell.column.align ? cell.column.align : "left"}
                    thicc={true}
                    list={list}
                    {...cell.getCellProps()}
                >
                    {cell.value.length} records
                </DataTableBodyCell>
            );
        }
        if (dates.indexOf(cell.column.Header) !== -1) {
            return (
                <DataTableBodyCell
                    key={key}
                    noBorder={true}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                >
                    {cell.value ? unixTimeToDayMonthYear(cell.value) : <>-</>}
                </DataTableBodyCell>
            );
        }

        if (isObject(cell.value)) {
            return (
                <DataTableBodyCell
                    key={key}
                    noBorder={true}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                >
                    {cell.value[Object.keys(cell.value)[0]]}
                </DataTableBodyCell>
            );
        }

        return (
            <DataTableBodyCell
                key={key}
                noBorder={true}
                align={cell.column.align ? cell.column.align : "left"}
                {...cell.getCellProps()}
            >
                {cell.value ? (
                    cell.value.toString().length > 50 ? (
                        cell.value.toString().slice(0, 50) + "..."
                    ) : (
                        cell.value
                    )
                ) : (
                    <>-</>
                )}
            </DataTableBodyCell>
        );
    }

    function headerRenderHandler(column: any, key: any) {
        return (
            <DataTableHeadCell
                key={key}
                {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                width={column.width ? column.width : "auto"}
                align={column.align ? column.align : "left"}
                sorted={false}
                minWidth={`${column.Header.length + 4}ch`}
            >
                {column.render("Header")}
            </DataTableHeadCell>
        );
    }

    const renderPagination = pageOptions.map((option: any, index: number) => (
        <MDPagination
            item
            key={index}
            onClick={(index: number) => {
                if (index > pageNumber) {
                    setPageInput(pageNumber);
                    setPage(pageNumber - 1);
                } else if (index === 0) {
                    setPageInput(1);
                    setPage(1 - 1);
                } else {
                    setPage(index - 1);
                }
            }}
            active={pageIndex === option}
        >
            {index + 1}
        </MDPagination>
    ));

    useEffect(() => {
        setPageInput(pageI);
    }, [pageI]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setPageInput(Number(e.target.value));

        debounceRef.current?.cancel();
        debounceRef.current = debounce((criteria) => {
            if (Number(e.target.value) > pageNumber) {
                setPageInput(pageNumber);
                setPage(pageNumber - 1);
            } else if (Number(e.target.value) === 0) {
                setPageInput(1);
                setPage(1 - 1);
            } else {
                setPage(criteria - 1);
            }
        }, 1800);
        debounceRef.current(e.target.value);
    }

    return (
        <>
            <TableContainer
                sx={{
                    boxShadow: "none",
                    background: "transparent",
                    //border: "1px solid white",
                    maxHeight: "550px",
                    borderRadius: 0,
                    padding: 0,
                    margin: 0,
                    ...scrollbar,
                }}
                ref={scrollRef}
            >
                <Table>
                    <MDBox
                        sx={{
                            position: "sticky",
                            top: 0,
                            background: "transparent",
                            //border: "1px solid white",
                            boxShadow: "0px 3px 1px -2px rgba(255, 255, 255, 1)",
                        }}
                        component="thead"
                    >
                        {headerGroups.map((headerGroup: any, key: any) => (
                            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any, key: any) => {
                                    return headerRenderHandler(column, key);
                                })}
                            </TableRow>
                        ))}
                    </MDBox>

                    <TableBody>
                        {page.map((row: any, key: any) => {
                            prepareRow(row);
                            return (
                                <>
                                    <TableRow
                                        data-testid={testIds.tableRow}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: `${primary.main} !important`,
                                                cursor: "pointer",
                                            },
                                        }}
                                        onClick={() => {
                                            const columns = table.columns.map((item: any) => {
                                                return item.Header;
                                            });
                                            if (openModal) openModal(row.original, columns);
                                        }}
                                        key={key}
                                        {...row.getRowProps()}
                                        hover
                                    >
                                        {row.cells.map((cell: any, key: any) => {
                                            return cellRenderHandler(cell, key);
                                        })}
                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <MDBox
                sx={{
                    //position: "sticky",
                    bottom: "0%",
                    padding: 0,
                    margin: 0,
                    left: 0,
                    width: "100%",
                    backgroundColor: "transparent",
                    //transform: "translateY(10%)",
                    paddingRight: "1rem",
                    //border: "20px solid white",
                }}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="center"
                height="7rem"
            >
                {entriesPerPage ? (
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            paddingLeft: "1rem",

                            // paddingTop: 0,
                            // paddingBottom: "3rem",
                        }}
                    >
                        {entriesPerPage && (
                            <MDBox sx={{}} display="flex" alignItems="center">
                                <Autocomplete
                                    data-testid={testIds.entriesPerPage}
                                    size="small"
                                    disableClearable
                                    id="combo-box-demo"
                                    options={rowsPerPage}
                                    defaultValue="10"
                                    sx={{ width: 100 }}
                                    onChange={(event, newValue) => {
                                        setPageSizeData(Number(newValue));
                                        setPageSize(Number(newValue));
                                    }}
                                    renderInput={({ inputProps, ...rest }) => (
                                        <TextField
                                            size="small"
                                            {...rest}
                                            inputProps={{ ...inputProps, readOnly: true }}
                                        />
                                    )}
                                />

                                <MDTypography variant="caption" color="secondary">
                                    &nbsp;&nbsp;entries per page
                                </MDTypography>
                            </MDBox>
                        )}
                    </MDBox>
                ) : null}

                {pageOptions.length > 1 && (
                    <MDPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {pageI !== 1 && (
                            <MDPagination
                                data-testid={testIds.previousPage}
                                item
                                onClick={() => {
                                    previousPageHandler();
                                }}
                            >
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </MDPagination>
                        )}

                        <MDBox width="5rem" mx={1}>
                            <TextField
                                inputProps={{
                                    type: "number",
                                    min: 1,
                                    max: pageNumber,
                                    style: {
                                        height: "10px",
                                    },
                                }}
                                value={pageInput}
                                onChange={(event: any) => {
                                    handleChange(event);
                                }}
                            />
                            {showTotalEntries && (
                                <MDBox sx={{ position: "absolute" }} mb={{ xs: 3, sm: 0 }}>
                                    <MDTypography variant="button" color="secondary" fontWeight="regular">
                                        {pageSize + currentPage * pageSize > total
                                            ? total + "/" + total
                                            : pageSize + currentPage * pageSize + "/" + total}
                                    </MDTypography>
                                </MDBox>
                            )}
                        </MDBox>

                        {pageI < pageNumber && (
                            <MDPagination
                                data-testid={testIds.nextPage}
                                item
                                onClick={() => {
                                    nextPageHandler();
                                }}
                            >
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </MDPagination>
                        )}
                    </MDPagination>
                )}
            </MDBox>
        </>
    );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: false,
    noEndBorder: false,
};

export default DataTable;
