import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { FilterUpdateType, TableauEmbed } from "@stoddabr/react-tableau-embed-live";

import { useRef, useEffect, useContext, useState } from "react";
import { context } from "utils/Provider";
import { enqueueSnackbar } from "notistack";

import Tabs from "components/Tabs/Tabs";
import TableauView from "./TableauView";
import React from "react";

function Visual(props: { pipelineId: string }): JSX.Element {
    const { token, refreshAccessToken, setToken, selectedCompany } = useContext(context);

    const [selected, setSelected] = useState<number>(0);
    const [credentials, setCredentials] = useState<string>();
    const { pipelineId } = props;

    useEffect(() => {
        async function refresh() {
            const data = await refreshAccessToken();
            if (!data) {
                enqueueSnackbar("Could not refresh credentials!", { variant: "error" });
            } else {
                //@ts-ignore
                setToken(data);
                //@ts-ignore
                setCredentials(data);
            }
        }
        refresh();
    }, []);

    return <>{credentials && <TableauEmbedMemo token={credentials} pipelineId={pipelineId} />}</>;
}

export default Visual;

const TableauEmbedMemo = React.memo(function ChartMemo({ token, pipelineId }: { token: string; pipelineId: string }) {
    return (
        <>
            <TableauView pipelineId={pipelineId} key={pipelineId} token={token} />
        </>
    );
});
