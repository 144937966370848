import { PageData } from "./types";

export default function errorDecider(code: string, updatePageData: (object: Partial<PageData>) => void) {
    switch (code) {
        default:
            updatePageData({
                parameterError: code,
            });
            break;
        case "NO_STAGES":
            updatePageData({
                parameterError: "Can not create pipeline because the selected pipeline doesn't have stages!",
            });
            break;
        case "NO_COLUMNS":
            updatePageData({
                fatalError: "Can not create pipeline because the selected pipeline doesn't have custom columns!",
            });
            break;
        case "NO_INSTANCES":
            updatePageData({
                parameterError:
                    "Selected column has no instances. Try adding conditional values via formulas to the column!",
            });
            break;
        case "ERROR":
            updatePageData({
                fatalError: "Something wrong with the database!",
            });
            break;
        case "NO_CRM_PIPELINES":
            updatePageData({
                fatalError: "Selected company doesn't have CRM pipelines to work with!",
            });
            break;
        case "NO_PIPELINES":
            updatePageData({
                fatalError: "Selected company doesn't have custom pipelines!",
            });
            break;
        case "CLEAN":
            updatePageData({
                fatalError: "",
                parameterError: "",
            });
            break;
    }
}
