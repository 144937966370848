import {
    ClientsResponse,
    CrmTables,
    CustomColumn,
    CustomColumnsDocument,
    Formula,
    FormulasDocument,
    useApplyFormulaMutation,
    useCrmColumnsQuery,
    useFormulasLazyQuery,
    useUpsertColumnMutation,
} from "graphql/types/graphql";
import { useUpsertFormulaMutation } from "graphql/types/graphql";
import { useDeleteFormulaMutation } from "graphql/types/graphql";
import { getSubTreeById } from "layouts/applications/CRM data/utils/getSubTreeById";
import { objectPropHandler } from "layouts/applications/CRM data/utils/objectPropHandler";

import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Rule, User } from "utils/types";

export function useRules(
    columns: CustomColumn[],
    setIsLoading: (value: boolean) => void,
    currentTable: CrmTables,
    tables: CrmTables[],
    user: User,
    selectedCompany: ClientsResponse,
    getDealsHandler: () => void,
    getCompaniesHandler: () => void
) {
    const [tree, setTree] = useState<any>();
    const [aliasTree, setAliasTree] = useState<any>();

    const [selectedSubTree, setSelectedSubTree] = useState<any>();

    const [selectedTree, setSelectedTree] = useState<any>(); //used to compare it with the tree state

    const [selectedColumn, setSelectedColumn] = useState<CustomColumn>(columns[0]);
    const [selectedRule, setSelectedRule] = useState<Formula>(); //used to store the rule object itself,needed for upserting

    const [newRuleName, setNewRuleName] = useState<string>();
    const [newColName, setNewColName] = useState<string>(columns[0].columnName);

    const [rules, setRules] = useState<Rule[]>([]);
    const [crmColumns, setCrmColumns] = useState<string[]>([]);

    const [getRules, { loading: loadingFormulas }] = useFormulasLazyQuery();

    const [upsertColumn] = useUpsertColumnMutation();
    const [upsertRule] = useUpsertFormulaMutation();
    const [deleteRule] = useDeleteFormulaMutation();
    const [applyFormula] = useApplyFormulaMutation();

    const [variableOptions1, setVariableOptions1] = useState<string[]>();
    const [variableOptions2, setVariableOptions2] = useState<string[]>();

    const [tableOption1, setTableOption1] = useState<CrmTables>(currentTable);
    const [tableOption2, setTableOption2] = useState<CrmTables>(currentTable);

    const {
        error: errorOptions1,
        data: dataOptions1,
        loading: loadingOptions1,
    } = useCrmColumnsQuery({ variables: { crmColumnsId: tableOption1.id } });

    const {
        error: errorOptions2,
        data: dataOptions2,
        loading: loadingOptions2,
    } = useCrmColumnsQuery({ variables: { crmColumnsId: tableOption2.id } });

    useEffect(() => {
        if (errorOptions1) console.log(errorOptions1);
        if (dataOptions1?.crmColumns) {
            setVariableOptions1(
                dataOptions1?.crmColumns
                    .filter((column: string) => {
                        return Boolean(column != "id" && column != "cruxyId");
                    })
                    .map((column) => column.replace(/([A-Z])/g, "_$1").toUpperCase())
            );
        }
    }, [errorOptions1, dataOptions1]);

    useEffect(() => {
        if (errorOptions2) console.log(errorOptions2);
        if (dataOptions2?.crmColumns) {
            setVariableOptions2(
                dataOptions2?.crmColumns
                    .filter((column: string) => {
                        return Boolean(column != "id" && column != "cruxyId");
                    })
                    .map((column) => column.replace(/([A-Z])/g, "_$1").toUpperCase())
            );
        }
    }, [errorOptions2, dataOptions2]);

    useEffect(() => {
        if (tree) {
            const newTree = aliasGiver(tree[objectPropHandler(tree)], objectPropHandler(tree), "C", tree.id);

            setAliasTree(newTree);
        } else {
            setAliasTree(undefined);
        }
    }, [tree]);

    useEffect(() => {
        setIsLoading(Boolean(loadingOptions1 || loadingOptions2 || loadingFormulas));
    }, [loadingOptions1, loadingOptions2, loadingFormulas]);

    function setUpTree(newTree: any, subTree?: any) {
        if (subTree) {
            setTree(newTree);
            setSelectedSubTree(subTree);
        } else {
            setTree(newTree);
            setSelectedSubTree(newTree);
        }
    }

    function aliasGiver(rule: any, relation: string, alias: string, id: string) {
        if (relation !== "AND" && relation !== "OR") {
            return {
                id: id,
                [relation]: rule,
                alias: alias + "1",
            };
        }
        return {
            id: id,
            alias: alias.split(".")[0],
            [relation]: rule.map((item: any, index: number) => {
                const key = objectPropHandler(item);
                if (key === "CONTAINS") {
                    return {
                        ...item,
                        alias: alias + (index + 1),
                    };
                }

                if (key === "AND" || key === "OR") {
                    const newIdentifier = alias + (index + 1) + ".C";
                    return {
                        ...aliasGiver(item[key], key, newIdentifier, item.id),
                        //alias:alias + (index + 1)
                    };
                } else {
                    return {
                        ...item,
                        alias: alias + "" + (index + 1),
                    };
                }
            }),
        };
    }

    function undoChanges() {
        const aliasedTree = aliasGiver(
            selectedTree[objectPropHandler(selectedTree)],
            objectPropHandler(selectedTree),
            "C",
            selectedTree.id
        );
        const subAliasedTree = getSubTreeById(selectedSubTree, aliasedTree, selectedSubTree);

        setUpTree(selectedTree, subAliasedTree);

        setNewRuleName(selectedRule.valueFormula);
        setNewColName(selectedColumn.columnName);
    }

    async function saveChanges() {
        //return;
        if (selectedRule.id === "newRule") {
            saveNew();
            return;
        }

        const newRule = {
            ...selectedRule,
            valueFormula: newRuleName,
            conditionFormula: tree,
        };

        try {
            setIsLoading(true);
            const responseColumn = await upsertColumn({
                variables: {
                    input: {
                        columnName: newColName,
                        tableId: selectedColumn.tableId,
                    },
                    upsertColumnId: selectedColumn.columnId,
                },
                update: (cache: any) => {
                    const data = cache.readQuery({
                        query: CustomColumnsDocument,
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: currentTable.id,
                            },
                        },
                    });
                    const items = data.customColumns.map((column: any) => {
                        if (column.id === selectedColumn.columnId) {
                            setSelectedColumn({
                                ...column,
                                name: newColName,
                            });
                            return {
                                ...column,
                                name: newColName,
                            };
                        } else {
                            return column;
                        }
                    });
                    cache.writeQuery({
                        query: CustomColumnsDocument,
                        data: { customColumns: items },
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: currentTable.id,
                            },
                        },
                    });
                },
            });
            if (responseColumn) {
                const responseFormula = await upsertRule({
                    variables: {
                        input: {
                            columnId: selectedColumn.columnId,
                            conditionFormula: newRule.conditionFormula,
                            valueFormula: newRule.valueFormula,
                            //cruxyId: selectedCompany.cruxyId,
                        },
                        upsertFormulaId: newRule.id,
                    },
                    update: (cache: any) => {
                        const data = cache.readQuery({
                            query: FormulasDocument,
                            variables: {
                                columnId: selectedColumn.columnId,
                            },
                        });
                        const items = data.formulas.map((formula: any) => {
                            if (formula.id === selectedRule.id) {
                                return {
                                    ...formula,
                                    conditionFormula: newRule.conditionFormula,
                                    valueFormula: newRule.valueFormula,
                                    cruxyId: selectedCompany.cruxyId,
                                };
                            } else {
                                return formula;
                            }
                        });
                        cache.writeQuery({
                            query: FormulasDocument,
                            data: { formulas: items },
                            variables: {
                                columnId: selectedColumn.columnId,
                            },
                        });
                    },
                });

                if (responseFormula) {
                    setSelectedTree(responseFormula.data.upsertFormula.conditionFormula);
                    setTree(responseFormula.data.upsertFormula.conditionFormula);
                    setSelectedRule(responseFormula.data.upsertFormula);
                    setRules(
                        rules.map((rule: Rule) => {
                            if (rule.id === responseFormula.data.upsertFormula.id) {
                                return {
                                    id: responseFormula.data.upsertFormula.id,
                                    columnId: responseFormula.data.upsertFormula.columnId,
                                    conditionFormula: responseFormula.data.upsertFormula.conditionFormula,
                                    valueFormula: responseFormula.data.upsertFormula.valueFormula,
                                };
                            } else {
                                return rule;
                            }
                        })
                    );

                    setIsLoading(false);
                    enqueueSnackbar("Rule has been modified successfully!", { variant: "success" });
                }
            }
        } catch (error) {
            console.log("err", error);
            setIsLoading(false);
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    }

    async function saveNew() {
        const newRule = {
            ...selectedRule,
            valueFormula: newRuleName,
            conditionFormula: tree,
        };

        try {
            setIsLoading(true);
            const responseColumn = await upsertColumn({
                variables: {
                    input: {
                        tableId: selectedColumn.tableId,
                        columnName: newColName,
                    },
                    upsertColumnId: selectedColumn.columnId,
                },
                update: (cache: any) => {
                    const data2 = cache.readQuery({
                        query: CustomColumnsDocument,
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: selectedColumn.tableId,
                            },
                        },
                    });
                    const items = data2.customColumns.map((column: any) => {
                        if (column.id === selectedColumn.columnId) {
                            setSelectedColumn({
                                ...column,
                                name: newColName,
                            });
                            return {
                                ...column,
                                name: newColName,
                            };
                        } else {
                            return column;
                        }
                    });
                    cache.writeQuery({
                        query: CustomColumnsDocument,
                        data: { customColumns: items },
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: selectedColumn.tableId,
                            },
                        },
                    });
                },
            });

            if (responseColumn) {
                const responseFormula = await upsertRule({
                    variables: {
                        input: {
                            columnId: selectedColumn.columnId,
                            conditionFormula: newRule.conditionFormula,
                            valueFormula: newRule.valueFormula,
                            cruxyId: selectedCompany.cruxyId,
                        },
                    },
                    update: (cache: any, { data }: any) => {
                        const data2 = cache.readQuery({
                            query: FormulasDocument,
                            variables: {
                                columnId: selectedColumn.columnId,
                            },
                        });

                        const items = data2?.formulas ? data2.formulas : [];

                        cache.writeQuery({
                            query: FormulasDocument,
                            data: { formulas: [...items, data.upsertFormula] },
                            variables: {
                                columnId: selectedColumn.columnId,
                            },
                        });
                    },
                });
                if (responseFormula) {
                    setSelectedTree(responseFormula.data.upsertFormula.conditionFormula);
                    setTree(responseFormula.data.upsertFormula.conditionFormula);
                    setSelectedRule(responseFormula.data.upsertFormula);
                    setRules([
                        ...rules,
                        {
                            id: responseFormula.data.upsertFormula.id,
                            columnId: responseFormula.data.upsertFormula.columnId,
                            conditionFormula: responseFormula.data.upsertFormula.conditionFormula,
                            valueFormula: responseFormula.data.upsertFormula.valueFormula,
                        },
                    ]);
                    setIsLoading(false);
                    enqueueSnackbar("Rule has been added successfully!", { variant: "success" });
                }
            }
        } catch (error) {
            console.log("err", JSON.stringify(error));
            setIsLoading(false);
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    }

    async function applyRule() {
        try {
            setIsLoading(true);

            const response = await applyFormula({
                variables: { applyFormulaId: selectedRule.id },
                update: (cache: any) => {
                    if (currentTable.name === "Deals") {
                        cache.evict({ id: "ROOT_QUERY", fieldName: "deals" });
                    }

                    if (currentTable.name === "Companies") {
                        cache.evict({ id: "ROOT_QUERY", fieldName: "companies" });
                    }
                },
            });
            if (response) {
                setIsLoading(false);
                enqueueSnackbar(response.data.applyFormula.replace(" = ", ": "), { variant: "success" });
            }
        } catch (error) {
            console.log("err", JSON.stringify(error));
            setIsLoading(false);
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    }

    async function deleteRuleHandler() {
        try {
            setIsLoading(true);

            const response = await deleteRule({
                variables: {
                    deleteFormulaId: selectedRule.id,
                },

                update: (cache: any, { data }: any) => {
                    const data2 = cache.readQuery({
                        query: FormulasDocument,
                        variables: {
                            columnId: selectedColumn.columnId,
                        },
                    });

                    const items = data2.formulas.filter((formula: any) => {
                        return formula.id !== selectedRule.id;
                    });

                    setRules(items);
                    cache.writeQuery({
                        query: FormulasDocument,
                        data: { formulas: items },
                        variables: {
                            columnId: selectedColumn.columnId,
                        },
                    });
                },
            });

            if (response) {
                setIsLoading(false);
                setTree(undefined);
                enqueueSnackbar("Rule has been deleted successfully!", { variant: "success" });
            }
        } catch (error) {
            console.log("err", error);
            setIsLoading(false);
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    }

    return {
        setSelectedTree,
        setUpTree,
        setSelectedRule,
        selectedColumn,
        setSelectedColumn,
        newColName,
        setNewColName,
        newRuleName,
        setNewRuleName,
        rules,
        setRules,
        tree,
        setSelectedSubTree,
        selectedTree,
        selectedRule,
        saveChanges,
        undoChanges,
        selectedSubTree,
        deleteRuleHandler,
        setTree,
        getRules,
        crmColumns,
        variableOptions1,
        variableOptions2,
        tableOption1,
        tableOption2,
        setTableOption1,
        setTableOption2,
        applyRule,
        aliasTree,
        aliasGiver,
    };
}
