import React, { useEffect, useState } from "react";
import { CurrentClient, TableVarLogs, User } from "./types";
import { useAuth } from "../layouts/authentication/sign-in/illustration/utils/useAuth";
import { ClientsResponse, useUserLazyQuery } from "graphql/types/graphql";
import { useClientsLazyQuery } from "graphql/types/graphql";
import { useRefreshTokenMutation } from "graphql/types/graphql";
import { CRUXY_ADMIN, CRUXY_USER, TOKEN_STORAGE } from "../common/names";

export interface ContextData {
    user?: User | undefined;
    setUser: (value: User) => void;
    token: string;
    setToken: (value: string) => void;
    logIn: (email: string, password: string) => Promise<boolean | string>;
    logOut: () => void;
    appLoading: boolean;
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
    setUpApp: () => void;
    companies: ClientsResponse[];
    selectedCompany: CurrentClient;
    setSelectedCompany: (value: CurrentClient) => void;
    refreshAccessToken: () => Promise<boolean | string>;
    setShowScroll: (value: boolean) => void;
    showScroll: boolean;
}

export const context = React.createContext<ContextData>({
    user: undefined,
    setUser: () => null,
    token: "",
    setToken: () => null,
    logIn: () => Promise.resolve(""),
    logOut: () => null,
    appLoading: true,
    isLoading: true,
    setIsLoading: () => null,
    setUpApp: () => null,
    companies: [],
    selectedCompany: null,
    setSelectedCompany: () => null,
    refreshAccessToken: () => Promise.resolve(false),
    setShowScroll: () => null,
    showScroll: false,
});

export function ContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const [appLoading, setAppLoading] = useState(true);
    const [identifyByToken] = useUserLazyQuery({ fetchPolicy: "no-cache" });
    const [getCompanies] = useClientsLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [getNewAccessToken] = useRefreshTokenMutation({ fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });

    const [companies, setCompanies] = useState<ClientsResponse[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<CurrentClient | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState("");

    const { user, setUser, logIn, logOut } = useAuth(getCompanies, getUserInfo, setToken);

    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        if (selectedCompany) {
            localStorage.setItem("currentClient", selectedCompany.cruxyId.toString());
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (user && token && selectedCompany) {
            setAppLoading(false);
        }
    }, [user, token, selectedCompany]);

    useEffect(() => {
        if (!user) {
            setSelectedCompany(null);
            setCompanies([]);
            setToken("");
        }
    }, [user, token, selectedCompany]);

    async function setUpApp() {
        try {
            setAppLoading(true);
            const newToken = await getNewAccessToken();
            if (newToken) {
                getUserInfo(newToken.data.refreshToken);
            } else {
                setAppLoading(false);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            setAppLoading(false);
            setIsLoading(false);
        }
    }

    async function getUserInfo(myToken: string) {
        const userInfo = await identifyByToken({
            fetchPolicy: "no-cache",
            context: {
                headers: {
                    Authorization: myToken,
                },
            },
        });

        if (userInfo) {
            if (userInfo.data.me.role === CRUXY_ADMIN || userInfo.data.me.role === CRUXY_USER) {
                const data = await getCompanies({
                    fetchPolicy: "no-cache",
                    variables: {
                        filter: {
                            cruxyId: null,
                        },
                    },
                    context: {
                        headers: {
                            Authorization: myToken,
                        },
                    },
                });
                if (data) {
                    const companies = data.data.clients;
                    setCompanies(companies);
                    const company = localStorage.getItem("currentClient");

                    const result = companies?.find((client: ClientsResponse) => {
                        return client.cruxyId === company;
                    });

                    if (result) {
                        setSelectedCompany({
                            cruxyId: result.cruxyId,
                            name: result.name,
                        });
                        setToken(myToken);
                    } else {
                        setSelectedCompany({ cruxyId: companies[0].cruxyId, name: companies[0].name });
                        setToken(myToken);
                    }
                }
                setUser({
                    id: userInfo.data.me.id,
                    cruxyId: userInfo.data.me.cruxyId,
                    role: userInfo.data.me.role,
                });
            } else {
                setUser({
                    id: userInfo.data.me.id,
                    cruxyId: userInfo.data.me.cruxyId,
                    role: userInfo.data.me.role,
                });
                setToken(myToken);
                setSelectedCompany({ cruxyId: userInfo.data.me.cruxyId });
                setAppLoading(false);
            }
        }
    }

    async function refreshAccessToken() {
        try {
            //setIsLoading(true);
            const response = await getNewAccessToken();
            if (response) {
                setToken(response.data.refreshToken);
                setIsLoading(false);
                return response.data.refreshToken;
            } else {
                //setIsLoading(false);
                return null;
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            return false;
        }
    }

    return (
        <context.Provider
            value={{
                user,
                setUser,
                token,
                setToken,
                logIn,
                logOut,
                appLoading,
                setUpApp,
                companies,
                selectedCompany,
                setSelectedCompany,
                isLoading,
                setIsLoading,
                refreshAccessToken,
                showScroll,
                setShowScroll,
            }}
        >
            {children}
        </context.Provider>
    );
}
