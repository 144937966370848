import { ClickAwayListener, Tab, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { addOpacityToColor } from "utils/addOpacityToColor";
import { testIds } from "utils/testIds";

function MyTab(props: {
    handleChange: (value: number) => void;
    index: number;
    id: string;
    name: string;
    selected: boolean;
    last: boolean;
    saveNew: () => void;
    setSelected: (value: number) => void;
    saveExistingHeader: (id: string, name: string, cruxyId: string) => void;
    classes: any;
}) {
    const { handleChange, index, selected, setSelected, name, saveExistingHeader, id, classes, last } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(name);
    const [width, setWidth] = useState<number>();
    const widthRef = useRef<any>(null);

    useEffect(() => {
        if (widthRef.current) {
            setWidth(widthRef.current.offsetWidth);
        }
    }, []);

    useEffect(() => {
        if (!isEditing && value !== name) {
            saveExistingHeader(id, value, "maya");
        }
    }, [isEditing, value]);

    function handleEnter(event: any) {
        if (event.key === "Enter") {
            setIsEditing(false);
        }
    }

    return (
        <ClickAwayListener onClickAway={() => setIsEditing(false)}>
            <MDBox
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: colors.dark.main,
                    backgroundColor: selected ? colors.primary.main : "transparent",
                    fontSize: "1rem",
                    height: "3rem",
                    textTransform: "none",
                }}
            >
                {isEditing ? (
                    <TextField
                        autoFocus
                        fullWidth
                        inputProps={{ "data-testid": testIds.tabInput }}
                        sx={{
                            fontSize: "1rem",
                            color: colors.white.main,
                            border: "none",
                            borderRight: last ? "none" : `3px solid ${colors.background.default}`,
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            minWidth: "160px",
                            background: "rgba(255, 255, 255, 0.2)",
                            boxShadow: "0 4px 30px rgba(255, 255, 255, 0.2)",
                            backdropFilter: "blur(15.9px)",
                            WebkitBackdropFilter: "blur(15.9px)",
                            width: width ? width : "auto",
                            input: {
                                //color: colors.dark.main,
                                fontWeight: 600,
                                height: "3rem",
                                textAlign: "center",
                            },
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        variant="standard"
                        value={value}
                        onChange={(e: any) => setValue(e.target.value)}
                        onKeyDown={handleEnter}
                    />
                ) : (
                    <MDBox
                        ref={widthRef}
                        data-testid={testIds.tab}
                        onDoubleClick={() => setIsEditing(true)}
                        sx={{
                            backgroundColor: selected ? colors.primary.main : "transparent",

                            color: selected ? colors.dark.main : addOpacityToColor(colors.primary.main, 0.8),
                            borderRight: last ? "none" : `3px solid ${colors.background.default}`,
                            background: "rgba(255, 255, 255, 0.2)",
                            boxShadow: "0 4px 30px rgba(255, 255, 255, 0.2)",
                            backdropFilter: "blur(15.9px)",
                            WebkitBackdropFilter: "blur(15.9px)",
                            fontSize: "1rem",
                            height: "3rem",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            textTransform: "none",
                            opacity: 1,
                            minWidth: "160px",
                            //maxWidth: "15rem",
                            //textWrap: "wrap",
                            fontWeight: 600,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            userSelect: "none",
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() => {
                            handleChange(index);
                            setSelected(index);
                        }}
                    >
                        {name}
                    </MDBox>
                )}
            </MDBox>
        </ClickAwayListener>
    );
}

export default MyTab;
