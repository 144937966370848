import MDBox from "components/MDBox";

import { useContext, useEffect, useRef, useState } from "react";
import {
    CrmTables,
    CustomColumn,
    useCompaniesLazyQuery,
    useCrmTablesQuery,
    useCustomColumnsLazyQuery,
    useDealsLazyQuery,
} from "graphql/types/graphql";
import { Log, Table } from "utils/types";

import { useSnackbar } from "notistack";
import Tabs from "components/Tabs/Tabs";

import Utils from "./components/Utils";

import Rules from "./components/Rules";
import { context } from "utils/Provider";
import { DebouncedFunc, debounce } from "lodash";
import setStorageTable from "./utils/setTable";
import getTable from "./utils/getTable";
import { linearGradientStyle } from "utils/linearGradientsStyle";
import { prepareTableDataForCompanies, prepareTableDataForDeals } from "./utils/prepareTableData";
import Header from "./components/Header";
import MyTable from "./components/Table";

function CrmData(): JSX.Element {
    const { isLoading, setIsLoading, selectedCompany } = useContext(context);

    const { enqueueSnackbar } = useSnackbar();

    const [crmTables, setCrmTables] = useState<CrmTables[]>();
    const [table, setTable] = useState<Table | null>(null);
    const [columns, setColumns] = useState<CustomColumn[]>();
    const [currentTable, setCurrentTable] = useState<CrmTables>();
    const [customColumns, setCustomColumns] = useState<string[]>([]);

    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState(10);

    const [selected, setSelected] = useState(0);

    const [filterBy, setFilterBy] = useState<string | undefined>();
    const [dealName, setDealName] = useState<string | null>(null);
    const [fiscalYear, setFiscalYear] = useState<string | null>(null);
    const [type, setType] = useState<string | null>(null);
    const [country, setCountry] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);

    const { error: errorTables, data: dataTables, loading: loadingTables } = useCrmTablesQuery();
    const [getColumns, { error: columnError, data: columnData, loading: columnLoading }] = useCustomColumnsLazyQuery({
        notifyOnNetworkStatusChange: true,
    });

    const [getDeals, { error, data, loading, refetch: refetchDeals }] = useDealsLazyQuery({
        notifyOnNetworkStatusChange: true,
    });

    const [
        getCompanies,
        { error: errorCompanies, data: dataCompanies, loading: loadingCompanies, refetch: refetchCompanies },
    ] = useCompaniesLazyQuery({ notifyOnNetworkStatusChange: true });

    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const [selectedRow, setSelectedRow] = useState<any>();
    const [column, setColumn] = useState<any>();

    const debounceRef = useRef<DebouncedFunc<(criteria: any) => void> | undefined>();

    useEffect(() => {
        resetTabs();
    }, [selectedCompany]);

    useEffect(() => {
        if (currentTable) {
            if (currentTable?.name === "Deals") {
                getDealsHandler();
            }
            if (currentTable?.name === "Companies") {
                getCompaniesHandler();
            }
            if (currentTable?.name !== "Companies" && currentTable?.name !== "Deals") {
                setCurrentTable(dataTables.crmTables[0]);
            }
        }
    }, [currentTable, currentPage, pageSize, fiscalYear, dealName, type, country, name, selectedCompany, dataTables]);

    useEffect(() => {
        setCurrentPage(0);
        if (currentTable) {
            setStorageTable(currentTable);
            getColumnsHandler(currentTable.id);
        }
    }, [currentTable]);

    useEffect(() => {
        if (errorTables) console.log(JSON.stringify(errorTables));
        if (dataTables?.crmTables) {
            setCrmTables(dataTables.crmTables);
            const storageTable = getTable();
            setCurrentTable(storageTable ? storageTable : dataTables.crmTables[0]);
        }
    }, [errorTables, dataTables, selectedCompany]);

    useEffect(() => {
        if (data?.deals.edges.length === 0 && currentTable.name === "Deals") setTable({ columns: [], rows: [] });
        if (error) console.log("dealsTable", error);
        if (data?.deals?.edges.length === 0) {
            enqueueSnackbar("No result were found for the given parameters!", { variant: "error" });
        }
        if (data?.deals?.edges.length > 0) {
            const tableData = prepareTableDataForDeals(data);
            setTable({
                columns: tableData.finalColumns.map((item) => {
                    return { Header: item, accessor: item };
                }),
                rows: tableData.finalRows,
            });
            setTotal(parseInt(data.deals.totalCount));
            setCustomColumns(tableData.customColumns);
        }
    }, [data, error, currentTable]);

    useEffect(() => {
        if (dataCompanies?.companies?.edges.length === 0 && currentTable.name === "Companies")
            setTable({ columns: [], rows: [] });
        if (dataCompanies?.companies?.edges.length > 0 && currentTable.name === "Companies") {
            const tableData = prepareTableDataForCompanies(dataCompanies);
            setTable({
                columns: tableData.finalColumns.map((item) => {
                    return { Header: item, accessor: item };
                }),
                rows: tableData.finalRows,
            });
            setCustomColumns(tableData.customColumns);
            setTotal(parseInt(dataCompanies.companies.totalCount));
        }
    }, [dataCompanies, errorCompanies, currentTable]);

    useEffect(() => {
        if (columnData?.customColumns) {
            setColumns(columnData.customColumns);
        }
        if (columnError) console.log("columnerror", columnError);
    }, [columnData, columnError]);

    useEffect(() => {
        setIsLoading(
            Boolean(
                (!data && !error && !dataCompanies && !errorCompanies) ||
                    (!columnData && !columnError) ||
                    loading ||
                    columnLoading ||
                    loadingCompanies
            )
        );
    }, [loading, loadingCompanies, columnLoading, error, errorCompanies, columnError, data, dataCompanies, columnData]);

    useEffect(() => {
        setCurrentPage(0);
    }, [pageSize]);

    async function getColumnsHandler(id: string) {
        try {
            const response = await getColumns({
                variables: {
                    filter: {
                        cruxyId: selectedCompany.cruxyId,
                        tableId: id,
                    },
                },
            });
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
        }
    }

    function getDealsHandler() {
        getDeals({
            variables: {
                limit: pageSize,
                offset: currentPage * pageSize,
                filter: {
                    cruxyId: selectedCompany.cruxyId,
                    fiscalYear: fiscalYear,
                    dealName: dealName,
                    type: type,
                },
            },
        });
    }

    function getCompaniesHandler() {
        getCompanies({
            variables: {
                limit: pageSize,
                offset: currentPage * pageSize,
                filter: {
                    cruxyId: selectedCompany.cruxyId,
                    country: country,
                    name: name,
                },
            },
        });
    }

    function resetTabs() {
        setTable(undefined);
        setCurrentPage(0);
        setSelected(selected === 2 ? 0 : selected);
    }

    function openModal(row: any, column: any) {
        setSelectedRow(row);
        setColumn(column);
        setOpen(true);
    }

    return (
        <>
            {!table || !columns || !crmTables ? (
                <></>
            ) : (
                <>
                    <Tabs
                        selected={selected}
                        setSelected={setSelected}
                        columns={columns}
                        tabs={["Table", "Utils", "Rules"]}
                    />
                    <MDBox
                        sx={{
                            borderRadius: "0",
                            boxShadow: "none",
                            width: "100%",
                            display: selected !== 0 ? "flex" : "block",
                            flexDirection: selected == 1 || selected == 2 ? "column" : "row",
                            flex: 1,
                            padding: "1rem",
                            ...linearGradientStyle,
                        }}
                    >
                        <Header
                            selected={selected}
                            currentTable={currentTable}
                            crmTables={crmTables}
                            setCurrentTable={setCurrentTable}
                            debounceRef={debounceRef}
                            filterBy={filterBy}
                            setDealName={setDealName}
                            setFiscalYear={setFiscalYear}
                            setFilterBy={setFilterBy}
                            setType={setType}
                            setCountry={setCountry}
                            setName={setName}
                        />

                        {selected === 0 && (
                            <MyTable
                                currentTable={currentTable}
                                total={total}
                                loading={loading}
                                table={table}
                                openModal={openModal}
                                pageI={parseInt(currentPage + "") + 1}
                                setPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                open={open}
                                customColumns={customColumns}
                                handleClose={handleClose}
                                selectedRow={selectedRow}
                                column={column}
                            />
                        )}
                        {selected === 1 && (
                            <Utils currentTable={currentTable} setIsLoading={setIsLoading} columns={columns} />
                        )}
                        {selected === 2 && columns?.length !== 0 && (
                            <Rules
                                tables={crmTables}
                                currentTable={currentTable}
                                columns={columns}
                                setIsLoading={setIsLoading}
                                getDealsHandler={refetchDeals}
                                getCompaniesHandler={refetchCompanies}
                            />
                        )}
                    </MDBox>
                </>
            )}
        </>
    );
}

export default CrmData;
