import { CircularProgress, Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { scrollbar } from "utils/scrollBar";
import MatchesTable from "./MatchesTable";
import ChangesColumn from "./ChangesColumn";
import { useContext, useEffect, useState } from "react";
import { InvoiceLog, useApplyInvoicesMutation, useGetApplyInvoicesPlanQuery } from "graphql/types/graphql";
import { context } from "utils/Provider";
import { enqueueSnackbar } from "notistack";
import MDButton from "components/MDButton";
import confirmSnack from "utils/confirmSnack";

const titleStyle = {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: colors.primary.main,
    textAlign: "center",
    marginBottom: "2rem",
};

function LogsPlan({ refetchLogDates }: { refetchLogDates: () => void }) {
    const { setIsLoading, selectedCompany } = useContext(context);

    const [matchesIndex, setMatchesIndex] = useState(0);

    const [matches, setMatches] = useState<InvoiceLog[]>();
    const [errorMess, setErrorMess] = useState<string | undefined>();

    const { data, loading, error } = useGetApplyInvoicesPlanQuery({
        variables: { cruxyId: selectedCompany.cruxyId },
    });

    const [applyInvoices, { loading: loadingMutation }] = useApplyInvoicesMutation();

    useEffect(() => {
        if (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            console.log(error);
        }

        if (data) {
            prepareMatches(data.getApplyInvoicesPlan.companies);
            setErrorMess(undefined);
        }
    }, [error, data]);

    useEffect(() => {
        setMatchesIndex(0);
    }, [selectedCompany]);

    function prepareMatches(invoiceLogs: InvoiceLog[]) {
        if (invoiceLogs.length === 0) {
            setErrorMess("No logs were found for the selected date!");
            return;
        } else {
            setMatches(invoiceLogs);
        }
    }

    async function applyInvoicesHandler() {
        try {
            const response = await applyInvoices({ variables: { cruxyId: selectedCompany.cruxyId } });
            if (response) {
                refetchLogDates();
                enqueueSnackbar("Invoices has been applied!", { variant: "success" });
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
        }
    }

    function confirmApplyHandler() {
        confirmSnack(
            applyInvoicesHandler,
            `Do you really want to apply ${data.getApplyInvoicesPlan.companies.length} invoices?`
        );
    }

    if (loading || loadingMutation) {
        return (
            <>
                <MDBox
                    sx={{
                        marginTop: "4rem",
                        display: "flex",
                        //border: "1px solid white",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "transparent",
                    }}
                >
                    <Typography
                        sx={{ fontSize: "1.25rem", fontWeight: 600, color: colors.white.main, marginBottom: "4rem" }}
                    >
                        Please stand by, this will take some time!
                    </Typography>
                    <CircularProgress color="primary" />
                </MDBox>
            </>
        );
    }

    return (
        <>
            {errorMess && (
                <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", color: colors.white.main }}>
                    {errorMess}
                </Typography>
            )}
            {matches && (
                <MDBox
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
                        flex: 1,
                        background: "transparent",
                    }}
                >
                    <MDBox>
                        <Typography sx={titleStyle}>Matches</Typography>
                        <MDBox
                            sx={{
                                overflow: "auto",
                                ...scrollbar,
                                marginBottom: "1.5rem",
                                background: "transparent",
                            }}
                        >
                            <MatchesTable
                                matchesIndex={matchesIndex}
                                matches={matches}
                                setMatchesIndex={setMatchesIndex}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div>
                            <Typography sx={titleStyle}>Changes</Typography>
                            <ChangesColumn
                                buttonVisible={true}
                                changes={matches[matchesIndex].changes}
                                confirmApplyHandler={confirmApplyHandler}
                            />
                        </div>
                        <MDBox
                            sx={{
                                display: "flex",
                                margin: "2rem",
                                alignItems: "center",
                                gap: 2,
                            }}
                        ></MDBox>
                    </MDBox>
                </MDBox>
            )}
        </>
    );
}

export default LogsPlan;
