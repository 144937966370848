function formatDate(date: string) {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const hour = currentDate.getHours();
    const minute = currentDate.getMinutes();
    const second = currentDate.getSeconds();

    return (
        year +
        "-" +
        formatNumber(month) +
        "-" +
        formatNumber(day) +
        " " +
        formatNumber(hour) +
        ":" +
        formatNumber(minute) +
        ":" +
        formatNumber(second)
    );
}

function formatNumber(number: number) {
    return number < 10 ? "0" + number : number;
}

export default formatDate;
