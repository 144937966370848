import {
    CustomColumn,
    Duration,
    InstancesResponse,
    PipelineInstance,
    PipelinesQuery,
    Stage,
    StagesResponse,
} from "graphql/types/graphql";

export default function buildNewPipeline(
    template: {
        columns: CustomColumn[];
        stages: StagesResponse[];
        instances: InstancesResponse[];
    },
    pipelineIndex: number,
    data: PipelinesQuery
) {
    const currentStages = template.stages.slice();
    currentStages.sort((a: any, b: any) => {
        return a.displayOrder - b.displayOrder;
    });
    const stages: Stage[] = currentStages.map((stage: StagesResponse, index: number) => {
        return {
            id: stage.id,
            name: stage.label,
            orderNumber: index,
        };
    });

    const pipelineInstances: PipelineInstance[] = template.instances.map(
        (instance: InstancesResponse, index: number) => {
            return {
                id: index.toString(),
                averageAmount: instance.averageAmount,
                name: instance.name,
                orderNumber: index,
            };
        }
    );
    const sortedInstances = pipelineInstances.slice().sort(function (a: any, b: any) {
        return a.averageAmount - b.averageAmount;
    });
    const durations: Duration[] = [];
    const fullDuration: any = stages.map((stage: Stage) => {
        const stageDurations = sortedInstances.map((instance: PipelineInstance, index: number) => {
            durations.push({
                duration: 30 + 15 * index,
                pipelineInstanceId: instance.id,
                stageId: stage.id,
                conversionRate: 1,
                numberOfDeals: 0,
            });
            return;
        });
    });

    const myNewPipeline = {
        pipelineId: data.pipelines[pipelineIndex].id,
        input: {
            pipeline: {
                durations: durations,
                schema: {
                    pipelineInstances: pipelineInstances,
                    stages: stages,
                },
            },
        },
    };

    return myNewPipeline;
}
