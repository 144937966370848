import { Breadcrumbs, ClickAwayListener, Theme, Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { Duration, PipelinesQuery, PipelinesResponse } from "graphql/types/graphql";
import Header from "./Header";
import { useMaterialUIController } from "context";
import MyBoard from "./Board";
import { testIds } from "utils/testIds";
import Visual from "./Visual/Visual";
import { PageData } from "../utils/types";
import CustomButton from "components/CustomButton";
import BreadCrumb from "./BreadCrumb";
import Table from "./Table";

interface Props {
    data: PipelinesQuery;
    pageData: PageData;
    getStagesHandler: (id: string) => void;
    updatePageData: (object: Partial<PageData>) => void;
    undoChanges: () => void;
    saveChanges: () => void;
    boardMaker: (value: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId">) => void;
    handleDuration: (duration: Duration) => void;
    handleConversionRate: (conversion: { stageId: string; conversion: number }) => void;
    handleStageName: (value: { stageId: string; name: string }) => void;
    handleInstanceName: (id: string, name: string) => void;
    removeStage: (stageId: string) => void;
    changeOrder: (stage1: number, stage2: number, newBoard: any) => void;
    calculateConversions: () => Promise<void>;
    setupTemplate: () => void;
}

function TableContent(props: Props) {
    const {
        data,
        getStagesHandler,
        saveChanges,
        undoChanges,
        boardMaker,
        handleDuration,
        handleConversionRate,
        handleStageName,
        handleInstanceName,
        removeStage,
        changeOrder,
        calculateConversions,
        setupTemplate,
        pageData,
        updatePageData,
    } = props;

    const { currentPipeline, newPipeline, currentlyEditing, pipelineIndex, isVisible, isConversionVisible } = pageData;

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <>
            <MDBox
                sx={{
                    display: "flex",
                    flexDirection: { xxl: "row", lg: "row", md: "row", xs: "column" },
                    width: { xxl: "fit-content", lg: "fit-content", md: "100%", xs: "100%" },
                    justifyContent: { xxl: "flex-start", lg: "flex-start", md: "flex-start", xs: "center" },
                    gap: 3,
                    //border: "1px solid white",
                    paddingLeft: { xxl: "3rem", lg: "3rem", md: "3rem", xs: "0rem" },
                    paddingBottom: 0,
                    paddingTop: "1.5rem",
                }}
                data-testid={testIds.breadCrumbSelect}
            >
                <BreadCrumb
                    isConversionVisible={isConversionVisible}
                    updatePageData={updatePageData}
                    isVisible={isVisible}
                />
            </MDBox>
            {isConversionVisible === 3 ? (
                <Visual key={data?.pipelines[pipelineIndex].id} pipelineId={data?.pipelines[pipelineIndex].id} />
            ) : (
                <Table
                    getStagesHandler={getStagesHandler}
                    saveChanges={saveChanges}
                    undoChanges={undoChanges}
                    boardMaker={boardMaker}
                    handleDuration={handleDuration}
                    handleConversionRate={handleConversionRate}
                    handleStageName={handleStageName}
                    handleInstanceName={handleInstanceName}
                    removeStage={removeStage}
                    changeOrder={changeOrder}
                    calculateConversions={calculateConversions}
                    setupTemplate={setupTemplate}
                    pageData={pageData}
                    updatePageData={updatePageData}
                />
            )}
        </>
    );
}

export default TableContent;
