import { useContext, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import * as Yup from "yup";
// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "../../../../assets/images/illustrations/illustration-reset.jpg";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { TextField, Typography, makeStyles } from "@mui/material";
import { context } from "utils/Provider";
import LinearProgress from "@mui/material/LinearProgress";

interface FormValue {
    email: string;
    password: string;
}

const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email!").required("Email required!"),
    password: Yup.string().required("Password required!"),
});

function Login(): JSX.Element {
    const { logIn } = useContext(context);
    const [isLoading, setIsLoading] = useState(false);
    const [errorUser, setErrorUser] = useState<string>(" ");
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPass, setErrorPass] = useState(false);

    return (
        <IllustrationLayout
            title="Sign In"
            description="Enter your email and password to sign in"
            illustration={bgImage}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: -10,
                    width: "120vw",
                    display: isLoading ? "block" : "none",
                }}
            >
                <LinearProgress color="primary" />
            </div>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    checked: false,
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values) => {
                    setIsLoading(true);
                    const email = values.email;
                    const password = values.password;
                    const response = await logIn(email, password);
                    if (typeof response === "string") {
                        setErrorUser(response);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                }}
            >
                <Form style={{ width: "100%" }}>
                    <Field sx={{ width: "100%" }} name="email">
                        {(props: FieldProps<string, FormValue>) => {
                            return (
                                <TextField
                                    onBlur={() => setErrorEmail(true)}
                                    inputProps={{ "data-testid": "emailForm" }}
                                    sx={{ width: "100%", marginBottom: "1.5rem" }}
                                    name="Email"
                                    label={"Email"}
                                    color="secondary"
                                    autoFocus
                                    required
                                    value={props.field.value}
                                    onChange={(e) => {
                                        setErrorUser(" ");
                                        setErrorEmail(false);
                                        props.form.setFieldValue(props.field.name, e.target.value, true);
                                    }}
                                />
                            );
                        }}
                    </Field>
                    <Field name="password">
                        {(props: FieldProps<string, FormValue>) => {
                            return (
                                <TextField
                                    onBlur={() => setErrorPass(true)}
                                    inputProps={{ "data-testid": "passwordForm" }}
                                    sx={{ width: "100%", "& input::placeholder": { fontSize: "10px" } }}
                                    color="secondary"
                                    name="Password"
                                    label={"Password"}
                                    type="password"
                                    required
                                    value={props.field.value}
                                    onChange={(e) => {
                                        setErrorUser(" ");
                                        setErrorPass(false);
                                        props.form.setFieldValue(props.field.name, e.target.value, true);
                                    }}
                                />
                            );
                        }}
                    </Field>

                    <MDBox mt={2} sx={{ minHeight: "2rem" }}>
                        {errorUser?.length === 1 ? (
                            <Test errorEmail={errorEmail} erroPass={errorPass} />
                        ) : (
                            <MDTypography mt={2} color="error">
                                {errorUser}
                            </MDTypography>
                        )}
                    </MDBox>

                    <MDBox mt={2} mb={1}>
                        <MDButton
                            data-testid="loginBtn"
                            type="submit"
                            variant="gradient"
                            color="secondary"
                            size="large"
                            fullWidth
                        >
                            sign in
                        </MDButton>
                    </MDBox>
                </Form>
            </Formik>
        </IllustrationLayout>
    );
}

export default Login;

function Test(props: { errorEmail: boolean; erroPass: boolean }): JSX.Element {
    const { errorEmail, erroPass } = props;
    const context = useFormikContext<FormValue>();
    const item = context.errors;
    if (Object.keys(item).length === 0) {
        return (
            <MDTypography mt={2} color="error">
                {" "}
            </MDTypography>
        );
    }
    let err: any;
    if (errorEmail) {
        err = item.email;
    } else if (erroPass) {
        err = item.password;
    } else {
        err = " ";
    }

    return (
        <MDTypography mt={2} color="error">
            {err}
        </MDTypography>
    );
}
