import { useEffect, useState, ReactNode, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import LogoutIcon from "@mui/icons-material/Logout";
import cruxy from "assets/images/cruxy.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from "context";
import { IconButton, ListItem, ListItemIcon, ListItemText, colors } from "@mui/material";
import { collapseArrow, collapseIcon, collapseIconBox, collapseItem, collapseText } from "./styles/sidenavCollapse";
import { useSignOutMutation } from "graphql/types/graphql";
import { context } from "utils/Provider";
import { useApolloClient } from "@apollo/client";
import { default as myColors } from "assets/theme-dark/base/colors";
import { scrollbar } from "utils/scrollBar";
import pxToRem from "assets/theme-dark/functions/pxToRem";
import { motion, stagger, useAnimate, usePresence } from "framer-motion";

// Declaring props types for Sidenav
interface Props {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    brand?: string;
    brandName: string;
    routes: any[];
    [key: string]: any;
    undefineToken: () => void;
}

function Sidenav({ color, brand, brandName, routes, undefineToken, ...rest }: Props): JSX.Element {
    const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const items = pathname.split("/").slice(1);
    const itemParentName = items[1];
    const itemName = items[items.length - 1];
    const apolloClient = useApolloClient();

    const { setUser, setToken, setIsLoading, setShowScroll, isLoading, user } = useContext(context);

    const [signout] = useSignOutMutation({ fetchPolicy: "no-cache" });
    const [scopeNav, animate] = useAnimate();
    const [isPresent, safeToRemove] = usePresence();
    const [animationFinished, setAnimationFinished] = useState(false);

    useEffect(() => {
        if (isPresent && scopeNav.current && !isLoading && !animationFinished && user) {
            const enterAnimation = async () => {
                await animate("li", { opacity: [0, 1], y: [-20, 0] }, { delay: stagger(0.35), stiffness: 2500 });
                await animate("img", { opacity: [0, 1] }, { delay: stagger(0.35) });
                setAnimationFinished(true);
            };
            enterAnimation();
        }
    }, [isPresent, isLoading, user, animationFinished]);

    let textColor:
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning"
        | "error"
        | "dark"
        | "white"
        | "inherit"
        | "text"
        | "light" = "white";

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    } else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }

    useEffect(() => {
        setOpenCollapse(collapseName);
        setOpenNestedCollapse(itemParentName);
    }, []);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        //handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse: any) => {
        const template = collapse.map(({ name, route, key, href }: any) =>
            href ? (
                <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                    <SidenavItem name={name} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
                    <SidenavItem name={name} active={route === pathname} nested />
                </NavLink>
            )
        );

        return template;
    };
    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses: any) =>
        collapses.map(({ name, collapse, route, href, key }: any) => {
            let returnValue;

            if (collapse) {
                returnValue = (
                    <SidenavItem
                        key={key}
                        color={color}
                        name={name}
                        active={key === itemParentName ? "isParent" : false}
                        open={openNestedCollapse === key}
                        onClick={({ currentTarget }: any) =>
                            openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                                ? setOpenNestedCollapse(false)
                                : setOpenNestedCollapse(key)
                        }
                    >
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                );
            } else {
                returnValue = href ? (
                    <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
                        <SidenavItem color={color} name={name} active={key === itemName} />
                    </NavLink>
                );
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>;
        });

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }: any) => {
        let returnValue;
        //if (name === "Manage Users") return;
        if (type === "collapse") {
            if (href) {
                returnValue = (
                    <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                        <SidenavCollapse
                            name={name}
                            icon={icon}
                            active={key === collapseName}
                            noCollapse={noCollapse}
                        />
                    </Link>
                );
            } else if (noCollapse && route) {
                returnValue = (
                    <NavLink to={route} key={key}>
                        <SidenavCollapse
                            name={name}
                            icon={icon(key === collapseName ? myColors.dark.main : myColors.secondary.main)}
                            noCollapse={noCollapse}
                            active={key === collapseName}
                        >
                            {collapse ? renderCollapse(collapse) : null}
                        </SidenavCollapse>
                    </NavLink>
                );
            } else {
                returnValue = (
                    <SidenavCollapse
                        key={key}
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        open={openCollapse === key}
                        onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
                    >
                        {collapse ? renderCollapse(collapse) : null}
                    </SidenavCollapse>
                );
            }
        } else if (type === "divider") {
            returnValue = (
                <Divider
                    key={key}
                    light={
                        (!darkMode && !whiteSidenav && !transparentSidenav) ||
                        (darkMode && !transparentSidenav && whiteSidenav)
                    }
                />
            );
        }

        return returnValue;
    });
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    async function signOutHandler() {
        try {
            setIsLoading(true);
            const response = await signout({
                fetchPolicy: "no-cache",
            });
            const clear = await apolloClient.clearStore();
            if (response && clear) {
                undefineToken();
                setToken(undefined);
                setUser(undefined);
                setIsLoading(false);
            }
        } catch (error) {
            undefineToken();
            setToken(undefined);
            setUser(undefined);
            setIsLoading(false);
        }
    }

    return (
        <MDBox
            ref={scopeNav}
            sx={{
                width: { xxl: "auto", lg: "auto", md: "auto", xs: "100%" },
                backgroundColor: myColors.background.card,
                borderRadius: 0,
            }}
            onMouseEnter={() => {
                setShowScroll(true);
            }}
            onMouseLeave={() => {
                setShowScroll(false);
            }}
        >
            <SidenavRoot
                {...rest}
                variant="permanent"
                ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
            >
                <MDBox
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",

                        "@media (min-width:1200px)": {
                            display: "none",
                        },
                    }}
                >
                    <IconButton onClick={handleMiniSidenav}>
                        <NavigateBeforeIcon sx={{ color: "white !important", width: "2rem", height: "2rem" }} />
                    </IconButton>
                </MDBox>
                <MDBox pt={3} pb={1} px={4} textAlign="center" position="relative">
                    <MDBox
                        sx={{
                            //marginLeft: miniSidenav ? 0 : pxToRem(8),
                            transition: "all 0.25s ease-out",
                            position: "absolute",
                            left: miniSidenav ? "50%" : "50%",
                            transform: miniSidenav ? "translateX(-50%)" : "translateX(-50%)",
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <MDBox
                            sx={{ transition: "all 0.25s ease-out", opacity: 0 }}
                            component="img"
                            src={cruxy}
                            alt="Brand"
                            width={miniSidenav ? "2rem" : "6rem"}
                        />
                    </MDBox>
                </MDBox>
                <Divider
                    sx={{ transition: "all 0.25s ease-out", marginTop: miniSidenav ? "2rem" : "6rem" }}
                    light={
                        (!darkMode && !whiteSidenav && !transparentSidenav) ||
                        (darkMode && !transparentSidenav && whiteSidenav)
                    }
                />
                <List>{renderRoutes}</List>
                <ListItem
                    onClick={signOutHandler}
                    component="li"
                    sx={{ textAlign: "start", opacity: 0, transform: "translateY:(-20)" }}
                >
                    <MDBox
                        {...rest}
                        sx={(theme: any) =>
                            collapseItem(theme, { active: false, transparentSidenav, whiteSidenav, darkMode })
                        }
                    >
                        <ListItemIcon
                            sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
                        >
                            <LogoutIcon sx={{ width: "1.5rem", height: "1.5rem", color: myColors.secondary.main }} />
                        </ListItemIcon>

                        <ListItemText
                            primary={"Sign Out"}
                            sx={(theme) =>
                                collapseText(theme, {
                                    miniSidenav,
                                    transparentSidenav,
                                    whiteSidenav,
                                    active: false,
                                })
                            }
                        />
                    </MDBox>
                </ListItem>
            </SidenavRoot>
        </MDBox>
    );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

export default Sidenav;
