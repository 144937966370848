import { Pipeline } from "./types";

export default function newStageHandler(newPipeline: Pipeline): {
    name: any;
    orderNumber: any;
}[] {
    return newPipeline.input.pipeline.schema.stages.map((stage: any) => {
        return {
            //id: stage.id,
            name: stage.name,
            orderNumber: stage.orderNumber,
        };
    });
}
