import { forwardRef, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from './components/Column'
import ColumnAdder from './components/ColumnAdder'
import withDroppable from '../withDroppable'
import { when, partialRight } from '../../services/utils'
import DefaultColumnHeader from './components/DefaultColumnHeader'
import DefaultCard from './components/DefaultCard'
import {
  getCard,
  getCoordinates,
  isAColumnMove,
  isMovingAColumnToAnotherPosition,
  isMovingACardToAnotherPosition,
} from './services'
import { useEffect} from "react";
import { moveCard, moveColumn, addColumn, removeColumn, changeColumn, addCard, removeCard } from '../../services/helpers'
import { motion, stagger, useAnimate, usePresence } from "framer-motion";
import { TextField, Typography,Tooltip  } from "@mui/material";
import colors from '../../../../../assets/theme-dark/base/colors'
import {addOpacityToColor} from "../../../../../utils/addOpacityToColor"
import {testIds} from "../../../../../utils/testIds"

const Columns = forwardRef((props, ref) => <div ref={ref} style={{ whiteSpace: 'nowrap' }} {...props} />)

const DroppableBoard = withDroppable(Columns)

function Board(props) {
  return props.initialBoard ? <UncontrolledBoard {...props} /> : <ControlledBoard isConversionVisible={props.isConversionVisible} {...props} />
}

function UncontrolledBoard({
  initialBoard,
  onCardDragEnd,
  onColumnDragEnd,
  allowAddColumn,
  renderColumnAdder,
  onNewColumnConfirm,
  onColumnRemove,
  renderColumnHeader,
  allowRemoveColumn,
  allowRenameColumn,
  onColumnRename,
  onCardNew,
  renderCard,
  allowRemoveCard,
  onCardRemove,
  onColumnNew,
  disableCardDrag,
  disableColumnDrag,
  allowAddCard,
  onNewCardConfirm,
}) {
  const [board, setBoard] = useState(initialBoard)
  const handleOnCardDragEnd = partialRight(handleOnDragEnd, { moveCallback: moveCard, notifyCallback: onCardDragEnd })
  const handleOnColumnDragEnd = partialRight(handleOnDragEnd, {
    moveCallback: moveColumn,
    notifyCallback: onColumnDragEnd,
  })

  function handleOnDragEnd({ source, destination, subject }, { moveCallback, notifyCallback }) {
    const reorderedBoard = moveCallback(board, source, destination)
    when(notifyCallback)((callback) => callback(reorderedBoard, subject, source, destination))
    setBoard(reorderedBoard)
  }

  async function handleColumnAdd(newColumn) {
    const column = renderColumnAdder ? newColumn : await onNewColumnConfirm(newColumn)
    const boardWithNewColumn = addColumn(board, column)
    onColumnNew(boardWithNewColumn, column)
    setBoard(boardWithNewColumn)
  }

  function handleColumnRemove(column) {
    const filteredBoard = removeColumn(board, column)
    onColumnRemove(filteredBoard, column)
    setBoard(filteredBoard)
  }

  function handleColumnRename(column, title) {
    const boardWithRenamedColumn = changeColumn(board, column, { title })
    onColumnRename(boardWithRenamedColumn, { ...column, title })
    setBoard(boardWithRenamedColumn)
  }

  function handleCardAdd(column, card, options = {}) {
    const boardWithNewCard = addCard(board, column, card, options)

    onCardNew(
      boardWithNewCard,
      boardWithNewCard.columns.find(({ id }) => id === column.id),
      card
    )
    setBoard(boardWithNewCard)
  }

  async function handleDraftCardAdd(column, card, options = {}) {
    const newCard = await onNewCardConfirm(card)
    handleCardAdd(column, newCard, options)
  }

  function handleCardRemove(column, card) {
    const boardWithoutCard = removeCard(board, column, card)
    onCardRemove(
      boardWithoutCard,
      boardWithoutCard.columns.find(({ id }) => id === column.id),
      card
    )
    setBoard(boardWithoutCard)
  }

  return (
    <BoardContainer
      onCardDragEnd={handleOnCardDragEnd}
      onColumnDragEnd={handleOnColumnDragEnd}
      renderColumnAdder={() => {
        if (!allowAddColumn) return null
        if (renderColumnAdder) return renderColumnAdder({ addColumn: handleColumnAdd })
        if (!onNewColumnConfirm) return null
        return <ColumnAdder onConfirm={(title) => handleColumnAdd({ title, cards: [] })} />
      }}
      {...(renderColumnHeader && {
        renderColumnHeader: (column) =>
          renderColumnHeader(column, {
            removeColumn: handleColumnRemove.bind(null, column),
            renameColumn: handleColumnRename.bind(null, column),
            addCard: handleCardAdd.bind(null, column),
          }),
      })}
      renderCard={(column, card, dragging) => {
        if (renderCard) return renderCard(card, { removeCard: handleCardRemove.bind(null, column, card), dragging })
        return (
          <DefaultCard
            dragging={dragging}
            allowRemoveCard={allowRemoveCard}
            onCardRemove={(card) => handleCardRemove(column, card)}
          >
            {card}
          </DefaultCard>
        )
      }}
      allowRemoveColumn={allowRemoveColumn}
      onColumnRemove={handleColumnRemove}
      allowRenameColumn={allowRenameColumn}
      onColumnRename={handleColumnRename}
      disableColumnDrag={disableColumnDrag}
      disableCardDrag={disableCardDrag}
      onCardNew={(column, card) => handleDraftCardAdd(column, card, allowAddCard)}
      allowAddCard={allowAddCard && onNewCardConfirm}
    >
      {board}
    </BoardContainer>
  )
}

function ControlledBoard({
  children: board,
  onCardDragEnd,
  onColumnDragEnd,
  allowAddColumn,
  renderColumnAdder,
  onNewColumnConfirm,
  onColumnRemove,
  renderColumnHeader,
  allowRemoveColumn,
  allowRenameColumn,
  onColumnRename,
  renderCard,
  allowRemoveCard,
  onCardRemove,
  disableCardDrag,
  disableColumnDrag,
  isConversionVisible
}) {
  const handleOnCardDragEnd = partialRight(handleOnDragEnd, { notifyCallback: onCardDragEnd })
  const handleOnColumnDragEnd = partialRight(handleOnDragEnd, { notifyCallback: onColumnDragEnd })

  function handleOnDragEnd({ source, destination, subject }, { notifyCallback }) {
    when(notifyCallback)((callback) => callback(subject, source, destination))
  }

  return (
    <BoardContainer
      isConversionVisible={isConversionVisible}
      onCardDragEnd={handleOnCardDragEnd}
      onColumnDragEnd={handleOnColumnDragEnd}
      renderColumnAdder={() => {
        if (!allowAddColumn) return null
        if (renderColumnAdder) return renderColumnAdder()
        if (!onNewColumnConfirm) return null
        return <ColumnAdder onConfirm={(title) => onNewColumnConfirm({ title, cards: [] })} />
      }}
      {...(renderColumnHeader && { renderColumnHeader: renderColumnHeader })}
      renderCard={(_column, card, dragging) => {
        if (renderCard) return renderCard(card, { dragging })
        return (
          <DefaultCard dragging={dragging} allowRemoveCard={allowRemoveCard} onCardRemove={onCardRemove}>
            {card}
          </DefaultCard>
        )
      }}
      allowRemoveColumn={allowRemoveColumn}
      onColumnRemove={onColumnRemove}
      allowRenameColumn={allowRenameColumn}
      onColumnRename={onColumnRename}
      disableColumnDrag={disableColumnDrag}
      disableCardDrag={disableCardDrag}
    >
      {board}
    </BoardContainer>
  )
}

function BoardContainer({
  children: board,
  renderCard,
  disableColumnDrag,
  disableCardDrag,
  renderColumnHeader,
  renderColumnAdder,
  allowRemoveColumn,
  onColumnRemove,
  allowRenameColumn,
  onColumnRename,
  onColumnDragEnd,
  onCardDragEnd,
  onCardNew,
  allowAddCard,
  isConversionVisible
}) {
  
  function handleOnDragEnd(event) {
    const coordinates = getCoordinates(event, board)
    if (!coordinates.source) return

    isAColumnMove(event.type)
      ? isMovingAColumnToAnotherPosition(coordinates) &&
        onColumnDragEnd({ ...coordinates, subject: board.columns[coordinates.source.fromPosition] })
      : isMovingACardToAnotherPosition(coordinates) &&
        onCardDragEnd({ ...coordinates, subject: getCard(board, coordinates.source) })
  }


  const [scopeNav, animate] = useAnimate();
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        
        if (isPresent && scopeNav.current) {
            const enterAnimation = async () => {
                await animate(document.getElementsByClassName("react-kanban-column"), { opacity: [0, 1],y:[-40,0]}, { delay: stagger(0.45),duration:0.5 });        
            };
            enterAnimation();
        }else{
          const exitAnimation=async ()=>{
            await animate(document.getElementsByClassName("react-kanban-column"), { opacity: [1,0],y:[0,40]}, { delay: stagger(0.45),duration:0.5 });
            safeToRemove();
          }
          exitAnimation();
        }
    }, [isPresent,board.columns.length]);

    function outputHandler(cardValue) {
          const value=isConversionVisible===0 ? cardValue.template : isConversionVisible===1 ? isNaN((cardValue.value2 * 100).toFixed(0)) ? cardValue.value2 : (cardValue.value2 * 100).toFixed(0) : cardValue.value3
          return cardValue.template.length > 8 ? value.substring(0, 8) + "..." : value;
    }

    

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
      ref={scopeNav}
      style={{ overflowY: 'hidden', display: 'flex', alignItems: 'flex-start' }} className='react-kanban-board'
      >
        <div style={{color:colors.white.main,backgroundColor:addOpacityToColor(colors.secondary.main,0.4) ,display:"flex",flexDirection:"column",alignItems:"center",minWidth: "150px",}} className='react-kanban-column'>
          <Typography
          data-testid="columnKanban"
              variant="subtitle1"
              sx={{
                  display: "inline-block",
                  width: "100%",
                  height: "50px",
                  fontSize: "14px",
                  fontWeight: "600 !important",
                  textAlign: "center",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  color:colors.white.main,
                  marginBottom:"24px",
              }}
          >
              Appendix
          </Typography>
          
          {
            board.columns[0].cards.map((card)=>{
              return(
                <Tooltip
                key={JSON.stringify(card)}
                title={
                  isConversionVisible === 1 && card.column !== "instances"
                      ? (card.value2 * 100).toFixed(0)
                      : isConversionVisible === 0
                      ? card.template
                      : card.value3
              }
                sx={{ width: `58ch` }}
                placement="top"
                >

                
              <div
              data-testid={testIds.card}
                
                        style={{
                            width: "120px",
                            height: "50px",
                            border:`2px solid rgba(255, 255, 255, 1)`,
                            borderRadius: "15px",
                            boxShadow: `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                            textOverflow: "ellipsis !important",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            paddingTop: "12.5px",
                            paddingBottom: "12.5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop:"20px",
                            backgroundColor: "transparent",}}
                        
                    >
                        {outputHandler(card)}
                    </div>
                    </Tooltip>);
            })
          }
        </div>
        <DroppableBoard droppableId='board-droppable' direction='horizontal' type='BOARD'>
          {board.columns.filter(item=>item.id!=="instances" && item.id!=="total").map((column, index) => (
            <Column
            id={column.id}
              key={column.id}
              index={index}
              renderCard={renderCard}
              renderColumnHeader={(column) =>
                renderColumnHeader ? (
                  renderColumnHeader(column)
                ) : (
                  <DefaultColumnHeader
                    allowRemoveColumn={allowRemoveColumn}
                    onColumnRemove={onColumnRemove}
                    allowRenameColumn={allowRenameColumn}
                    onColumnRename={onColumnRename}
                  >
                    {column}
                  </DefaultColumnHeader>
                )
              }
              disableColumnDrag={disableColumnDrag}
              disableCardDrag={disableCardDrag}
              onCardNew={onCardNew}
              allowAddCard={allowAddCard}
            >
              {column}
            </Column>
          ))}
        </DroppableBoard>
        <div style={{color:colors.white.main,backgroundColor:addOpacityToColor(colors.primary.main,0.4) ,display:"flex",flexDirection:"column",alignItems:"center",minWidth: "150px",}} className='react-kanban-column'>
          <Typography
          data-testid="columnKanban"
              variant="subtitle1"
              sx={{
                  display: "inline-block",
                  width: "100%",
                  height: "50px",
                  fontSize: "14px",
                  fontWeight: "600 !important",
                  textAlign: "center",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  color:colors.white.main,
                  marginBottom:"24px",
              }}
          >
              Overall
          </Typography>
          
          {
            board.columns[board.columns.length-1].cards.map((card)=>{
              return(

                <Tooltip
                key={JSON.stringify(card)}
                title={
                  isConversionVisible === 1 && card.column !== "instances"
                      ? (card.value2 * 100).toFixed(0)
                      : isConversionVisible === 0
                      ? card.template
                      : card.value3
              }
                sx={{ width: `58ch` }}
                placement="top"
                
                >
              <div
              data-testid={testIds.card}
                
                        style={{
                            width: "120px",
                            height: "50px",
                            border:`2px solid rgba(255, 255, 255, 1)`,
                            borderRadius: "15px",
                            boxShadow: `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                            textOverflow: "ellipsis !important",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            paddingTop: "12.5px",
                            paddingBottom: "12.5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop:"20px",
                            backgroundColor: "transparent",}}
                        
                    >
                        {outputHandler(card)}
                    </div></Tooltip>);
            })
          }
        </div>
      </div>
    </DragDropContext>
  )
}

export default Board
