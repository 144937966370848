import MDButton from "components/MDButton";
import { v4 } from "uuid";
import addNewObject from "../utils/addNewObject";
import { objectPropHandler } from "../utils/objectPropHandler";
import { getSubTreeById } from "../utils/getSubTreeById";
import repairTree from "../utils/repairTree";
import { testIds } from "utils/testIds";

interface Props {
    relation: string;
    variable1: string;
    variable2: string | number;
    operator: string;
    tree: any;
    subTree: any;
    isConstant: boolean;
    selectedSubTree: any;
    setUpTree: (newTree: any, subTree?: any) => void;
    setTree: (value: any) => void;
    relationOperator: string;
    isEditing: boolean;
    aliasGiver: (rule: any, relation: string, alias: string, id: string) => any;
}

function SubmitAddButton(props: Props) {
    const {
        relation,
        variable1,
        variable2,
        operator,
        tree,
        subTree,
        isConstant,
        selectedSubTree,
        setUpTree,
        setTree,
        relationOperator,
        isEditing,
        aliasGiver,
    } = props;

    function callWithRelationRegular() {
        const newCriteria = {
            id: v4(),
            [operator]: [
                {
                    value: variable1,
                    isColumn: true,
                },
                {
                    value: variable2,
                    isColumn: !isConstant,
                },
            ],
        };

        const oldCriteria = {
            ...subTree,
            id: v4(),
        };

        const newSubTree = {
            id: subTree.id,
            [objectPropHandler(subTree)]: [...oldCriteria[objectPropHandler(subTree)], newCriteria],
        };
        if (tree.id === selectedSubTree.id) {
            const aliasedTree = aliasGiver(
                newSubTree[objectPropHandler(newSubTree)],
                objectPropHandler(newSubTree),
                "C",
                newSubTree.id
            );
            const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

            const key = objectPropHandler(newSubTree);
            const repairedTree = repairTree(newSubTree[key], key, newSubTree.id);

            setUpTree(repairedTree, subAliasedTree);
            return;
        }

        const newTree = addNewObject(newSubTree, tree, selectedSubTree);

        const key = objectPropHandler(newTree);
        const repairedTree = repairTree(newTree[key], key, newTree.id);

        const aliasedTree = aliasGiver(
            newTree[objectPropHandler(newTree)],
            objectPropHandler(newTree),
            "C",
            newTree.id
        );
        const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

        setUpTree(repairedTree, subAliasedTree);
    }

    function callWithoutRelationRegular() {
        const newCriteria = {
            id: v4(),
            [operator]: [
                {
                    value: variable1,
                    isColumn: true,
                },
                {
                    value: variable2,
                    isColumn: !isConstant,
                },
            ],
        };

        const oldTree = {
            ...subTree,
            id: v4(),
        };

        const newSubTree = {
            id: subTree.id,
            [relationOperator]: [oldTree, newCriteria],
        };

        const newTree = addNewObject(newSubTree, tree, selectedSubTree);

        const key = objectPropHandler(newTree);
        const repairedTree = repairTree(newTree[key], key, newTree.id);

        const aliasedTree = aliasGiver(
            newTree[objectPropHandler(newTree)],
            objectPropHandler(newTree),
            "C",
            newTree.id
        );
        const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

        setUpTree(repairedTree, subAliasedTree);
    }

    function callWithRelationContains() {
        //@ts-ignore
        const values = variable2.split(",");

        const newCriteria = {
            id: v4(),
            [operator]: [
                {
                    value: variable1,
                    isColumn: true,
                },
                values.map((item: any) => {
                    return { value: item, isColumn: false };
                }),
            ],
        };
        const oldCriteria = {
            ...subTree,
            id: v4(),
        };

        const newSubTree = {
            id: subTree.id,
            [objectPropHandler(subTree)]: [...oldCriteria[objectPropHandler(subTree)], newCriteria],
        };
        if (tree.id === selectedSubTree.id) {
            const aliasedTree = aliasGiver(
                newSubTree[objectPropHandler(newSubTree)],
                objectPropHandler(newSubTree),
                "C",
                newSubTree.id
            );
            const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

            const key = objectPropHandler(newSubTree);
            const repairedTree = repairTree(newSubTree[key], key, newSubTree.id);

            setUpTree(repairedTree, subAliasedTree);

            return;
        }

        const newTree = addNewObject(newSubTree, tree, selectedSubTree);

        const key = objectPropHandler(newTree);
        const repairedTree = repairTree(newTree[key], key, newTree.id);

        const aliasedTree = aliasGiver(
            newTree[objectPropHandler(newTree)],
            objectPropHandler(newTree),
            "C",
            newTree.id
        );
        const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

        setUpTree(repairedTree, subAliasedTree);
    }

    function callWithoutRelationContains() {
        //@ts-ignore
        const values = variable2.split(",");
        const newCriteria = {
            id: v4(),
            [operator]: [
                {
                    value: variable1,
                    isColumn: true,
                },
                values.map((item: any) => {
                    return { value: item, isColumn: false };
                }),
            ],
        };

        const oldTree = {
            ...subTree,
            id: v4(),
        };

        const newSubTree = {
            id: subTree.id,
            [relationOperator]: [oldTree, newCriteria],
        };
        const newTree = addNewObject(newSubTree, tree, selectedSubTree);

        const key = objectPropHandler(newTree);
        const repairedTree = repairTree(newTree[key], key, newTree.id);

        const aliasedTree = aliasGiver(
            newTree[objectPropHandler(newTree)],
            objectPropHandler(newTree),
            "C",
            newTree.id
        );
        const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

        setUpTree(repairedTree, subAliasedTree);
    }
    //176e20a4-5e66-444e-aab8-b3ddedb538c2
    return (
        <>
            {relation ? (
                <MDButton
                    data-testid={testIds.submitAddBtn}
                    sx={{
                        visibility: Boolean(variable1 && variable2?.toString().length > 0 && operator)
                            ? "visible"
                            : "hidden",
                        marginBottom: { xxl: "0", lg: "0", md: "0", xs: "2.5rem" },
                    }}
                    onClick={() => {
                        if (operator === "CONTAINS") {
                            callWithRelationContains();
                        } else {
                            callWithRelationRegular();
                        }
                    }}
                    type="submit"
                    variant="gradient"
                    color="info"
                    size="large"
                    fullWidth
                >
                    ADD
                </MDButton>
            ) : (
                <MDButton
                    data-testid={testIds.submitAddBtn}
                    sx={{
                        visibility: Boolean(
                            variable1 && variable2?.toString().length > 0 && operator && relationOperator
                        )
                            ? "visible"
                            : "hidden",
                        marginBottom: { xxl: "0", lg: "0", md: "0", xs: "2.5rem" },
                    }}
                    onClick={() => {
                        if (operator === "CONTAINS") {
                            callWithoutRelationContains();
                        } else {
                            callWithoutRelationRegular();
                        }
                    }}
                    type="submit"
                    variant="gradient"
                    color="info"
                    size="large"
                    fullWidth
                >
                    ADD
                </MDButton>
            )}
        </>
    );
}

export default SubmitAddButton;
