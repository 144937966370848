import colors from "assets/theme-dark/base/colors";

export const scrollbar = {
    scrollbarColor: `${colors.primary.main} transparent`,
    scrollbarWidth: "thin",
    borderRadius: "9px",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "transparent",
        borderRadius: "9px",
        height: "18px",
        cursor: "pointer",
        padding: 2,
        margin: 0,
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: colors.primary.main,
        minHeight: 24,
        border: "3px solid #2b2b2b",
        cursor: "pointer",
    },

    "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: colors.primary.focus,
    },
    "&::-webkit-scrollbar-thumb:active": {
        backgroundColor: "#817343",
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        backgroundColor: "#2b2b2b",
        cursor: "pointer",
    },
};
