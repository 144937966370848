import { Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import colors from "assets/theme-dark/base/colors";
import { CustomColumn } from "graphql/types/graphql";
import MDBox from "components/MDBox";
import { testIds } from "utils/testIds";
import { addOpacityToColor } from "utils/addOpacityToColor";

function Tabs(props: {
    selected: number;
    setSelected: (value: number) => void;
    tabs: string[];
    columns: CustomColumn[] | string[] | { original: string; formatted: string }[];
}) {
    const { selected, setSelected, columns, tabs } = props;

    return (
        <Stack flexDirection="row" sx={{ background: "transparent" }}>
            {tabs.map((tab: string, index: number) => {
                if (index === tabs.length - 1) {
                    return (
                        <MDBox
                            data-testid={testIds.tab}
                            key={index + tab}
                            sx={{
                                visibility: columns?.length === 0 ? "hidden" : "visible",
                                borderRight: `3px solid ${colors.background.default}`,
                                background: "rgba(255, 255, 255, 0.2)",
                                boxShadow: "0 4px 30px rgba(255, 255, 255, 0.2)",
                                backdropFilter: "blur(15.9px)",
                                WebkitBackdropFilter: "blur(15.9px)",
                                backgroundColor: selected === index ? colors.primary.main : "transparent",

                                color:
                                    selected === index ? colors.dark.main : addOpacityToColor(colors.primary.main, 0.8),

                                width: "10rem",
                                height: "3rem",
                                fontSize: "1rem",
                                fontWeight: 600,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                    cursor: "pointer",
                                    opacity: selected === index ? 1 : 0.7,
                                },
                            }}
                            onClick={() => setSelected(index)}
                        >
                            {tab}
                        </MDBox>
                    );
                } else {
                    return (
                        <MDBox
                            key={index + tab}
                            data-testid={testIds.tab}
                            sx={{
                                borderRight: `3px solid ${colors.background.default}`,
                                background: "rgba(255, 255, 255, 0.2)",
                                boxShadow: "0 4px 30px rgba(255, 255, 255, 0.2)",
                                backdropFilter: "blur(15.9px)",
                                WebkitBackdropFilter: "blur(15.9px)",
                                backgroundColor: selected === index ? colors.primary.main : "transparent",
                                color:
                                    selected === index ? colors.dark.main : addOpacityToColor(colors.primary.main, 0.8),
                                width: "10rem",
                                height: "3rem",
                                fontSize: "1rem",
                                fontWeight: 600,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                    cursor: "pointer",
                                    opacity: selected === index ? 1 : 0.7,
                                },
                            }}
                            onClick={() => setSelected(index)}
                        >
                            {tab}
                        </MDBox>
                    );
                }
            })}
        </Stack>
    );
}

export default Tabs;
const Tab = styled.div`
    width: 10rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;
