import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { FilterUpdateType, TableauEmbed } from "@stoddabr/react-tableau-embed-live";

import { useRef, useEffect, useContext, useState } from "react";
import { context } from "utils/Provider";
import { enqueueSnackbar } from "notistack";
import TableauView from "./components/TableauEmbed";
import { WHERE } from "common/constants";
import React from "react";
import { ClientsResponse } from "graphql/types/graphql";
import { linearGradientStyle } from "utils/linearGradientsStyle";

function Where(): JSX.Element {
    const { token, refreshAccessToken, setToken, selectedCompany } = useContext(context);
    const [selected, setSelected] = useState<number>(0);
    const [credentials, setCredentials] = useState<string>();

    useEffect(() => {
        async function refresh() {
            const data = await refreshAccessToken();
            if (!data) {
                enqueueSnackbar("Could not refresh credentials!", { variant: "error" });
            } else {
                //@ts-ignore
                setToken(data);
                //@ts-ignore
                setCredentials(data);
            }
        }
        refresh();
    }, []);

    return (
        <>
            <div style={{ height: "800px", ...linearGradientStyle }}>
                {credentials && (
                    <TableauEmbedMemo
                        key={selectedCompany.cruxyId}
                        link={WHERE}
                        selectedCompany={selectedCompany}
                        token={credentials}
                    />
                )}
            </div>
        </>
    );
}

const TableauEmbedMemo = React.memo(function ChartMemo({
    link,
    selectedCompany,
    token,
}: {
    token: string;
    link: string;
    selectedCompany: ClientsResponse;
}) {
    return (
        <>
            <TableauView link={link} key={selectedCompany.cruxyId} selectedCompany={selectedCompany} token={token} />
        </>
    );
});

export default Where;
