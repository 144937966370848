import colors from "assets/theme-dark/base/colors";
import backgroundImage from "assets/images/body-background.png";
import backgroundImage2 from "assets/images/backgroundImage.jpg";
import backgroundImage3 from "assets/images/backgroundImage2.png";
import backgroundImage4 from "assets/images/backgroundImage3.png";
import backgroundImage5 from "assets/images/backgroundImage4.png";
import backgroundImage6 from "assets/images/backgroundImage6.png";
import backgroundImage7 from "assets/images/kl_14.jpg";

const { info, dark } = colors;

const globals = {
    html: {
        scrollBehavior: "smooth",
        fontSize: "1rem",
    },
    // body: {
    //     //background: `url(${backgroundImage5})`,
    //     backgroundRepeat: "no-repeat",
    // },
    "*, *::before, *::after": {
        margin: 0,
        padding: 0,
    },
    "a, a:link, a:visited": {
        textDecoration: "none !important",
    },
    "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
        color: `${dark.main} !important`,
        transition: "color 150ms ease-in !important",
    },
    "a.link:hover, .link:hover, a.link:focus, .link:focus": {
        color: `${info.main} !important`,
    },
};

export default globals;
