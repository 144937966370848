import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import MDBox from "components/MDBox";
import colors from "assets/theme-dark/base/colors";
import cruxy from "assets/images/cruxy-icon.svg";
import Upload from "../../../../components/Upload/Upload";
import { scrollbar } from "utils/scrollBar";
import { context } from "utils/Provider";
import { BACKEND_URL } from "common/constants";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    minHeight: "500px",
    height: "80vh",
    minWidth: "25rem",
    //overflowY: "scroll",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    ...scrollbar,
};

export default function TransitionsModal(props: {
    open: boolean;
    handleClose: () => void;
    setIsLoading: (value: boolean) => void;
}) {
    const { companies, selectedCompany } = React.useContext(context);
    const { open, handleClose, setIsLoading } = props;
    const { background } = colors;
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <MDBox sx={style} bgColor={background.card} border={"none"}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <img style={{ width: "6rem", height: "6rem", marginBottom: "4rem" }} src={cruxy} alt="cruxy" />
                    </div>
                    <MDBox sx={{ width: "100%", height: "80%" }}>
                        <Upload endpoint={`/upload/invoice`} />
                    </MDBox>
                </MDBox>
            </Fade>
        </Modal>
    );
}
