import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { context } from "utils/Provider";
import { createCipheriv } from "crypto";
import confirmSnack from "utils/confirmSnack";
import colors from "assets/theme-dark/base/colors";
import { BACKEND_URL } from "common/constants";

function Upload({ endpoint, isFiltered }: { endpoint: string; isFiltered?: boolean }) {
    const { selectedCompany } = useContext(context);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [dropzoneActive, setDropzoneActive] = useState(false);
    const [files, setFiles] = useState();

    function handleDrop(e: any) {
        if (!endpoint) return;

        e.preventDefault();
        const question = (
            <p>
                Are you sure you want upload new data for{" "}
                <strong style={{ color: colors.primary.main, fontWeight: "600" }}>
                    {selectedCompany.name.toUpperCase()}?
                </strong>
            </p>
        );
        const file = e.dataTransfer.files[0];
        confirmSnack(() => {
            setFiles(file);
        }, question);
    }

    useEffect(() => {
        if (files) {
            uploadFileHandler();
        }
    }, [files]);

    async function uploadFileInvoice() {
        try {
            const data = new FormData();

            data.append("file", files);
            data.append("cruxyId", selectedCompany.cruxyId);

            const response = await axios.post(BACKEND_URL + endpoint, data, {
                withCredentials: true,
            });
            if (response) {
                enqueueSnackbar("File has been uploaded successfully!", { variant: "success" });
                setFiles(undefined);
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setFiles(undefined);
        }
    }

    async function uploadFileCRM() {
        try {
            const data = new FormData();

            data.append("file", files);
            data.append("cruxyId", selectedCompany.cruxyId);
            data.append("isFiltered", isFiltered.toString());

            const response = await axios.post(BACKEND_URL + endpoint, data, {
                withCredentials: true,
            });

            if (response) {
                enqueueSnackbar("File has been uploaded successfully!", { variant: "success" });
                setFiles(undefined);
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setFiles(undefined);
        }
    }

    async function uploadFileHandler() {
        if (endpoint === `/upload/invoice`) {
            uploadFileInvoice();
        } else {
            uploadFileCRM();
        }
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                    //border: "1px solid red",
                    //maxHeight: "700px",
                    //padding: "2rem",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "transparent",
                        color: "white",
                        border: "1px dashed white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onDragOver={(e) => {
                        setDropzoneActive(true);
                        e.preventDefault();
                    }}
                    onDragLeave={(e) => {
                        setDropzoneActive(false);
                        e.preventDefault();
                    }}
                    onDrop={(e) => handleDrop(e)}
                    className={"dropzone" + (dropzoneActive ? " active" : "")}
                >
                    {files ? (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                //border: "1px solid white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress size={"4rem"} color="primary" />
                        </div>
                    ) : endpoint ? (
                        "Drop your file here!"
                    ) : (
                        "Please select your entries destination!"
                    )}
                </div>
            </Box>
        </>
    );
}

export default Upload;
