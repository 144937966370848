import Drawer from "@mui/material/Drawer";
import { styled, Theme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { context } from "utils/Provider";
import { linearGradientStyle } from "utils/linearGradientsStyle";
import { scrollbar } from "utils/scrollBar";
import useMediaQuery from "@mui/material/useMediaQuery";

export default styled(Drawer)(({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
    const { palette, boxShadows, transitions, breakpoints, functions } = theme;
    const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;

    const sidebarWidth = "19.5rem";
    const { transparent, gradients, white, background } = palette;
    const { xxl } = boxShadows;
    const { pxToRem, linearGradient } = functions;

    const { showScroll } = useContext(context);

    const matches = useMediaQuery("(min-width:1200px)");

    let backgroundValue = darkMode ? background.sidenav : linearGradient(gradients.dark.main, gradients.dark.state);

    if (transparentSidenav) {
        backgroundValue = transparent.main;
    } else if (whiteSidenav) {
        backgroundValue = white.main;
    }

    // styles for the sidenav when miniSidenav={false}

    const drawerOpenStyles = () =>
        matches
            ? {
                  //border: "1px solid white",
                  ...linearGradientStyle,
                  width: sidebarWidth,
                  scrollbarGutter: "stable both-edges",
                  overflow: showScroll ? "auto" : "hidden",
                  transition: transitions.create("transform", {
                      easing: transitions.easing.sharp,
                      duration: transitions.duration.shorter,
                  }),
                  borderRadius: 0,
                  ...scrollbar,

                  [breakpoints.up("xl")]: {
                      boxShadow: transparentSidenav ? "none" : xxl,
                      marginBottom: transparentSidenav ? 0 : "inherit",
                      left: "0",
                      width: sidebarWidth,
                      transform: "translateX(0)",
                      transition: transitions.create(["width", "background-color"], {
                          easing: transitions.easing.sharp,
                          duration: transitions.duration.enteringScreen,
                      }),
                  },
              }
            : {
                  width: sidebarWidth,
                  scrollbarGutter: "stable both-edges",
                  overflow: showScroll ? "auto" : "hidden",
                  transition: transitions.create("transform", {
                      easing: transitions.easing.sharp,
                      duration: transitions.duration.shorter,
                  }),
                  borderRadius: 0,
                  ...scrollbar,

                  [breakpoints.up("xl")]: {
                      boxShadow: transparentSidenav ? "none" : xxl,
                      marginBottom: transparentSidenav ? 0 : "inherit",
                      left: "0",
                      width: sidebarWidth,
                      transform: "translateX(0)",
                      transition: transitions.create(["width", "background-color"], {
                          easing: transitions.easing.sharp,
                          duration: transitions.duration.enteringScreen,
                      }),
                  },
              };

    // styles for the sidenav when miniSidenav={true}
    const drawerCloseStyles = () => ({
        ...linearGradientStyle,
        borderRadius: 0,
        transform: `translateX(${pxToRem(-320)})`,
        transition: transitions.create("transform", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),
        //scrollbarGutter: "stable both-edges",
        overflow: "hidden",
        [breakpoints.up("xl")]: {
            boxShadow: transparentSidenav ? "none" : xxl,
            marginBottom: transparentSidenav ? 0 : "inherit",
            left: "0",
            width: pxToRem(96),
            overflowX: "hidden",
            transform: "translateX(0)",
            transition: transitions.create(["width", "background-color"], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.shorter,
            }),
        },
    });

    return {
        "& .MuiDrawer-paper": matches
            ? {
                  boxShadow: xxl,
                  //margin: 0,
                  // border: "1px solid white",
                  ...linearGradientStyle,
                  borderRadius: 0,
                  height: {
                      xxl: `calc(100vh - ${pxToRem(32)})`,
                      lg: `calc(100vh - ${pxToRem(32)})`,
                      md: `calc(100vh - ${pxToRem(32)})`,
                      xs: `calc(100vh - ${pxToRem(0)})`,
                  },
                  margin: { xxl: pxToRem(16), lg: pxToRem(16), md: pxToRem(16), xs: pxToRem(16) },

                  ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
              }
            : {
                  boxShadow: xxl,

                  borderRadius: 0,
                  height: {
                      xxl: `calc(100vh - ${pxToRem(32)})`,
                      lg: `calc(100vh - ${pxToRem(32)})`,
                      md: `calc(100vh - ${pxToRem(32)})`,
                      xs: `calc(100vh - ${pxToRem(0)})`,
                  },
                  margin: { xxl: pxToRem(16), lg: pxToRem(16), md: pxToRem(16), xs: pxToRem(16) },

                  ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
              },
    };
});
