import MDBox from "components/MDBox";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import ColumnDropdown from "./ColumnDropdown";
import { useRules } from "layouts/applications/CRM data/utils/useRules";
import { CrmTables, useFormulasLazyQuery } from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import { context } from "utils/Provider";
import TreeComponent from "../utils/treeComponentMemo";
import EditFrame from "./EditFrame";
import { testIds } from "utils/testIds";
import CustomButton from "components/CustomButton";
import colors from "assets/theme-dark/base/colors";

interface Props {
    columns: any;
    setIsLoading: (value: boolean) => void;
    tables: CrmTables[];
    currentTable: CrmTables;
    getDealsHandler: () => void;
    getCompaniesHandler: () => void;
}

function Rules(props: Props) {
    const { columns, setIsLoading, tables, currentTable, getDealsHandler, getCompaniesHandler } = props;
    const { user, selectedCompany } = useContext(context);

    const {
        setSelectedTree,
        setUpTree,
        setSelectedRule,
        selectedColumn,
        setSelectedColumn,
        newColName,
        setNewColName,
        newRuleName,
        setNewRuleName,
        rules,
        setRules,
        tree,
        setSelectedSubTree,
        selectedTree,
        selectedRule,
        saveChanges,
        undoChanges,
        selectedSubTree,
        deleteRuleHandler,
        setTree,
        getRules,
        crmColumns,
        variableOptions1,
        variableOptions2,
        tableOption1,
        tableOption2,
        setTableOption1,
        setTableOption2,
        applyRule,
        aliasTree,
        aliasGiver,
    } = useRules(
        columns,
        setIsLoading,
        currentTable,
        tables,
        user,
        selectedCompany,
        getDealsHandler,
        getCompaniesHandler
    );

    const [isEditing, setIsEditing] = useState(false);
    const [relation, setRelation] = useState<any>();

    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        if (relation === "AND" || relation === "OR") {
            setIsEditing(false);
        }
    }, [relation]);

    useEffect(() => {
        if (currentTable) {
            setTableOption1(currentTable);
            setTableOption2(currentTable);
        }
    }, [currentTable]);

    async function getRulesForColumn(id: string) {
        try {
            const response = await getRules({ variables: { columnId: id } });
            if (response) {
                if (response.data) {
                    setRules(response.data.formulas);
                } else {
                    setRules([]);
                    setUpTree(undefined);
                }
            }
        } catch (error) {
            enqueueSnackbar("Error:" + error, {
                variant: "error",
            });
        }
    }

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const TreeComponentMemo = React.useMemo(() => {
        return (
            <TreeComponent
                expanded={expanded}
                handleToggle={handleToggle}
                tree={aliasTree}
                setSelectedSubTree={setSelectedSubTree}
            />
        );
    }, [aliasTree, expanded]);

    const EditFrameMemo = React.useMemo(() => {
        return (
            <EditFrame
                aliasGiver={aliasGiver}
                selectedSubTree={selectedSubTree}
                tree={tree}
                deleteRuleHandler={deleteRuleHandler}
                setTree={setTree}
                setSelectedSubTree={setSelectedSubTree}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                selectedRule={selectedRule}
                relation={relation}
                currentTable={currentTable}
                setUpTree={setUpTree}
                tables={tables}
                crmColumns={crmColumns}
                variableOptions1={variableOptions1}
                variableOptions2={variableOptions2}
                tableOption1={tableOption1}
                tableOption2={tableOption2}
                setTableOption1={setTableOption1}
                setTableOption2={setTableOption2}
                setRelation={setRelation}
            />
        );
    }, [
        tree,
        selectedSubTree,
        isEditing,
        selectedRule,
        relation,
        currentTable,
        tables,
        crmColumns,
        variableOptions1,
        variableOptions2,
        tableOption1,
        tableOption2,
        aliasTree,
    ]);

    return (
        <MDBox
            sx={{
                overflowY: "auto",
                minHeight: "600px",
                padding: "0.5rem",
                paddingTop: "2rem",
                display: "grid",
                gap: 1,
                gridTemplateColumns: {
                    xxl: "repeat(auto-fit, minmax(37.5rem, 1fr))",
                    lg: "repeat(auto-fit, minmax(37.5rem, 1fr))",
                    md: "repeat(auto-fit, minmax(28rem, 1fr))",
                    xs: "repeat(auto-fit, minmax(22rem, 1fr))",
                },
                height: "100%",
                width: "100%",
            }}
        >
            <MDBox
                sx={{
                    padding: "0rem",
                    paddingRight: { xxl: "1rem", lg: "1rem", md: "1rem", xs: "0" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <ColumnDropdown
                    aliasGiver={aliasGiver}
                    getRulesForColumn={getRulesForColumn}
                    setSelectedTree={setSelectedTree}
                    columns={columns}
                    setIsLoading={setIsLoading}
                    setUpTree={setUpTree}
                    setSelectedRule={setSelectedRule}
                    selectedColumn={selectedColumn}
                    setSelectedColumn={setSelectedColumn}
                    newColName={newColName}
                    setNewColName={setNewColName}
                    newRuleName={newRuleName}
                    setNewRuleName={setNewRuleName}
                    rules={rules}
                    setRules={setRules}
                    tree={tree}
                />

                {aliasTree && tree && (
                    <>
                        <CustomButton
                            testId={testIds.rootBtn}
                            title="Root"
                            background={colors.white.main}
                            job={() => {
                                setSelectedSubTree(aliasTree);
                            }}
                            props={{
                                width: "6rem",
                                height: "2rem",
                                borderRadius: "5px",
                                marginBottom: "2rem",
                            }}
                        />

                        {TreeComponentMemo}
                        <MDBox
                            sx={{
                                display: "flex",
                                width: "50%",
                                marginTop: "3rem",
                                justifyContent: "space-between",
                                gap: 2,
                            }}
                        >
                            <CustomButton
                                testId={testIds.ruleApplyBtn}
                                title="Apply"
                                background={colors.primary.main}
                                job={() => {
                                    applyRule();
                                }}
                                props={{
                                    width: "8rem",
                                    minWidth: "6rem",
                                    zIndex: 0,
                                    display:
                                        selectedRule?.id === "newRule" ||
                                        JSON.stringify(selectedTree) !== JSON.stringify(tree) ||
                                        newRuleName !== selectedRule.valueFormula ||
                                        newColName !== selectedColumn.columnName
                                            ? "none"
                                            : "block",
                                }}
                            />

                            <CustomButton
                                testId={testIds.ruleSaveBtn}
                                title="Save"
                                background={colors.success.main}
                                job={() => {
                                    saveChanges();
                                }}
                                props={{
                                    width: "8rem",
                                    zIndex: 0,
                                    minWidth: "6rem",
                                    display:
                                        (JSON.stringify(selectedTree) !== JSON.stringify(tree) ||
                                            newRuleName !== selectedRule.valueFormula ||
                                            newColName !== selectedColumn.columnName) &&
                                        JSON.stringify(tree).toLowerCase().indexOf("new rule") === -1
                                            ? "block"
                                            : "none",
                                }}
                            />

                            <CustomButton
                                testId={testIds.ruleUndoBtn}
                                title="Undo"
                                background={colors.warning.main}
                                job={() => {
                                    undoChanges();
                                }}
                                props={{
                                    width: "8rem",
                                    zIndex: 0,
                                    minWidth: "6rem",
                                    display:
                                        JSON.stringify(selectedTree) !== JSON.stringify(tree) ||
                                        newRuleName !== selectedRule.valueFormula ||
                                        newColName !== selectedColumn.columnName
                                            ? "block"
                                            : "none",
                                }}
                            />
                        </MDBox>
                    </>
                )}
            </MDBox>
            {aliasTree && tree && variableOptions1 && variableOptions2 && <>{EditFrameMemo}</>}
        </MDBox>
    );
}

export default Rules;
