import { Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import ChangeCard from "./ChangeCard";
import MDButton from "components/MDButton";
import CustomButton from "components/CustomButton";

const titleStyle = {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: colors.white.main,
    marginBottom: "0.5rem",
    padding: "0.75rem",
};

const columnStyle = {
    width: "33.333%",
    //border: "1px solid white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

function ChangesColumn({
    changes,
    buttonVisible,
    confirmApplyHandler,
}: {
    changes: any;
    buttonVisible: boolean;
    confirmApplyHandler?: () => void;
}) {
    useEffect(() => {
        getFields();
        getFroms();
        getTos();
    }, [changes]);

    function getFields() {
        return Object.keys(changes).filter((field: string) => {
            return field !== "sql" && field.toLowerCase() !== "__typename";
        });
    }

    function getFroms() {
        const froms: string[] = [];
        const keys = Object.keys(changes).filter((field: string) => {
            return field !== "sql" && field.toLowerCase() !== "__typename";
        });
        keys.map((key: string) => {
            froms.push(changes[key].from);
        });

        return froms;
    }

    function getTos() {
        const tos: string[] = [];
        const keys = Object.keys(changes).filter((field: string) => {
            return field !== "sql" && field.toLowerCase() !== "__typename";
        });
        keys.map((key: string) => {
            tos.push(changes[key].to);
        });

        return tos;
    }

    return (
        <MDBox
            sx={{
                width: "100%",
                //height: "100%",
                display: "flex",
                //border: "1px solid red",
            }}
        >
            <MDBox sx={columnStyle}>
                <Typography sx={{ ...titleStyle, visibility: "hidden" }}>FIELDS</Typography>
                {getFields().map((field: string, index: number) => {
                    return <ChangeCard key={field + index} name={field} field={true} />;
                })}

                <CustomButton
                    title="Apply"
                    background={colors.primary.main}
                    job={confirmApplyHandler}
                    props={{ width: "8rem", marginTop: "3rem", display: buttonVisible ? "block" : "none" }}
                />
            </MDBox>

            <MDBox sx={columnStyle}>
                <Typography sx={titleStyle}>FROM</Typography>
                {getFroms().map((from: string, index: number) => {
                    return <ChangeCard key={from + index} name={from} field={false} />;
                })}
            </MDBox>
            <MDBox sx={columnStyle}>
                <Typography sx={titleStyle}>TO</Typography>
                {getTos().map((to: string, index: number) => {
                    return <ChangeCard key={index + to} name={to} field={false} />;
                })}
            </MDBox>
        </MDBox>
    );
}

export default ChangesColumn;
