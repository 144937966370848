import { useEffect, ReactNode } from "react";
// react-router-dom components
import { useLocation } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";

function DashboardLayout({ children }: { children: ReactNode }): JSX.Element {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav } = controller;
    const { pathname } = useLocation();

    useEffect(() => {
        setLayout(dispatch, "dashboard");
    }, [pathname]);

    return (
        <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                paddingTop: { xxl: 3, lg: 3, md: 3, xs: 0 },
                paddingLeft: { xxl: 3, lg: 3, md: 3, xs: 0 },
                paddingRight: { xxl: 3, lg: 3, md: 3, xs: 0 },
                paddingBottom: "1rem",
                position: "relative",
                [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? "8rem" : "20rem",
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
                display: "flex",
                flexDirection: "column",
                //border: "1px solid pink",
                //height: "100vh",
                minHeight: "850px",
                //alignItems: "stretch",
            })}
        >
            {children}
        </MDBox>
    );
}

export default DashboardLayout;
