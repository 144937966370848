import { Duration } from "graphql/types/graphql";
import { Pipeline } from "./types";

export default function newDurationHandler(newPipeline: Pipeline): {
    conversionRate: number;
    duration: number;
    stageOrderNumber: number;
    pipelineInstanceOrderNumber: number;
    numberOfDeals: number;
}[] {
    return newPipeline.input.pipeline.durations.map((duration: Duration) => {
        return {
            conversionRate: duration.conversionRate,
            duration: duration.duration,
            stageOrderNumber: newPipeline.input.pipeline.schema.stages.find((stage: any) => {
                return stage.id === duration.stageId;
            }).orderNumber,
            pipelineInstanceOrderNumber: newPipeline.input.pipeline.schema.pipelineInstances.find((instance: any) => {
                return instance.id === duration.pipelineInstanceId;
            }).orderNumber,
            numberOfDeals: duration.numberOfDeals,
        };
    });
}
