import Column from "../Column";
//@ts-ignore
import Board from "components/Board/src/components/Board";
import { moveColumn, removeCard } from "components/Board/src/services/helpers";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import Card from "../Card";
import { CustomColumn, Duration, Stage } from "graphql/types/graphql";
import { PageData } from "../../utils/types";

interface Props {
    pageData: PageData;
    updatePageData: (object: Partial<PageData>) => void;
    handleDuration: (value: Duration) => void;
    handleConversionRate: (value: { stageId: string; conversion: number }) => void;
    handleStageName: (value: { stageId: string; name: string }) => void;
    handleInstanceName: (id: string, name: string) => void;
    removeStage: (stageId: string) => void;
    changeOrder: (stageId1: number, stageId2: number, newBoard: any) => void;
}

function MyBoard(props: Props) {
    const {
        pageData,
        updatePageData,
        handleDuration,
        handleConversionRate,
        handleStageName,
        handleInstanceName,
        removeStage,
        changeOrder,
    } = props;

    const { currentlyEditing, isConversionVisible, board } = pageData;

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [newCardForm, setNewCardForm] = useState<string | number | boolean>(false);

    const openNewCardForm = (event: HTMLButtonElement | any, id: string | number) => setNewCardForm(id);

    function handleMoveColumn(column: any, source: any, destination: any) {
        const stages = pageData.newPipeline.input.pipeline.schema.stages;

        if (
            (source.fromPosition === stages.length || destination.toPosition === stages.length) &&
            (isConversionVisible === 1 || isConversionVisible === 2)
        ) {
            return;
        }
        const newBoard = moveColumn(board, source, destination);
        changeOrder(source.fromPosition, destination.toPosition, newBoard);
    }

    const getColumn = (card: any) => {
        const column = board.columns.filter((column: any) => column.cards.includes(card));
        return column[0];
    };

    function handleCurrentlyEditing(id: string) {
        if (id === currentlyEditing && isConversionVisible === 2) {
            updatePageData({
                currentlyEditing: "",
            });
            return;
        }
        updatePageData({
            currentlyEditing: id,
        });
    }

    function handleCardDragEnd(card: any, source: any, destination: any) {}

    return (
        <>
            <Board
                isConversionVisible={isConversionVisible}
                allowAddColumn
                allowRemoveColumn
                allowRemoveCard
                allowAddCard
                disableCardDrag
                onCardDragEnd={handleCardDragEnd}
                onColumnDragEnd={handleMoveColumn}
                renderColumnHeader={(props: any) => (
                    <>
                        <Column
                            board={board}
                            props={props}
                            isEditingColumn={currentlyEditing === props.id}
                            openNewCardForm={openNewCardForm}
                            removeColumn={removeStage}
                            handleCurrentlyEditing={handleCurrentlyEditing}
                            handleStageName={handleStageName}
                        />
                    </>
                )}
                renderCard={(props: any) => (
                    <Card
                        props={props}
                        darkMode={darkMode}
                        currentlyEditing={currentlyEditing === props.column}
                        updatePageData={updatePageData}
                        board={board}
                        removeCard={removeCard}
                        getColumn={getColumn}
                        handleDuration={handleDuration}
                        handleConversionRate={handleConversionRate}
                        handleInstanceName={handleInstanceName}
                        isConversionVisible={isConversionVisible}
                    />
                )}
                onCardNew={(): any => null}
            >
                {board}
            </Board>
        </>
    );
}

export default MyBoard;
