import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { MaterialUIControllerProvider } from "context";

import { context, ContextProvider } from "utils/Provider";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ApolloClient, HttpLink, InMemoryCache, createHttpLink, ApolloLink, from, Observable } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { setContext as mySetContext } from "@apollo/client/link/context";
import { useContext, useEffect, useRef, useState } from "react";
import colors from "assets/theme-dark/base/colors";
import { onError } from "@apollo/client/link/error";
//import { Observable } from 'apollo-link';

const root = createRoot(document.getElementById("root"));

import { MaterialDesignContent } from "notistack";
import { BACKEND_URL } from "common/constants";

import axios from "axios";

// Sentry.init({
//     dsn: "https://9d717f8d6a50809ecd9754eee631ef61@o4505646860140544.ingest.sentry.io/4505646863286272",
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", BACKEND_URL],
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-default": {
        backgroundColor: colors.background.card,
    },
}));

function RootComponent() {
    let myToken: string;

    const link = new HttpLink({ uri: BACKEND_URL, credentials: "include" });

    const authLink = mySetContext((_, { headers }) => {
        return myToken ? { headers: { ...headers, Authorization: myToken } } : { headers: { ...headers } };
    });

    const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
        if (graphQLErrors[0].extensions.code === "NOT_ALLOWED" || graphQLErrors[0].extensions.code === "UNAUTHORIZED") {
            return new Observable((observer) => {
                (async () => {
                    try {
                        for (let err of graphQLErrors) {
                            switch (err.extensions.code) {
                                default:
                                    return;
                                case "NOT_ALLOWED":
                                    checkUserAgain(operation, forward, observer);
                                    break;
                                case "UNAUTHORIZED":
                                    checkUserAgain(operation, forward, observer);
                                    break;
                            }
                        }
                    } catch (error) {
                        observer.error(error);
                    }
                })();
            });
        }
    });

    const client = new ApolloClient({
        link: from([errorLink, authLink.concat(link)]),
        cache: new InMemoryCache({}),
    });

    async function checkUserAgain(operation: any, forward: any, observer: any) {
        const response = await axios.post(
            BACKEND_URL,
            {
                operationName: "RefreshToken",
                query: "mutation RefreshToken {\n refreshToken\n}",
                variables: {},
            },
            {
                withCredentials: true,
            }
        );
        if (response) {
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
                headers: {
                    ...oldHeaders,
                    Authorization: response.data.data.refreshToken,
                },
            });
            const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
            };
            myToken = response.data.data.refreshToken;
            forward(operation).subscribe(subscriber);
        }
    }

    function undefineToken() {
        myToken = undefined;
    }

    return (
        <BrowserRouter>
            <ApolloProvider client={client}>
                <ContextProvider>
                    <MaterialUIControllerProvider>
                        <SnackbarProvider
                            action={(snackbarId) => (
                                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                                    <CloseIcon sx={{ color: "white" }} />
                                </IconButton>
                            )}
                            autoHideDuration={3000}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            Components={{
                                default: StyledMaterialDesignContent,
                            }}
                            //style={{ background: colors.background.card }}
                        >
                            <App undefineToken={undefineToken} />
                        </SnackbarProvider>
                    </MaterialUIControllerProvider>
                </ContextProvider>
            </ApolloProvider>
        </BrowserRouter>
    );
}

root.render(<RootComponent />);
