import colors from "assets/theme-dark/base/colors";

const { transparent } = colors;

// types
type Types = any;

const textField: Types = {
    styleOverrides: {
        root: {
            backgroundColor: transparent.main,
        },
        "& .MuiSelect-icon": {
            color: "white !important",
        },
        "& .MuiFormHelperText-root": {
            color: "white !important",
        },
    },
};

export default textField;
