import { PipelinesResponse } from "graphql/types/graphql";

export default function convertToInput(pipeline: PipelinesResponse[]) {
    return pipeline.map((pipeline: PipelinesResponse) => {
        return {
            pipelineId: pipeline.id,
            name: pipeline?.name,
            input: {
                pipeline: {
                    durations: pipeline?.data?.durations,
                    schema: pipeline?.data?.schema,
                },
            },
        };
    });
}
