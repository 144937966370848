import { Tooltip, Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";

function ChangeCard({ name, field }: { name: string; field: boolean }) {
    return (
        <Tooltip title={field ? name.toUpperCase() : name}>
            <Typography
                sx={{
                    color: colors.white.main,
                    fontSize: "1rem",
                    marginBottom: "1.5rem",
                    whiteSpace: "nowrap",
                    fontWeight: field ? 600 : 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "150px",
                    textAlign: "center",
                }}
            >
                {field ? name.toUpperCase() : name}
            </Typography>
        </Tooltip>
    );
}

export default ChangeCard;
