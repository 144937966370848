export function valueFormatter(item: any) {
    if (typeof item.value === "string") {
        return item.value;
    }
    if (typeof item.value === "number") {
        if (item.value < 2050 && item.value >= 2000) return item.value;
        const valueByChars = item.value.toString().split("");
        const reversedChars = valueByChars.slice().reverse();
        const newValue = reversedChars.map((char: string, index: number) => {
            if (index % 3 === 0 && index !== 0) {
                return char + ",";
            } else {
                return char;
            }
        });

        return newValue.reverse().join("");
    }
    return item.value;
}
