import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type ApplyInvoicesResponse = {
  __typename?: 'ApplyInvoicesResponse';
  companies?: Maybe<Array<Maybe<InvoiceLog>>>;
  cruxyId?: Maybe<Scalars['String']['output']>;
};

export type BatchApplyFormulasResult = {
  __typename?: 'BatchApplyFormulasResult';
  formulaId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BestMatches = {
  __typename?: 'BestMatches';
  externalId: Scalars['String']['output'];
  externalName: Scalars['String']['output'];
  internalId: Scalars['String']['output'];
  internalName: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type CrmPipelinesResponse = {
  __typename?: 'CRMPipelinesResponse';
  displayOrder: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CrmTables = {
  __typename?: 'CRMTables';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type Change = {
  __typename?: 'Change';
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type Changes = {
  __typename?: 'Changes';
  country?: Maybe<Change>;
  industry?: Maybe<Change>;
  name?: Maybe<Change>;
  sql?: Maybe<Scalars['String']['output']>;
};

export type ClientsFilter = {
  cruxyId?: InputMaybe<Scalars['String']['input']>;
};

export type ClientsResponse = {
  __typename?: 'ClientsResponse';
  cruxyId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompaniesFilter = {
  country?: InputMaybe<Scalars['String']['input']>;
  cruxyId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompaniesResponse = {
  __typename?: 'CompaniesResponse';
  edges: Array<Company>;
  totalCount: Scalars['String']['output'];
};

export type Company = {
  __typename?: 'Company';
  annualRevenue?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createDate?: Maybe<Scalars['String']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  cruxyId: Scalars['String']['output'];
  customProperties?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  mainIndustry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentCompanyId?: Maybe<Scalars['String']['output']>;
  territory?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type ConversionRatesResponse = {
  __typename?: 'ConversionRatesResponse';
  conversionRate: Scalars['Float']['output'];
  instanceId: Scalars['String']['output'];
  numberOfDeals: Scalars['Int']['output'];
  stageId: Scalars['String']['output'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  cruxyId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  exp?: Maybe<Scalars['Int']['output']>;
  iat?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  role?: Maybe<UserRole>;
};

export type CustomColumn = {
  __typename?: 'CustomColumn';
  columnId?: Maybe<Scalars['ID']['output']>;
  columnName?: Maybe<Scalars['String']['output']>;
  cruxyId?: Maybe<Scalars['String']['output']>;
  tableId?: Maybe<Scalars['String']['output']>;
};

export type CustomColumnFilters = {
  cruxyId: Scalars['String']['input'];
  tableId?: InputMaybe<Scalars['String']['input']>;
};

export type Deal = {
  __typename?: 'Deal';
  acv?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  amountInHomeCurrency?: Maybe<Scalars['String']['output']>;
  analyticsLatestSource?: Maybe<Scalars['String']['output']>;
  arr?: Maybe<Scalars['String']['output']>;
  arrWeighted?: Maybe<Scalars['String']['output']>;
  associatedCompany: Array<Maybe<Company>>;
  associatedContact: Array<Maybe<Contact>>;
  closeDate?: Maybe<Scalars['String']['output']>;
  createDate?: Maybe<Scalars['String']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  cruxyId: Scalars['String']['output'];
  customProperties?: Maybe<Scalars['JSONObject']['output']>;
  dealCurrencyCode?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealOwnerId?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  fiscalYear?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pipeline?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DealsFilter = {
  cruxyId: Scalars['String']['input'];
  dealName?: InputMaybe<Scalars['String']['input']>;
  fiscalYear?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type DealsResponse = {
  __typename?: 'DealsResponse';
  edges: Array<Deal>;
  totalCount: Scalars['String']['output'];
};

export type DeleteInvoicesFilter = {
  cruxyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  processed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Duration = {
  __typename?: 'Duration';
  conversionRate?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  numberOfDeals?: Maybe<Scalars['Int']['output']>;
  pipelineInstanceId?: Maybe<Scalars['ID']['output']>;
  stageId?: Maybe<Scalars['ID']['output']>;
};

export type DurationsInput = {
  conversionRate: Scalars['Float']['input'];
  duration: Scalars['Int']['input'];
  numberOfDeals: Scalars['Int']['input'];
  pipelineInstanceOrderNumber: Scalars['Int']['input'];
  stageOrderNumber: Scalars['Int']['input'];
};

export type EditCompanyFields = {
  annualRevenue?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createDate?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EditDealFields = {
  acv?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  amountInHomeCurrency?: InputMaybe<Scalars['String']['input']>;
  analyticsLatestSource?: InputMaybe<Scalars['String']['input']>;
  arr?: InputMaybe<Scalars['String']['input']>;
  arrWeighted?: InputMaybe<Scalars['String']['input']>;
  closeDate?: InputMaybe<Scalars['String']['input']>;
  createDate?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  dealCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  dealName?: InputMaybe<Scalars['String']['input']>;
  dealOwnerId?: InputMaybe<Scalars['String']['input']>;
  dealStage?: InputMaybe<Scalars['String']['input']>;
  fiscalYear?: InputMaybe<Scalars['String']['input']>;
  pipeline?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Formula = {
  __typename?: 'Formula';
  columnId: Scalars['String']['output'];
  conditionFormula: Scalars['JSONObject']['output'];
  cruxyId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  valueFormula: Scalars['String']['output'];
};

export type GetApplyInvoicesPlanResponse = {
  __typename?: 'GetApplyInvoicesPlanResponse';
  companies?: Maybe<Array<Maybe<InvoiceLog>>>;
  cruxyId?: Maybe<Scalars['String']['output']>;
};

export type InstancesResponse = {
  __typename?: 'InstancesResponse';
  averageAmount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  accountId?: Maybe<Scalars['String']['output']>;
  accountLegalName?: Maybe<Scalars['String']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  accountStatus?: Maybe<Scalars['String']['output']>;
  annualisedConverted?: Maybe<Scalars['String']['output']>;
  annualisedValue?: Maybe<Scalars['String']['output']>;
  check?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creditGbp?: Maybe<Scalars['String']['output']>;
  cruxyId: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  currentMonth?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  debitGbp?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  industryPrimarySegment?: Maybe<Scalars['String']['output']>;
  industrySubsegment?: Maybe<Scalars['String']['output']>;
  invoiceLengthMonths?: Maybe<Scalars['String']['output']>;
  invoiceNumber: Scalars['String']['output'];
  keyStakeholderTitleOrPosition?: Maybe<Scalars['String']['output']>;
  mrr?: Maybe<Scalars['String']['output']>;
  productSold?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  salesLastYear?: Maybe<Scalars['String']['output']>;
  sourceOfInvoiceData?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type InvoiceLog = {
  __typename?: 'InvoiceLog';
  changes?: Maybe<Changes>;
  idsOfDealsHidden?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  insertedNow?: Maybe<Scalars['Boolean']['output']>;
  invoiceName?: Maybe<Scalars['String']['output']>;
  matchId?: Maybe<Scalars['String']['output']>;
  matchName?: Maybe<Scalars['String']['output']>;
  newDealID?: Maybe<Scalars['String']['output']>;
  numberOfDealsHidden?: Maybe<Scalars['Int']['output']>;
  similiarity?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceLogsResponse = {
  __typename?: 'InvoiceLogsResponse';
  companies?: Maybe<Array<Maybe<InvoiceLog>>>;
  cruxyId?: Maybe<Scalars['String']['output']>;
};

export type InvoicesFilter = {
  cruxyId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  processed?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type InvoicesResponse = {
  __typename?: 'InvoicesResponse';
  edges: Array<Maybe<Invoice>>;
  totalCount: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  applyFormula?: Maybe<Scalars['String']['output']>;
  applyFormulasForColumn?: Maybe<Array<Maybe<BatchApplyFormulasResult>>>;
  applyInvoices?: Maybe<ApplyInvoicesResponse>;
  calculateWhereAmI?: Maybe<Scalars['Int']['output']>;
  deleteAllRecords: Scalars['Int']['output'];
  deleteClient: Scalars['Int']['output'];
  deleteColumn?: Maybe<Scalars['Boolean']['output']>;
  deleteFormula?: Maybe<Scalars['String']['output']>;
  deleteInvoices: Scalars['Int']['output'];
  deletePipelineTag?: Maybe<Scalars['Boolean']['output']>;
  editCompany?: Maybe<Company>;
  editDeal?: Maybe<Deal>;
  externalDataFuzzyMatching: Array<Maybe<BestMatches>>;
  garbageCollect: Scalars['Int']['output'];
  recalculateExpectations?: Maybe<Array<Maybe<Projection>>>;
  refreshReality?: Maybe<Array<Maybe<Projection>>>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  signIn?: Maybe<Scalars['String']['output']>;
  signOut?: Maybe<CurrentUser>;
  signUp?: Maybe<Scalars['String']['output']>;
  upsertColumn?: Maybe<Scalars['String']['output']>;
  upsertFormula?: Maybe<Formula>;
  upsertPipeline?: Maybe<Pipeline>;
  upsertPipelineTag?: Maybe<Scalars['String']['output']>;
};


export type MutationApplyFormulaArgs = {
  id: Scalars['String']['input'];
};


export type MutationApplyFormulasForColumnArgs = {
  columnId: Scalars['String']['input'];
};


export type MutationApplyInvoicesArgs = {
  cruxyId: Scalars['String']['input'];
};


export type MutationDeleteAllRecordsArgs = {
  cruxyId: Scalars['String']['input'];
};


export type MutationDeleteClientArgs = {
  cruxyId: Scalars['String']['input'];
};


export type MutationDeleteColumnArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFormulaArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteInvoicesArgs = {
  filter: DeleteInvoicesFilter;
};


export type MutationDeletePipelineTagArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditCompanyArgs = {
  fields: EditCompanyFields;
  id: Scalars['String']['input'];
};


export type MutationEditDealArgs = {
  fields: EditDealFields;
  id: Scalars['String']['input'];
};


export type MutationExternalDataFuzzyMatchingArgs = {
  cruxyId: Scalars['String']['input'];
};


export type MutationRecalculateExpectationsArgs = {
  cruxyId: Scalars['String']['input'];
  input: Scalars['JSONObject']['input'];
  pipelineId: Scalars['String']['input'];
};


export type MutationRefreshRealityArgs = {
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  untilDate: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSignUpArgs = {
  cruxyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: UserRole;
};


export type MutationUpsertColumnArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: UpsertColumnInput;
};


export type MutationUpsertFormulaArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  input: UpsertFormulaInput;
};


export type MutationUpsertPipelineArgs = {
  basePipelineId: Scalars['String']['input'];
  columnId: Scalars['String']['input'];
  input: UpsertPipelineInput;
  pipelineId: Scalars['String']['input'];
};


export type MutationUpsertPipelineTagArgs = {
  cruxyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Operators = {
  __typename?: 'Operators';
  symbol?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type Pipeline = {
  __typename?: 'Pipeline';
  basePipelineId: Scalars['String']['output'];
  columnId: Scalars['String']['output'];
  durations?: Maybe<Array<Maybe<Duration>>>;
  schema?: Maybe<Schema>;
};

export type PipelineInstance = {
  __typename?: 'PipelineInstance';
  averageAmount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfClosedWons?: Maybe<Scalars['Int']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
};

export type PipelineInstanceInput = {
  averageAmount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  orderNumber: Scalars['Int']['input'];
};

export type PipelinesResponse = {
  __typename?: 'PipelinesResponse';
  data?: Maybe<Pipeline>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Projection = {
  __typename?: 'Projection';
  amount: Scalars['Int']['output'];
  amountConverted: Scalars['Int']['output'];
  cruxyId: Scalars['String']['output'];
  dateOfCalculation: Scalars['String']['output'];
  dateOfProjection: Scalars['String']['output'];
  instanceName: Scalars['String']['output'];
  minimumStage: Scalars['Int']['output'];
  numberOfDeals: Scalars['Int']['output'];
  numberOfDealsConverted: Scalars['Int']['output'];
  pipelineId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  clients: Array<Maybe<ClientsResponse>>;
  companies: CompaniesResponse;
  conversionRates: Array<Maybe<ConversionRatesResponse>>;
  crmColumns: Array<Maybe<Scalars['String']['output']>>;
  crmPipelines: Array<Maybe<CrmPipelinesResponse>>;
  crmTables: Array<Maybe<CrmTables>>;
  customColumns: Array<Maybe<CustomColumn>>;
  deals: DealsResponse;
  expectations?: Maybe<Array<Maybe<Projection>>>;
  formulas?: Maybe<Array<Maybe<Formula>>>;
  getApplyInvoicesPlan?: Maybe<GetApplyInvoicesPlanResponse>;
  instances: Array<Maybe<InstancesResponse>>;
  invoiceLogDates: Array<Maybe<Scalars['String']['output']>>;
  invoiceLogs?: Maybe<InvoiceLogsResponse>;
  invoices: InvoicesResponse;
  me?: Maybe<CurrentUser>;
  operators: Array<Operators>;
  pipeline?: Maybe<Pipeline>;
  pipelines?: Maybe<Array<Maybe<PipelinesResponse>>>;
  reality?: Maybe<Array<Maybe<Projection>>>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  stages: Array<Maybe<StagesResponse>>;
  test: Scalars['Boolean']['output'];
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
};


export type QueryCompaniesArgs = {
  filter: CompaniesFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConversionRatesArgs = {
  pipelineId: Scalars['String']['input'];
};


export type QueryCrmColumnsArgs = {
  id: Scalars['String']['input'];
};


export type QueryCrmPipelinesArgs = {
  cruxyId: Scalars['String']['input'];
};


export type QueryCustomColumnsArgs = {
  filter: CustomColumnFilters;
};


export type QueryDealsArgs = {
  filter: DealsFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExpectationsArgs = {
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
};


export type QueryFormulasArgs = {
  columnId: Scalars['String']['input'];
};


export type QueryGetApplyInvoicesPlanArgs = {
  cruxyId: Scalars['String']['input'];
};


export type QueryInstancesArgs = {
  columnId: Scalars['String']['input'];
};


export type QueryInvoiceLogDatesArgs = {
  cruxyId: Scalars['String']['input'];
};


export type QueryInvoiceLogsArgs = {
  cruxyId: Scalars['String']['input'];
  date: Scalars['String']['input'];
};


export type QueryInvoicesArgs = {
  filter: InvoicesFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPipelineArgs = {
  id: Scalars['String']['input'];
};


export type QueryPipelinesArgs = {
  cruxyId: Scalars['String']['input'];
};


export type QueryRealityArgs = {
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
};


export type QueryStagesArgs = {
  pipelineId: Scalars['String']['input'];
};


export type QueryTestArgs = {
  bool: Scalars['Boolean']['input'];
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
};

export type Schema = {
  __typename?: 'Schema';
  pipelineInstances?: Maybe<Array<Maybe<PipelineInstance>>>;
  stages?: Maybe<Array<Maybe<Stage>>>;
};

export type SchemaInput = {
  pipelineInstances: Array<PipelineInstanceInput>;
  stages: Array<StageInput>;
};

export type Stage = {
  __typename?: 'Stage';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
};

export type StageInput = {
  name: Scalars['String']['input'];
  orderNumber: Scalars['Int']['input'];
};

export type StagesResponse = {
  __typename?: 'StagesResponse';
  displayOrder: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type UpsertColumnInput = {
  columnName?: InputMaybe<Scalars['String']['input']>;
  cruxyId?: InputMaybe<Scalars['String']['input']>;
  tableId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertFormulaInput = {
  columnId?: InputMaybe<Scalars['String']['input']>;
  conditionFormula?: InputMaybe<Scalars['JSONObject']['input']>;
  cruxyId?: InputMaybe<Scalars['String']['input']>;
  valueFormula?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertPipelineInput = {
  durations: Array<DurationsInput>;
  schema: SchemaInput;
};

export type User = {
  __typename?: 'User';
  cruxyId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  role: UserRole;
};

export enum UserRole {
  ClientAdmin = 'ClientAdmin',
  ClientUser = 'ClientUser',
  CruxyAdmin = 'CruxyAdmin',
  CruxyUser = 'CruxyUser'
}

export type UsersFilter = {
  cruxyId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
};

export type ApplyFormulaMutationVariables = Exact<{
  applyFormulaId: Scalars['String']['input'];
}>;


export type ApplyFormulaMutation = { __typename?: 'Mutation', applyFormula?: string | null };

export type ApplyFormulasForColumnMutationVariables = Exact<{
  columnId: Scalars['String']['input'];
}>;


export type ApplyFormulasForColumnMutation = { __typename?: 'Mutation', applyFormulasForColumn?: Array<{ __typename?: 'BatchApplyFormulasResult', formulaId?: string | null, message?: string | null } | null> | null };

export type ApplyInvoicesMutationVariables = Exact<{
  cruxyId: Scalars['String']['input'];
}>;


export type ApplyInvoicesMutation = { __typename?: 'Mutation', applyInvoices?: { __typename?: 'ApplyInvoicesResponse', cruxyId?: string | null, companies?: Array<{ __typename?: 'InvoiceLog', invoiceName?: string | null, matchName?: string | null, matchId?: string | null, numberOfDealsHidden?: number | null, idsOfDealsHidden?: Array<string | null> | null, newDealID?: string | null, insertedNow?: boolean | null, changes?: { __typename?: 'Changes', name?: { __typename?: 'Change', from?: string | null, to?: string | null } | null, country?: { __typename?: 'Change', from?: string | null, to?: string | null } | null, industry?: { __typename?: 'Change', from?: string | null, to?: string | null } | null } | null } | null> | null } | null };

export type DeleteColumnMutationVariables = Exact<{
  deleteColumnId: Scalars['String']['input'];
}>;


export type DeleteColumnMutation = { __typename?: 'Mutation', deleteColumn?: boolean | null };

export type DeleteFormulaMutationVariables = Exact<{
  deleteFormulaId: Scalars['String']['input'];
}>;


export type DeleteFormulaMutation = { __typename?: 'Mutation', deleteFormula?: string | null };

export type DeletePipelineTagMutationVariables = Exact<{
  deletePipelineTagId: Scalars['String']['input'];
}>;


export type DeletePipelineTagMutation = { __typename?: 'Mutation', deletePipelineTag?: boolean | null };

export type EditCompanyMutationVariables = Exact<{
  fields: EditCompanyFields;
  editCompanyId: Scalars['String']['input'];
}>;


export type EditCompanyMutation = { __typename?: 'Mutation', editCompany?: { __typename?: 'Company', id: string, name: string, parentCompanyId?: string | null, type?: string | null, cruxyId: string, createdById?: string | null, createDate?: string | null, country?: string | null, annualRevenue?: string | null, industry?: string | null, customProperties?: any | null } | null };

export type EditDealMutationVariables = Exact<{
  fields: EditDealFields;
  editDealId: Scalars['String']['input'];
}>;


export type EditDealMutation = { __typename?: 'Mutation', editDeal?: { __typename?: 'Deal', id: string, dealName?: string | null, dealStage?: string | null, createDate?: string | null, closeDate?: string | null, dealOwnerId?: string | null, amount?: string | null, analyticsLatestSource?: string | null, pipeline?: string | null, fiscalYear?: string | null, type?: string | null, dealCurrencyCode?: string | null, createdById?: string | null, arr?: string | null, amountInHomeCurrency?: string | null, acv?: string | null, customProperties?: any | null, associatedCompany: Array<{ __typename?: 'Company', id: string, name: string, createDate?: string | null, createdById?: string | null, country?: string | null } | null>, associatedContact: Array<{ __typename?: 'Contact', id: string, firstName: string, lastName: string } | null> } | null };

export type RecalculateExpectationsMutationVariables = Exact<{
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  input: Scalars['JSONObject']['input'];
}>;


export type RecalculateExpectationsMutation = { __typename?: 'Mutation', recalculateExpectations?: Array<{ __typename?: 'Projection', cruxyId: string, pipelineId: string, instanceName: string, minimumStage: number, numberOfDeals: number, numberOfDealsConverted: number, amount: number, amountConverted: number, dateOfCalculation: string, dateOfProjection: string } | null> | null };

export type RefreshRealityMutationVariables = Exact<{
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  untilDate: Scalars['String']['input'];
}>;


export type RefreshRealityMutation = { __typename?: 'Mutation', refreshReality?: Array<{ __typename?: 'Projection', amount: number, amountConverted: number, cruxyId: string, dateOfCalculation: string, dateOfProjection: string, instanceName: string, minimumStage: number, numberOfDeals: number, numberOfDealsConverted: number, pipelineId: string } | null> | null };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: string | null };

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn?: string | null };

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = { __typename?: 'Mutation', signOut?: { __typename?: 'CurrentUser', id: string, cruxyId: string, email: string, role?: UserRole | null, iat?: number | null, exp?: number | null } | null };

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: UserRole;
  cruxyId: Scalars['String']['input'];
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: string | null };

export type UpsertPipelineTagMutationVariables = Exact<{
  upsertPipelineTagId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  cruxyId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpsertPipelineTagMutation = { __typename?: 'Mutation', upsertPipelineTag?: string | null };

export type UpsertColumnMutationVariables = Exact<{
  input: UpsertColumnInput;
  upsertColumnId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type UpsertColumnMutation = { __typename?: 'Mutation', upsertColumn?: string | null };

export type UpsertFormulaMutationVariables = Exact<{
  input: UpsertFormulaInput;
  upsertFormulaId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpsertFormulaMutation = { __typename?: 'Mutation', upsertFormula?: { __typename?: 'Formula', columnId: string, conditionFormula: any, id: string, valueFormula: string, cruxyId: string } | null };

export type UpsertPipelineMutationVariables = Exact<{
  input: UpsertPipelineInput;
  pipelineId: Scalars['String']['input'];
  columnId: Scalars['String']['input'];
  basePipelineId: Scalars['String']['input'];
}>;


export type UpsertPipelineMutation = { __typename?: 'Mutation', upsertPipeline?: { __typename?: 'Pipeline', basePipelineId: string, columnId: string, schema?: { __typename?: 'Schema', stages?: Array<{ __typename?: 'Stage', id?: string | null, name?: string | null, orderNumber?: number | null } | null> | null, pipelineInstances?: Array<{ __typename?: 'PipelineInstance', id?: string | null, name?: string | null, averageAmount?: number | null, orderNumber?: number | null, numberOfClosedWons?: number | null } | null> | null } | null, durations?: Array<{ __typename?: 'Duration', pipelineInstanceId?: string | null, stageId?: string | null, duration?: number | null, conversionRate?: number | null, numberOfDeals?: number | null } | null> | null } | null };

export type InstancesQueryVariables = Exact<{
  columnId: Scalars['String']['input'];
}>;


export type InstancesQuery = { __typename?: 'Query', instances: Array<{ __typename?: 'InstancesResponse', name: string, averageAmount: number } | null> };

export type ClientsQueryVariables = Exact<{
  filter?: InputMaybe<ClientsFilter>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'ClientsResponse', cruxyId?: string | null, name?: string | null } | null> };

export type CustomColumnsQueryVariables = Exact<{
  filter: CustomColumnFilters;
}>;


export type CustomColumnsQuery = { __typename?: 'Query', customColumns: Array<{ __typename?: 'CustomColumn', columnId?: string | null, columnName?: string | null, cruxyId?: string | null, tableId?: string | null } | null> };

export type CompaniesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  filter: CompaniesFilter;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'CompaniesResponse', totalCount: string, edges: Array<{ __typename?: 'Company', id: string, name: string, parentCompanyId?: string | null, type?: string | null, cruxyId: string, createdById?: string | null, createDate?: string | null, country?: string | null, annualRevenue?: string | null, industry?: string | null, customProperties?: any | null }> } };

export type ConversionRatesQueryVariables = Exact<{
  pipelineId: Scalars['String']['input'];
}>;


export type ConversionRatesQuery = { __typename?: 'Query', conversionRates: Array<{ __typename?: 'ConversionRatesResponse', conversionRate: number, numberOfDeals: number, stageId: string, instanceId: string } | null> };

export type CrmColumnsQueryVariables = Exact<{
  crmColumnsId: Scalars['String']['input'];
}>;


export type CrmColumnsQuery = { __typename?: 'Query', crmColumns: Array<string | null> };

export type CrmPipelinesQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
}>;


export type CrmPipelinesQuery = { __typename?: 'Query', crmPipelines: Array<{ __typename?: 'CRMPipelinesResponse', id: string, name: string, displayOrder: number } | null> };

export type DealsQueryVariables = Exact<{
  filter: DealsFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DealsQuery = { __typename?: 'Query', deals: { __typename?: 'DealsResponse', totalCount: string, edges: Array<{ __typename?: 'Deal', id: string, dealName?: string | null, dealStage?: string | null, createDate?: string | null, closeDate?: string | null, dealOwnerId?: string | null, amount?: string | null, analyticsLatestSource?: string | null, pipeline?: string | null, fiscalYear?: string | null, type?: string | null, dealCurrencyCode?: string | null, createdById?: string | null, arr?: string | null, amountInHomeCurrency?: string | null, acv?: string | null, customProperties?: any | null, associatedCompany: Array<{ __typename?: 'Company', id: string, name: string, createDate?: string | null, createdById?: string | null, country?: string | null } | null>, associatedContact: Array<{ __typename?: 'Contact', id: string, firstName: string, lastName: string } | null> }> } };

export type ExpectationQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
}>;


export type ExpectationQuery = { __typename?: 'Query', expectations?: Array<{ __typename?: 'Projection', amount: number, amountConverted: number, cruxyId: string, dateOfCalculation: string, dateOfProjection: string, instanceName: string, minimumStage: number, numberOfDeals: number, numberOfDealsConverted: number, pipelineId: string } | null> | null };

export type FormulasQueryVariables = Exact<{
  columnId: Scalars['String']['input'];
}>;


export type FormulasQuery = { __typename?: 'Query', formulas?: Array<{ __typename?: 'Formula', id: string, columnId: string, conditionFormula: any, valueFormula: string } | null> | null };

export type InvoiceLogDatesQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
}>;


export type InvoiceLogDatesQuery = { __typename?: 'Query', invoiceLogDates: Array<string | null> };

export type InvoiceLogsQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
  date: Scalars['String']['input'];
}>;


export type InvoiceLogsQuery = { __typename?: 'Query', invoiceLogs?: { __typename?: 'InvoiceLogsResponse', cruxyId?: string | null, companies?: Array<{ __typename?: 'InvoiceLog', invoiceName?: string | null, matchName?: string | null, matchId?: string | null, numberOfDealsHidden?: number | null, idsOfDealsHidden?: Array<string | null> | null, newDealID?: string | null, insertedNow?: boolean | null, changes?: { __typename?: 'Changes', name?: { __typename?: 'Change', from?: string | null, to?: string | null } | null, country?: { __typename?: 'Change', from?: string | null, to?: string | null } | null, industry?: { __typename?: 'Change', from?: string | null, to?: string | null } | null } | null } | null> | null } | null };

export type InvoicesQueryVariables = Exact<{
  filter: InvoicesFilter;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type InvoicesQuery = { __typename?: 'Query', invoices: { __typename?: 'InvoicesResponse', totalCount: string, edges: Array<{ __typename?: 'Invoice', invoiceNumber: string, accountName?: string | null, accountLegalName?: string | null, accountStatus?: string | null, accountId?: string | null, check?: string | null, annualisedConverted?: string | null, annualisedValue?: string | null, contractType?: string | null, creditGbp?: string | null, cruxyId: string, currency?: string | null, currentMonth?: string | null, date?: string | null, debitGbp?: string | null, description?: string | null, endDate?: string | null, exchangeRate?: string | null, id: string, industry?: string | null, industryPrimarySegment?: string | null, industrySubsegment?: string | null, invoiceLengthMonths?: string | null, keyStakeholderTitleOrPosition?: string | null, mrr?: string | null, productSold?: string | null, reference?: string | null, salesLastYear?: string | null, sourceOfInvoiceData?: string | null, startDate?: string | null, country?: string | null } | null> } };

export type GetApplyInvoicesPlanQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
}>;


export type GetApplyInvoicesPlanQuery = { __typename?: 'Query', getApplyInvoicesPlan?: { __typename?: 'GetApplyInvoicesPlanResponse', cruxyId?: string | null, companies?: Array<{ __typename?: 'InvoiceLog', invoiceName?: string | null, matchName?: string | null, matchId?: string | null, idsOfDealsHidden?: Array<string | null> | null, numberOfDealsHidden?: number | null, newDealID?: string | null, insertedNow?: boolean | null, changes?: { __typename?: 'Changes', name?: { __typename?: 'Change', from?: string | null, to?: string | null } | null, country?: { __typename?: 'Change', from?: string | null, to?: string | null } | null, industry?: { __typename?: 'Change', from?: string | null, to?: string | null } | null } | null } | null> | null } | null };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentUser', cruxyId: string, id: string, role?: UserRole | null } | null };

export type OperatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type OperatorsQuery = { __typename?: 'Query', operators: Array<{ __typename?: 'Operators', symbol?: string | null, text?: string | null }> };

export type PipelinesQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
}>;


export type PipelinesQuery = { __typename?: 'Query', pipelines?: Array<{ __typename?: 'PipelinesResponse', id: string, name: string, data?: { __typename?: 'Pipeline', basePipelineId: string, columnId: string, durations?: Array<{ __typename?: 'Duration', duration?: number | null, pipelineInstanceId?: string | null, stageId?: string | null, conversionRate?: number | null, numberOfDeals?: number | null } | null> | null, schema?: { __typename?: 'Schema', pipelineInstances?: Array<{ __typename?: 'PipelineInstance', averageAmount?: number | null, id?: string | null, name?: string | null, orderNumber?: number | null, numberOfClosedWons?: number | null } | null> | null, stages?: Array<{ __typename?: 'Stage', id?: string | null, name?: string | null, orderNumber?: number | null } | null> | null } | null } | null } | null> | null };

export type RealityQueryVariables = Exact<{
  cruxyId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
}>;


export type RealityQuery = { __typename?: 'Query', reality?: Array<{ __typename?: 'Projection', cruxyId: string, pipelineId: string, instanceName: string, minimumStage: number, numberOfDeals: number, numberOfDealsConverted: number, amount: number, amountConverted: number, dateOfCalculation: string, dateOfProjection: string } | null> | null };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles?: Array<UserRole | null> | null };

export type StagesQueryVariables = Exact<{
  pipelineId: Scalars['String']['input'];
}>;


export type StagesQuery = { __typename?: 'Query', stages: Array<{ __typename?: 'StagesResponse', id: string, label: string, displayOrder: number } | null> };

export type CrmTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type CrmTablesQuery = { __typename?: 'Query', crmTables: Array<{ __typename?: 'CRMTables', id?: string | null, name?: string | null, path?: string | null } | null> };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UsersFilter>;
}>;


export type UsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: string, role: UserRole, email: string, cruxyId: string } | null> | null };


export const ApplyFormulaDocument = gql`
    mutation ApplyFormula($applyFormulaId: String!) {
  applyFormula(id: $applyFormulaId)
}
    `;
export type ApplyFormulaMutationFn = Apollo.MutationFunction<ApplyFormulaMutation, ApplyFormulaMutationVariables>;

/**
 * __useApplyFormulaMutation__
 *
 * To run a mutation, you first call `useApplyFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyFormulaMutation, { data, loading, error }] = useApplyFormulaMutation({
 *   variables: {
 *      applyFormulaId: // value for 'applyFormulaId'
 *   },
 * });
 */
export function useApplyFormulaMutation(baseOptions?: Apollo.MutationHookOptions<ApplyFormulaMutation, ApplyFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyFormulaMutation, ApplyFormulaMutationVariables>(ApplyFormulaDocument, options);
      }
export type ApplyFormulaMutationHookResult = ReturnType<typeof useApplyFormulaMutation>;
export type ApplyFormulaMutationResult = Apollo.MutationResult<ApplyFormulaMutation>;
export type ApplyFormulaMutationOptions = Apollo.BaseMutationOptions<ApplyFormulaMutation, ApplyFormulaMutationVariables>;
export const ApplyFormulasForColumnDocument = gql`
    mutation ApplyFormulasForColumn($columnId: String!) {
  applyFormulasForColumn(columnId: $columnId) {
    formulaId
    message
  }
}
    `;
export type ApplyFormulasForColumnMutationFn = Apollo.MutationFunction<ApplyFormulasForColumnMutation, ApplyFormulasForColumnMutationVariables>;

/**
 * __useApplyFormulasForColumnMutation__
 *
 * To run a mutation, you first call `useApplyFormulasForColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyFormulasForColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyFormulasForColumnMutation, { data, loading, error }] = useApplyFormulasForColumnMutation({
 *   variables: {
 *      columnId: // value for 'columnId'
 *   },
 * });
 */
export function useApplyFormulasForColumnMutation(baseOptions?: Apollo.MutationHookOptions<ApplyFormulasForColumnMutation, ApplyFormulasForColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyFormulasForColumnMutation, ApplyFormulasForColumnMutationVariables>(ApplyFormulasForColumnDocument, options);
      }
export type ApplyFormulasForColumnMutationHookResult = ReturnType<typeof useApplyFormulasForColumnMutation>;
export type ApplyFormulasForColumnMutationResult = Apollo.MutationResult<ApplyFormulasForColumnMutation>;
export type ApplyFormulasForColumnMutationOptions = Apollo.BaseMutationOptions<ApplyFormulasForColumnMutation, ApplyFormulasForColumnMutationVariables>;
export const ApplyInvoicesDocument = gql`
    mutation ApplyInvoices($cruxyId: String!) {
  applyInvoices(cruxyId: $cruxyId) {
    cruxyId
    companies {
      invoiceName
      matchName
      matchId
      changes {
        name {
          from
          to
        }
        country {
          from
          to
        }
        industry {
          from
          to
        }
      }
      numberOfDealsHidden
      idsOfDealsHidden
      newDealID
      insertedNow
    }
  }
}
    `;
export type ApplyInvoicesMutationFn = Apollo.MutationFunction<ApplyInvoicesMutation, ApplyInvoicesMutationVariables>;

/**
 * __useApplyInvoicesMutation__
 *
 * To run a mutation, you first call `useApplyInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyInvoicesMutation, { data, loading, error }] = useApplyInvoicesMutation({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function useApplyInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<ApplyInvoicesMutation, ApplyInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyInvoicesMutation, ApplyInvoicesMutationVariables>(ApplyInvoicesDocument, options);
      }
export type ApplyInvoicesMutationHookResult = ReturnType<typeof useApplyInvoicesMutation>;
export type ApplyInvoicesMutationResult = Apollo.MutationResult<ApplyInvoicesMutation>;
export type ApplyInvoicesMutationOptions = Apollo.BaseMutationOptions<ApplyInvoicesMutation, ApplyInvoicesMutationVariables>;
export const DeleteColumnDocument = gql`
    mutation DeleteColumn($deleteColumnId: String!) {
  deleteColumn(id: $deleteColumnId)
}
    `;
export type DeleteColumnMutationFn = Apollo.MutationFunction<DeleteColumnMutation, DeleteColumnMutationVariables>;

/**
 * __useDeleteColumnMutation__
 *
 * To run a mutation, you first call `useDeleteColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteColumnMutation, { data, loading, error }] = useDeleteColumnMutation({
 *   variables: {
 *      deleteColumnId: // value for 'deleteColumnId'
 *   },
 * });
 */
export function useDeleteColumnMutation(baseOptions?: Apollo.MutationHookOptions<DeleteColumnMutation, DeleteColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteColumnMutation, DeleteColumnMutationVariables>(DeleteColumnDocument, options);
      }
export type DeleteColumnMutationHookResult = ReturnType<typeof useDeleteColumnMutation>;
export type DeleteColumnMutationResult = Apollo.MutationResult<DeleteColumnMutation>;
export type DeleteColumnMutationOptions = Apollo.BaseMutationOptions<DeleteColumnMutation, DeleteColumnMutationVariables>;
export const DeleteFormulaDocument = gql`
    mutation DeleteFormula($deleteFormulaId: String!) {
  deleteFormula(id: $deleteFormulaId)
}
    `;
export type DeleteFormulaMutationFn = Apollo.MutationFunction<DeleteFormulaMutation, DeleteFormulaMutationVariables>;

/**
 * __useDeleteFormulaMutation__
 *
 * To run a mutation, you first call `useDeleteFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormulaMutation, { data, loading, error }] = useDeleteFormulaMutation({
 *   variables: {
 *      deleteFormulaId: // value for 'deleteFormulaId'
 *   },
 * });
 */
export function useDeleteFormulaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormulaMutation, DeleteFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormulaMutation, DeleteFormulaMutationVariables>(DeleteFormulaDocument, options);
      }
export type DeleteFormulaMutationHookResult = ReturnType<typeof useDeleteFormulaMutation>;
export type DeleteFormulaMutationResult = Apollo.MutationResult<DeleteFormulaMutation>;
export type DeleteFormulaMutationOptions = Apollo.BaseMutationOptions<DeleteFormulaMutation, DeleteFormulaMutationVariables>;
export const DeletePipelineTagDocument = gql`
    mutation DeletePipelineTag($deletePipelineTagId: String!) {
  deletePipelineTag(id: $deletePipelineTagId)
}
    `;
export type DeletePipelineTagMutationFn = Apollo.MutationFunction<DeletePipelineTagMutation, DeletePipelineTagMutationVariables>;

/**
 * __useDeletePipelineTagMutation__
 *
 * To run a mutation, you first call `useDeletePipelineTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePipelineTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePipelineTagMutation, { data, loading, error }] = useDeletePipelineTagMutation({
 *   variables: {
 *      deletePipelineTagId: // value for 'deletePipelineTagId'
 *   },
 * });
 */
export function useDeletePipelineTagMutation(baseOptions?: Apollo.MutationHookOptions<DeletePipelineTagMutation, DeletePipelineTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePipelineTagMutation, DeletePipelineTagMutationVariables>(DeletePipelineTagDocument, options);
      }
export type DeletePipelineTagMutationHookResult = ReturnType<typeof useDeletePipelineTagMutation>;
export type DeletePipelineTagMutationResult = Apollo.MutationResult<DeletePipelineTagMutation>;
export type DeletePipelineTagMutationOptions = Apollo.BaseMutationOptions<DeletePipelineTagMutation, DeletePipelineTagMutationVariables>;
export const EditCompanyDocument = gql`
    mutation EditCompany($fields: EditCompanyFields!, $editCompanyId: String!) {
  editCompany(fields: $fields, id: $editCompanyId) {
    id
    name
    parentCompanyId
    type
    cruxyId
    createdById
    createDate
    country
    annualRevenue
    industry
    customProperties
  }
}
    `;
export type EditCompanyMutationFn = Apollo.MutationFunction<EditCompanyMutation, EditCompanyMutationVariables>;

/**
 * __useEditCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyMutation, { data, loading, error }] = useEditCompanyMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *      editCompanyId: // value for 'editCompanyId'
 *   },
 * });
 */
export function useEditCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditCompanyMutation, EditCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCompanyMutation, EditCompanyMutationVariables>(EditCompanyDocument, options);
      }
export type EditCompanyMutationHookResult = ReturnType<typeof useEditCompanyMutation>;
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompanyMutation>;
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompanyMutation, EditCompanyMutationVariables>;
export const EditDealDocument = gql`
    mutation EditDeal($fields: EditDealFields!, $editDealId: String!) {
  editDeal(fields: $fields, id: $editDealId) {
    id
    dealName
    dealStage
    createDate
    closeDate
    dealOwnerId
    amount
    analyticsLatestSource
    associatedCompany {
      id
      name
      createDate
      createdById
      country
    }
    pipeline
    associatedContact {
      id
      firstName
      lastName
    }
    fiscalYear
    type
    dealCurrencyCode
    createdById
    arr
    amountInHomeCurrency
    acv
    customProperties
  }
}
    `;
export type EditDealMutationFn = Apollo.MutationFunction<EditDealMutation, EditDealMutationVariables>;

/**
 * __useEditDealMutation__
 *
 * To run a mutation, you first call `useEditDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDealMutation, { data, loading, error }] = useEditDealMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *      editDealId: // value for 'editDealId'
 *   },
 * });
 */
export function useEditDealMutation(baseOptions?: Apollo.MutationHookOptions<EditDealMutation, EditDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDealMutation, EditDealMutationVariables>(EditDealDocument, options);
      }
export type EditDealMutationHookResult = ReturnType<typeof useEditDealMutation>;
export type EditDealMutationResult = Apollo.MutationResult<EditDealMutation>;
export type EditDealMutationOptions = Apollo.BaseMutationOptions<EditDealMutation, EditDealMutationVariables>;
export const RecalculateExpectationsDocument = gql`
    mutation RecalculateExpectations($cruxyId: String!, $pipelineId: String!, $input: JSONObject!) {
  recalculateExpectations(
    cruxyId: $cruxyId
    pipelineId: $pipelineId
    input: $input
  ) {
    cruxyId
    pipelineId
    instanceName
    minimumStage
    numberOfDeals
    numberOfDealsConverted
    amount
    amountConverted
    dateOfCalculation
    dateOfProjection
  }
}
    `;
export type RecalculateExpectationsMutationFn = Apollo.MutationFunction<RecalculateExpectationsMutation, RecalculateExpectationsMutationVariables>;

/**
 * __useRecalculateExpectationsMutation__
 *
 * To run a mutation, you first call `useRecalculateExpectationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecalculateExpectationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recalculateExpectationsMutation, { data, loading, error }] = useRecalculateExpectationsMutation({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *      pipelineId: // value for 'pipelineId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecalculateExpectationsMutation(baseOptions?: Apollo.MutationHookOptions<RecalculateExpectationsMutation, RecalculateExpectationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecalculateExpectationsMutation, RecalculateExpectationsMutationVariables>(RecalculateExpectationsDocument, options);
      }
export type RecalculateExpectationsMutationHookResult = ReturnType<typeof useRecalculateExpectationsMutation>;
export type RecalculateExpectationsMutationResult = Apollo.MutationResult<RecalculateExpectationsMutation>;
export type RecalculateExpectationsMutationOptions = Apollo.BaseMutationOptions<RecalculateExpectationsMutation, RecalculateExpectationsMutationVariables>;
export const RefreshRealityDocument = gql`
    mutation RefreshReality($cruxyId: String!, $pipelineId: String!, $untilDate: String!) {
  refreshReality(
    cruxyId: $cruxyId
    pipelineId: $pipelineId
    untilDate: $untilDate
  ) {
    amount
    amountConverted
    cruxyId
    dateOfCalculation
    dateOfProjection
    instanceName
    minimumStage
    numberOfDeals
    numberOfDealsConverted
    pipelineId
  }
}
    `;
export type RefreshRealityMutationFn = Apollo.MutationFunction<RefreshRealityMutation, RefreshRealityMutationVariables>;

/**
 * __useRefreshRealityMutation__
 *
 * To run a mutation, you first call `useRefreshRealityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshRealityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshRealityMutation, { data, loading, error }] = useRefreshRealityMutation({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *      pipelineId: // value for 'pipelineId'
 *      untilDate: // value for 'untilDate'
 *   },
 * });
 */
export function useRefreshRealityMutation(baseOptions?: Apollo.MutationHookOptions<RefreshRealityMutation, RefreshRealityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshRealityMutation, RefreshRealityMutationVariables>(RefreshRealityDocument, options);
      }
export type RefreshRealityMutationHookResult = ReturnType<typeof useRefreshRealityMutation>;
export type RefreshRealityMutationResult = Apollo.MutationResult<RefreshRealityMutation>;
export type RefreshRealityMutationOptions = Apollo.BaseMutationOptions<RefreshRealityMutation, RefreshRealityMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshToken
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password)
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
  signOut {
    id
    cruxyId
    email
    role
    iat
    exp
  }
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!, $role: UserRole!, $cruxyId: String!) {
  signUp(email: $email, password: $password, role: $role, cruxyId: $cruxyId)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UpsertPipelineTagDocument = gql`
    mutation UpsertPipelineTag($upsertPipelineTagId: String, $name: String!, $cruxyId: String) {
  upsertPipelineTag(id: $upsertPipelineTagId, name: $name, cruxyId: $cruxyId)
}
    `;
export type UpsertPipelineTagMutationFn = Apollo.MutationFunction<UpsertPipelineTagMutation, UpsertPipelineTagMutationVariables>;

/**
 * __useUpsertPipelineTagMutation__
 *
 * To run a mutation, you first call `useUpsertPipelineTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPipelineTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPipelineTagMutation, { data, loading, error }] = useUpsertPipelineTagMutation({
 *   variables: {
 *      upsertPipelineTagId: // value for 'upsertPipelineTagId'
 *      name: // value for 'name'
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function useUpsertPipelineTagMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPipelineTagMutation, UpsertPipelineTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPipelineTagMutation, UpsertPipelineTagMutationVariables>(UpsertPipelineTagDocument, options);
      }
export type UpsertPipelineTagMutationHookResult = ReturnType<typeof useUpsertPipelineTagMutation>;
export type UpsertPipelineTagMutationResult = Apollo.MutationResult<UpsertPipelineTagMutation>;
export type UpsertPipelineTagMutationOptions = Apollo.BaseMutationOptions<UpsertPipelineTagMutation, UpsertPipelineTagMutationVariables>;
export const UpsertColumnDocument = gql`
    mutation upsertColumn($input: UpsertColumnInput!, $upsertColumnId: ID) {
  upsertColumn(input: $input, id: $upsertColumnId)
}
    `;
export type UpsertColumnMutationFn = Apollo.MutationFunction<UpsertColumnMutation, UpsertColumnMutationVariables>;

/**
 * __useUpsertColumnMutation__
 *
 * To run a mutation, you first call `useUpsertColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertColumnMutation, { data, loading, error }] = useUpsertColumnMutation({
 *   variables: {
 *      input: // value for 'input'
 *      upsertColumnId: // value for 'upsertColumnId'
 *   },
 * });
 */
export function useUpsertColumnMutation(baseOptions?: Apollo.MutationHookOptions<UpsertColumnMutation, UpsertColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertColumnMutation, UpsertColumnMutationVariables>(UpsertColumnDocument, options);
      }
export type UpsertColumnMutationHookResult = ReturnType<typeof useUpsertColumnMutation>;
export type UpsertColumnMutationResult = Apollo.MutationResult<UpsertColumnMutation>;
export type UpsertColumnMutationOptions = Apollo.BaseMutationOptions<UpsertColumnMutation, UpsertColumnMutationVariables>;
export const UpsertFormulaDocument = gql`
    mutation UpsertFormula($input: UpsertFormulaInput!, $upsertFormulaId: String) {
  upsertFormula(input: $input, id: $upsertFormulaId) {
    columnId
    conditionFormula
    id
    valueFormula
    cruxyId
  }
}
    `;
export type UpsertFormulaMutationFn = Apollo.MutationFunction<UpsertFormulaMutation, UpsertFormulaMutationVariables>;

/**
 * __useUpsertFormulaMutation__
 *
 * To run a mutation, you first call `useUpsertFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFormulaMutation, { data, loading, error }] = useUpsertFormulaMutation({
 *   variables: {
 *      input: // value for 'input'
 *      upsertFormulaId: // value for 'upsertFormulaId'
 *   },
 * });
 */
export function useUpsertFormulaMutation(baseOptions?: Apollo.MutationHookOptions<UpsertFormulaMutation, UpsertFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertFormulaMutation, UpsertFormulaMutationVariables>(UpsertFormulaDocument, options);
      }
export type UpsertFormulaMutationHookResult = ReturnType<typeof useUpsertFormulaMutation>;
export type UpsertFormulaMutationResult = Apollo.MutationResult<UpsertFormulaMutation>;
export type UpsertFormulaMutationOptions = Apollo.BaseMutationOptions<UpsertFormulaMutation, UpsertFormulaMutationVariables>;
export const UpsertPipelineDocument = gql`
    mutation UpsertPipeline($input: UpsertPipelineInput!, $pipelineId: String!, $columnId: String!, $basePipelineId: String!) {
  upsertPipeline(
    input: $input
    pipelineId: $pipelineId
    columnId: $columnId
    basePipelineId: $basePipelineId
  ) {
    basePipelineId
    columnId
    schema {
      stages {
        id
        name
        orderNumber
      }
      pipelineInstances {
        id
        name
        averageAmount
        orderNumber
        numberOfClosedWons
      }
    }
    durations {
      pipelineInstanceId
      stageId
      duration
      conversionRate
      numberOfDeals
    }
  }
}
    `;
export type UpsertPipelineMutationFn = Apollo.MutationFunction<UpsertPipelineMutation, UpsertPipelineMutationVariables>;

/**
 * __useUpsertPipelineMutation__
 *
 * To run a mutation, you first call `useUpsertPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPipelineMutation, { data, loading, error }] = useUpsertPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *      pipelineId: // value for 'pipelineId'
 *      columnId: // value for 'columnId'
 *      basePipelineId: // value for 'basePipelineId'
 *   },
 * });
 */
export function useUpsertPipelineMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPipelineMutation, UpsertPipelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPipelineMutation, UpsertPipelineMutationVariables>(UpsertPipelineDocument, options);
      }
export type UpsertPipelineMutationHookResult = ReturnType<typeof useUpsertPipelineMutation>;
export type UpsertPipelineMutationResult = Apollo.MutationResult<UpsertPipelineMutation>;
export type UpsertPipelineMutationOptions = Apollo.BaseMutationOptions<UpsertPipelineMutation, UpsertPipelineMutationVariables>;
export const InstancesDocument = gql`
    query Instances($columnId: String!) {
  instances(columnId: $columnId) {
    name
    averageAmount
  }
}
    `;

/**
 * __useInstancesQuery__
 *
 * To run a query within a React component, call `useInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstancesQuery({
 *   variables: {
 *      columnId: // value for 'columnId'
 *   },
 * });
 */
export function useInstancesQuery(baseOptions: Apollo.QueryHookOptions<InstancesQuery, InstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstancesQuery, InstancesQueryVariables>(InstancesDocument, options);
      }
export function useInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstancesQuery, InstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstancesQuery, InstancesQueryVariables>(InstancesDocument, options);
        }
export type InstancesQueryHookResult = ReturnType<typeof useInstancesQuery>;
export type InstancesLazyQueryHookResult = ReturnType<typeof useInstancesLazyQuery>;
export type InstancesQueryResult = Apollo.QueryResult<InstancesQuery, InstancesQueryVariables>;
export const ClientsDocument = gql`
    query Clients($filter: ClientsFilter) {
  clients(filter: $filter) {
    cruxyId
    name
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const CustomColumnsDocument = gql`
    query CustomColumns($filter: CustomColumnFilters!) {
  customColumns(filter: $filter) {
    columnId
    columnName
    cruxyId
    tableId
  }
}
    `;

/**
 * __useCustomColumnsQuery__
 *
 * To run a query within a React component, call `useCustomColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomColumnsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomColumnsQuery(baseOptions: Apollo.QueryHookOptions<CustomColumnsQuery, CustomColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomColumnsQuery, CustomColumnsQueryVariables>(CustomColumnsDocument, options);
      }
export function useCustomColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomColumnsQuery, CustomColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomColumnsQuery, CustomColumnsQueryVariables>(CustomColumnsDocument, options);
        }
export type CustomColumnsQueryHookResult = ReturnType<typeof useCustomColumnsQuery>;
export type CustomColumnsLazyQueryHookResult = ReturnType<typeof useCustomColumnsLazyQuery>;
export type CustomColumnsQueryResult = Apollo.QueryResult<CustomColumnsQuery, CustomColumnsQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($limit: Int, $offset: Int, $filter: CompaniesFilter!) {
  companies(limit: $limit, offset: $offset, filter: $filter) {
    totalCount
    edges {
      id
      name
      parentCompanyId
      type
      cruxyId
      createdById
      createDate
      country
      annualRevenue
      industry
      customProperties
    }
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const ConversionRatesDocument = gql`
    query ConversionRates($pipelineId: String!) {
  conversionRates(pipelineId: $pipelineId) {
    conversionRate
    numberOfDeals
    stageId
    instanceId
  }
}
    `;

/**
 * __useConversionRatesQuery__
 *
 * To run a query within a React component, call `useConversionRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionRatesQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useConversionRatesQuery(baseOptions: Apollo.QueryHookOptions<ConversionRatesQuery, ConversionRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversionRatesQuery, ConversionRatesQueryVariables>(ConversionRatesDocument, options);
      }
export function useConversionRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversionRatesQuery, ConversionRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversionRatesQuery, ConversionRatesQueryVariables>(ConversionRatesDocument, options);
        }
export type ConversionRatesQueryHookResult = ReturnType<typeof useConversionRatesQuery>;
export type ConversionRatesLazyQueryHookResult = ReturnType<typeof useConversionRatesLazyQuery>;
export type ConversionRatesQueryResult = Apollo.QueryResult<ConversionRatesQuery, ConversionRatesQueryVariables>;
export const CrmColumnsDocument = gql`
    query CrmColumns($crmColumnsId: String!) {
  crmColumns(id: $crmColumnsId)
}
    `;

/**
 * __useCrmColumnsQuery__
 *
 * To run a query within a React component, call `useCrmColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmColumnsQuery({
 *   variables: {
 *      crmColumnsId: // value for 'crmColumnsId'
 *   },
 * });
 */
export function useCrmColumnsQuery(baseOptions: Apollo.QueryHookOptions<CrmColumnsQuery, CrmColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmColumnsQuery, CrmColumnsQueryVariables>(CrmColumnsDocument, options);
      }
export function useCrmColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmColumnsQuery, CrmColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmColumnsQuery, CrmColumnsQueryVariables>(CrmColumnsDocument, options);
        }
export type CrmColumnsQueryHookResult = ReturnType<typeof useCrmColumnsQuery>;
export type CrmColumnsLazyQueryHookResult = ReturnType<typeof useCrmColumnsLazyQuery>;
export type CrmColumnsQueryResult = Apollo.QueryResult<CrmColumnsQuery, CrmColumnsQueryVariables>;
export const CrmPipelinesDocument = gql`
    query CrmPipelines($cruxyId: String!) {
  crmPipelines(cruxyId: $cruxyId) {
    id
    name
    displayOrder
  }
}
    `;

/**
 * __useCrmPipelinesQuery__
 *
 * To run a query within a React component, call `useCrmPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmPipelinesQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function useCrmPipelinesQuery(baseOptions: Apollo.QueryHookOptions<CrmPipelinesQuery, CrmPipelinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmPipelinesQuery, CrmPipelinesQueryVariables>(CrmPipelinesDocument, options);
      }
export function useCrmPipelinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmPipelinesQuery, CrmPipelinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmPipelinesQuery, CrmPipelinesQueryVariables>(CrmPipelinesDocument, options);
        }
export type CrmPipelinesQueryHookResult = ReturnType<typeof useCrmPipelinesQuery>;
export type CrmPipelinesLazyQueryHookResult = ReturnType<typeof useCrmPipelinesLazyQuery>;
export type CrmPipelinesQueryResult = Apollo.QueryResult<CrmPipelinesQuery, CrmPipelinesQueryVariables>;
export const DealsDocument = gql`
    query Deals($filter: DealsFilter!, $limit: Int, $offset: Int) {
  deals(filter: $filter, limit: $limit, offset: $offset) {
    totalCount
    edges {
      id
      dealName
      dealStage
      createDate
      closeDate
      dealOwnerId
      amount
      analyticsLatestSource
      associatedCompany {
        id
        name
        createDate
        createdById
        country
      }
      pipeline
      associatedContact {
        id
        firstName
        lastName
      }
      fiscalYear
      type
      dealCurrencyCode
      createdById
      arr
      amountInHomeCurrency
      acv
      customProperties
    }
  }
}
    `;

/**
 * __useDealsQuery__
 *
 * To run a query within a React component, call `useDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDealsQuery(baseOptions: Apollo.QueryHookOptions<DealsQuery, DealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
      }
export function useDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealsQuery, DealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
        }
export type DealsQueryHookResult = ReturnType<typeof useDealsQuery>;
export type DealsLazyQueryHookResult = ReturnType<typeof useDealsLazyQuery>;
export type DealsQueryResult = Apollo.QueryResult<DealsQuery, DealsQueryVariables>;
export const ExpectationDocument = gql`
    query Expectation($cruxyId: String!, $pipelineId: String!) {
  expectations(cruxyId: $cruxyId, pipelineId: $pipelineId) {
    amount
    amountConverted
    cruxyId
    dateOfCalculation
    dateOfProjection
    instanceName
    minimumStage
    numberOfDeals
    numberOfDealsConverted
    pipelineId
  }
}
    `;

/**
 * __useExpectationQuery__
 *
 * To run a query within a React component, call `useExpectationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpectationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpectationQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useExpectationQuery(baseOptions: Apollo.QueryHookOptions<ExpectationQuery, ExpectationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpectationQuery, ExpectationQueryVariables>(ExpectationDocument, options);
      }
export function useExpectationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpectationQuery, ExpectationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpectationQuery, ExpectationQueryVariables>(ExpectationDocument, options);
        }
export type ExpectationQueryHookResult = ReturnType<typeof useExpectationQuery>;
export type ExpectationLazyQueryHookResult = ReturnType<typeof useExpectationLazyQuery>;
export type ExpectationQueryResult = Apollo.QueryResult<ExpectationQuery, ExpectationQueryVariables>;
export const FormulasDocument = gql`
    query Formulas($columnId: String!) {
  formulas(columnId: $columnId) {
    id
    columnId
    conditionFormula
    valueFormula
  }
}
    `;

/**
 * __useFormulasQuery__
 *
 * To run a query within a React component, call `useFormulasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormulasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormulasQuery({
 *   variables: {
 *      columnId: // value for 'columnId'
 *   },
 * });
 */
export function useFormulasQuery(baseOptions: Apollo.QueryHookOptions<FormulasQuery, FormulasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormulasQuery, FormulasQueryVariables>(FormulasDocument, options);
      }
export function useFormulasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormulasQuery, FormulasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormulasQuery, FormulasQueryVariables>(FormulasDocument, options);
        }
export type FormulasQueryHookResult = ReturnType<typeof useFormulasQuery>;
export type FormulasLazyQueryHookResult = ReturnType<typeof useFormulasLazyQuery>;
export type FormulasQueryResult = Apollo.QueryResult<FormulasQuery, FormulasQueryVariables>;
export const InvoiceLogDatesDocument = gql`
    query InvoiceLogDates($cruxyId: String!) {
  invoiceLogDates(cruxyId: $cruxyId)
}
    `;

/**
 * __useInvoiceLogDatesQuery__
 *
 * To run a query within a React component, call `useInvoiceLogDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLogDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLogDatesQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function useInvoiceLogDatesQuery(baseOptions: Apollo.QueryHookOptions<InvoiceLogDatesQuery, InvoiceLogDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceLogDatesQuery, InvoiceLogDatesQueryVariables>(InvoiceLogDatesDocument, options);
      }
export function useInvoiceLogDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLogDatesQuery, InvoiceLogDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceLogDatesQuery, InvoiceLogDatesQueryVariables>(InvoiceLogDatesDocument, options);
        }
export type InvoiceLogDatesQueryHookResult = ReturnType<typeof useInvoiceLogDatesQuery>;
export type InvoiceLogDatesLazyQueryHookResult = ReturnType<typeof useInvoiceLogDatesLazyQuery>;
export type InvoiceLogDatesQueryResult = Apollo.QueryResult<InvoiceLogDatesQuery, InvoiceLogDatesQueryVariables>;
export const InvoiceLogsDocument = gql`
    query InvoiceLogs($cruxyId: String!, $date: String!) {
  invoiceLogs(cruxyId: $cruxyId, date: $date) {
    cruxyId
    companies {
      invoiceName
      matchName
      matchId
      changes {
        name {
          from
          to
        }
        country {
          from
          to
        }
        industry {
          from
          to
        }
      }
      numberOfDealsHidden
      idsOfDealsHidden
      newDealID
      insertedNow
    }
  }
}
    `;

/**
 * __useInvoiceLogsQuery__
 *
 * To run a query within a React component, call `useInvoiceLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLogsQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useInvoiceLogsQuery(baseOptions: Apollo.QueryHookOptions<InvoiceLogsQuery, InvoiceLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceLogsQuery, InvoiceLogsQueryVariables>(InvoiceLogsDocument, options);
      }
export function useInvoiceLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLogsQuery, InvoiceLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceLogsQuery, InvoiceLogsQueryVariables>(InvoiceLogsDocument, options);
        }
export type InvoiceLogsQueryHookResult = ReturnType<typeof useInvoiceLogsQuery>;
export type InvoiceLogsLazyQueryHookResult = ReturnType<typeof useInvoiceLogsLazyQuery>;
export type InvoiceLogsQueryResult = Apollo.QueryResult<InvoiceLogsQuery, InvoiceLogsQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($filter: InvoicesFilter!, $limit: Int, $offset: Int) {
  invoices(filter: $filter, limit: $limit, offset: $offset) {
    totalCount
    edges {
      invoiceNumber
      accountName
      accountLegalName
      accountStatus
      accountId
      check
      annualisedConverted
      annualisedValue
      contractType
      creditGbp
      cruxyId
      currency
      currentMonth
      date
      debitGbp
      description
      endDate
      exchangeRate
      id
      industry
      industryPrimarySegment
      industrySubsegment
      invoiceLengthMonths
      keyStakeholderTitleOrPosition
      mrr
      productSold
      reference
      salesLastYear
      sourceOfInvoiceData
      startDate
      country
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const GetApplyInvoicesPlanDocument = gql`
    query GetApplyInvoicesPlan($cruxyId: String!) {
  getApplyInvoicesPlan(cruxyId: $cruxyId) {
    cruxyId
    companies {
      invoiceName
      matchName
      matchId
      changes {
        name {
          from
          to
        }
        country {
          from
          to
        }
        industry {
          from
          to
        }
      }
      idsOfDealsHidden
      numberOfDealsHidden
      newDealID
      insertedNow
    }
  }
}
    `;

/**
 * __useGetApplyInvoicesPlanQuery__
 *
 * To run a query within a React component, call `useGetApplyInvoicesPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplyInvoicesPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplyInvoicesPlanQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function useGetApplyInvoicesPlanQuery(baseOptions: Apollo.QueryHookOptions<GetApplyInvoicesPlanQuery, GetApplyInvoicesPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplyInvoicesPlanQuery, GetApplyInvoicesPlanQueryVariables>(GetApplyInvoicesPlanDocument, options);
      }
export function useGetApplyInvoicesPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplyInvoicesPlanQuery, GetApplyInvoicesPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplyInvoicesPlanQuery, GetApplyInvoicesPlanQueryVariables>(GetApplyInvoicesPlanDocument, options);
        }
export type GetApplyInvoicesPlanQueryHookResult = ReturnType<typeof useGetApplyInvoicesPlanQuery>;
export type GetApplyInvoicesPlanLazyQueryHookResult = ReturnType<typeof useGetApplyInvoicesPlanLazyQuery>;
export type GetApplyInvoicesPlanQueryResult = Apollo.QueryResult<GetApplyInvoicesPlanQuery, GetApplyInvoicesPlanQueryVariables>;
export const UserDocument = gql`
    query User {
  me {
    cruxyId
    id
    role
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const OperatorsDocument = gql`
    query Operators {
  operators {
    symbol
    text
  }
}
    `;

/**
 * __useOperatorsQuery__
 *
 * To run a query within a React component, call `useOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperatorsQuery(baseOptions?: Apollo.QueryHookOptions<OperatorsQuery, OperatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperatorsQuery, OperatorsQueryVariables>(OperatorsDocument, options);
      }
export function useOperatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperatorsQuery, OperatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperatorsQuery, OperatorsQueryVariables>(OperatorsDocument, options);
        }
export type OperatorsQueryHookResult = ReturnType<typeof useOperatorsQuery>;
export type OperatorsLazyQueryHookResult = ReturnType<typeof useOperatorsLazyQuery>;
export type OperatorsQueryResult = Apollo.QueryResult<OperatorsQuery, OperatorsQueryVariables>;
export const PipelinesDocument = gql`
    query Pipelines($cruxyId: String!) {
  pipelines(cruxyId: $cruxyId) {
    id
    name
    data {
      basePipelineId
      columnId
      durations {
        duration
        pipelineInstanceId
        stageId
        conversionRate
        numberOfDeals
      }
      schema {
        pipelineInstances {
          averageAmount
          id
          name
          orderNumber
          numberOfClosedWons
        }
        stages {
          id
          name
          orderNumber
        }
      }
    }
  }
}
    `;

/**
 * __usePipelinesQuery__
 *
 * To run a query within a React component, call `usePipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipelinesQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *   },
 * });
 */
export function usePipelinesQuery(baseOptions: Apollo.QueryHookOptions<PipelinesQuery, PipelinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PipelinesQuery, PipelinesQueryVariables>(PipelinesDocument, options);
      }
export function usePipelinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PipelinesQuery, PipelinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PipelinesQuery, PipelinesQueryVariables>(PipelinesDocument, options);
        }
export type PipelinesQueryHookResult = ReturnType<typeof usePipelinesQuery>;
export type PipelinesLazyQueryHookResult = ReturnType<typeof usePipelinesLazyQuery>;
export type PipelinesQueryResult = Apollo.QueryResult<PipelinesQuery, PipelinesQueryVariables>;
export const RealityDocument = gql`
    query Reality($cruxyId: String!, $pipelineId: String!) {
  reality(cruxyId: $cruxyId, pipelineId: $pipelineId) {
    cruxyId
    pipelineId
    instanceName
    minimumStage
    numberOfDeals
    numberOfDealsConverted
    amount
    amountConverted
    dateOfCalculation
    dateOfProjection
  }
}
    `;

/**
 * __useRealityQuery__
 *
 * To run a query within a React component, call `useRealityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealityQuery({
 *   variables: {
 *      cruxyId: // value for 'cruxyId'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useRealityQuery(baseOptions: Apollo.QueryHookOptions<RealityQuery, RealityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RealityQuery, RealityQueryVariables>(RealityDocument, options);
      }
export function useRealityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealityQuery, RealityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RealityQuery, RealityQueryVariables>(RealityDocument, options);
        }
export type RealityQueryHookResult = ReturnType<typeof useRealityQuery>;
export type RealityLazyQueryHookResult = ReturnType<typeof useRealityLazyQuery>;
export type RealityQueryResult = Apollo.QueryResult<RealityQuery, RealityQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const StagesDocument = gql`
    query Stages($pipelineId: String!) {
  stages(pipelineId: $pipelineId) {
    id
    label
    displayOrder
  }
}
    `;

/**
 * __useStagesQuery__
 *
 * To run a query within a React component, call `useStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStagesQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useStagesQuery(baseOptions: Apollo.QueryHookOptions<StagesQuery, StagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StagesQuery, StagesQueryVariables>(StagesDocument, options);
      }
export function useStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StagesQuery, StagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StagesQuery, StagesQueryVariables>(StagesDocument, options);
        }
export type StagesQueryHookResult = ReturnType<typeof useStagesQuery>;
export type StagesLazyQueryHookResult = ReturnType<typeof useStagesLazyQuery>;
export type StagesQueryResult = Apollo.QueryResult<StagesQuery, StagesQueryVariables>;
export const CrmTablesDocument = gql`
    query CrmTables {
  crmTables {
    id
    name
    path
  }
}
    `;

/**
 * __useCrmTablesQuery__
 *
 * To run a query within a React component, call `useCrmTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmTablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrmTablesQuery(baseOptions?: Apollo.QueryHookOptions<CrmTablesQuery, CrmTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmTablesQuery, CrmTablesQueryVariables>(CrmTablesDocument, options);
      }
export function useCrmTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmTablesQuery, CrmTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmTablesQuery, CrmTablesQueryVariables>(CrmTablesDocument, options);
        }
export type CrmTablesQueryHookResult = ReturnType<typeof useCrmTablesQuery>;
export type CrmTablesLazyQueryHookResult = ReturnType<typeof useCrmTablesLazyQuery>;
export type CrmTablesQueryResult = Apollo.QueryResult<CrmTablesQuery, CrmTablesQueryVariables>;
export const UsersDocument = gql`
    query Users($filter: UsersFilter) {
  users(filter: $filter) {
    id
    role
    email
    cruxyId
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;