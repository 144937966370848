import colors from "assets/theme-dark/base/colors";
import MDButton from "components/MDButton";
import { useEffect } from "react";
import { v4 } from "uuid";
import addNewObject from "../utils/addNewObject";
import { objectPropHandler } from "../utils/objectPropHandler";
import { getSubTreeById } from "../utils/getSubTreeById";
import repairTree from "../utils/repairTree";
import { testIds } from "utils/testIds";

interface Props {
    relation: string;
    variable1: string;
    variable2: string | number;
    operator: string;
    tree: any;
    subTree: any;
    isConstant: boolean;
    selectedSubTree: any;
    setUpTree: (newTree: any, subTree?: any) => void;
    aliasGiver: (rule: any, relation: string, alias: string, id: string) => any;
    setTree: (value: any) => void;
    relationOperator: string;
}

function SubmitEditButton(props: Props) {
    const {
        relation,
        variable1,
        variable2,
        operator,
        tree,
        subTree,
        isConstant,
        selectedSubTree,
        setUpTree,
        setTree,
        relationOperator,
        aliasGiver,
    } = props;

    function replaceRegularCriteria() {
        const newCriteria = {
            id: v4(),
            [operator]: [
                {
                    value: variable1,
                    isColumn: true,
                },
                {
                    value: variable2,
                    isColumn: !isConstant,
                },
            ],
        };

        const newSubTree = newCriteria;

        if (tree.id === selectedSubTree.id) {
            const aliasedTree = aliasGiver(
                newSubTree[objectPropHandler(newSubTree)],
                objectPropHandler(newSubTree),
                "C",
                newSubTree.id
            );
            const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

            const key = objectPropHandler(aliasedTree);
            const repairedTree = repairTree(aliasedTree[key], key, aliasedTree.id);

            setUpTree(repairedTree, subAliasedTree);
            return;
        }
        const newTree = addNewObject(newSubTree, tree, selectedSubTree);

        const key = objectPropHandler(newTree);
        const repairedTree = repairTree(newTree[key], key, newTree.id);

        const aliasedTree = aliasGiver(
            newTree[objectPropHandler(newTree)],
            objectPropHandler(newTree),
            "C",
            newTree.id
        );
        const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

        setUpTree(repairedTree, subAliasedTree);
    }

    function replaceContainsCriteria() {
        //@ts-ignore
        const values = variable2.split(",");
        const newCriteria = {
            id: v4(),
            [operator]: [
                {
                    value: variable1,
                    isColumn: true,
                },
                values.map((item: any) => {
                    return { value: item, isColumn: false };
                }),
            ],
        };

        const newSubTree = newCriteria;
        if (tree.id === selectedSubTree.id) {
            const aliasedTree = aliasGiver(
                newSubTree[objectPropHandler(newSubTree)],
                objectPropHandler(newSubTree),
                "C",
                newSubTree.id
            );
            const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

            const key = objectPropHandler(aliasedTree);
            const repairedTree = repairTree(aliasedTree[key], key, aliasedTree.id);

            setUpTree(repairedTree, subAliasedTree);
            return;
        }
        const newTree = addNewObject(newSubTree, tree, selectedSubTree);

        const key = objectPropHandler(newTree);
        const repairedTree = repairTree(newTree[key], key, newTree.id);

        const aliasedTree = aliasGiver(
            newTree[objectPropHandler(newTree)],
            objectPropHandler(newTree),
            "C",
            newTree.id
        );
        const subAliasedTree = getSubTreeById(newSubTree, aliasedTree, selectedSubTree);

        setUpTree(repairedTree, subAliasedTree);
    }

    return (
        <>
            <MDButton
                data-testid={testIds.submitEditBtn}
                sx={{
                    visibility: Boolean(variable1 && variable2?.toString()?.length > 0 && operator)
                        ? "visible"
                        : "hidden",
                    //backgroundColor: colors.secondary.main,
                }}
                onClick={() => {
                    if (operator === "CONTAINS") {
                        replaceContainsCriteria();
                    } else {
                        replaceRegularCriteria();
                    }
                }}
                color="secondary"
                variant="gradient"
                type="submit"
                size="large"
                fullWidth
            >
                EDIT
            </MDButton>
        </>
    );
}

export default SubmitEditButton;
