import MDBox from "components/MDBox";
import Criteria from "./Criteria";
import colors from "assets/theme-dark/base/colors";
import MDButton from "components/MDButton";
import { useEffect } from "react";
import { v4 } from "uuid";
import { objectPropHandler } from "../utils/objectPropHandler";
import { testIds } from "utils/testIds";

function RuleForm(props: {
    selectedSubTree: { [key: string]: any };
    setSelectedSubTree: (value: any) => void;
    relation: string;
    setRelation: (value: string) => void;
}) {
    const { selectedSubTree, setSelectedSubTree, setRelation, relation } = props;

    function criteriaMaker(rule: any) {
        const key = objectPropHandler(rule);
        if (key !== "AND" && key !== "OR") {
            const item = rule[key];
            setRelation("");
            if (key === "CONTAINS") {
                return (
                    <Criteria
                        relation={relation}
                        setSelectedSubTree={setSelectedSubTree}
                        currentSubTree={item}
                        alias={rule.alias}
                    />
                );
            }

            return (
                <Criteria
                    relation={relation}
                    setSelectedSubTree={setSelectedSubTree}
                    currentSubTree={item}
                    alias={rule.alias}
                />
            );
        } else {
            setRelation(key);
            return rule[key].map((item: any, index: number) => {
                const key = objectPropHandler(item);
                if (key !== "AND" && key !== "OR") {
                    const item2 = item[key];

                    if (key === "CONTAINS") {
                        return (
                            <Criteria
                                key={v4()}
                                setSelectedSubTree={setSelectedSubTree}
                                currentSubTree={item}
                                relation={relation}
                                alias={item.alias}
                            />
                        );
                    }

                    return (
                        <Criteria
                            relation={relation}
                            setSelectedSubTree={setSelectedSubTree}
                            key={v4()}
                            currentSubTree={item}
                            alias={item.alias}
                        />
                    );
                }
                return (
                    <MDButton
                        data-testid={testIds.criteria}
                        key={v4()}
                        onClick={() => setSelectedSubTree(item)}
                        sx={{
                            border: "1px solid white",
                            display: "flex",
                            width: "100%",
                            //minWidth: "120px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                            marginBottom: "1rem",
                            fontWeight: "600",
                            height: "3.5rem",
                            fontSize: "1rem",
                            backgroundColor: colors.white.main,
                            boxShadow: `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                        }}
                    >
                        {item.alias}
                    </MDButton>
                );
            });
        }
    }

    return <MDBox>{selectedSubTree ? <>{criteriaMaker(selectedSubTree)}</> : <></>}</MDBox>;
}

export default RuleForm;
