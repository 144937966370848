import Action from "components/Action";
import { enqueueSnackbar } from "notistack";

export default function confirmSnack(myFunction: any, question: any) {
    const action = (snackbarId: any) => (
        <>
            <Action snackbarId={snackbarId} myFunction={myFunction} />
        </>
    );

    enqueueSnackbar(question, {
        action,
        autoHideDuration: 5000,
    });
}
