export function labelGiver(key: string) {
    switch (key) {
        case "EQUAL":
            return " = ";
        case "NOT_EQUAL":
            return " != ";
        case "LESS_THAN":
            return " < ";
        case "LESS_OR_EQUAL":
            return " <= ";
        case "GREATER_THAN":
            return " > ";
        case "GREATER_OR_EQUAL":
            return " >= ";
        case "REGEX":
            return " LIKE ";
        case "CONTAINS":
            return " : ";
    }
}
