export const TOKEN_STORAGE = "token";

export const LOGIN_ERROR = "Something went wrong during login! :(";

export const PAGE_HOME = "home";
export const PAGE_TABLE = "table";
export const CRUXY_ADMIN = "CruxyAdmin";
export const CRUXY_USER = "CruxyUser";
export const CLIENT_ADMIN = "ClientAdmin";
export const CLIENT_USER = "ClientUser";
