import { Duration, PipelineInstance, Stage } from "graphql/types/graphql";

export default function nextValueFinder(
    currentStage: Stage,
    stages: Stage[],
    durations: Duration[],
    instances: PipelineInstance[]
) {
    const nextItemIdPosition = stages.indexOf(currentStage) - 1;
    const cards = [
        ...durations
            .filter((duration: any) => {
                return duration.stageId === stages[nextItemIdPosition].id;
            })
            .map((duration: any) => {
                return {
                    pipelineInstanceId: instances.find((item: any) => {
                        return duration.pipelineInstanceId === item.id;
                    }).id,
                    ownId: currentStage.id,
                    template: duration.duration,
                    value2: duration.conversionRate,
                    value3: duration.numberOfDeals,
                    column: duration.stageId,
                    stageId: duration.stageId,
                    isConversion: false,
                    orderNumber: instances.find((item: any) => {
                        return duration.pipelineInstanceId === item.id;
                    }).orderNumber,
                    isFirst: false,
                };
            })
            .sort(function (a: any, b: any) {
                const x = instances.find((item: PipelineInstance) => {
                    return item.id === a.pipelineInstanceId;
                });

                const y = instances.find((item: PipelineInstance) => {
                    return item.id === b.pipelineInstanceId;
                });

                return x.averageAmount - y.averageAmount;
            }),
    ];
    return cards;
}
