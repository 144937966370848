import { useState, useEffect, useContext, ReactElement, JSXElementConstructor, Key } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";

import Sidenav from "examples/Sidenav";

import themeDark from "assets/theme-dark";

import routes from "routes";

import { useMaterialUIController, setMiniSidenav } from "context";

import { context } from "utils/Provider";
import { Backdrop, Stack } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import RequireAuth from "utils/RequireAuth";
import cruxy from "assets/images/cruxy.png";
import MDBox from "components/MDBox";

export default function App({ undefineToken }: { undefineToken: () => void }) {
    const [controller, dispatch] = useMaterialUIController();
    const [opacity, setOpacity] = useState<boolean>(true);
    const theme: any = colors;

    const { miniSidenav, direction, layout, openConfigurator, sidenavColor, setOpenConfigurator, darkMode } =
        controller;

    const { appLoading, setUpApp, isLoading, showScroll, setShowScroll, user } = useContext(context);

    useEffect(() => {
        setUpApp();
    }, []);

    useEffect(() => {
        let interval;
        if (appLoading) {
            interval = setInterval(() => {
                setOpacity((current) => !current);
            }, 500);
        } else {
            clearInterval(interval);
        }
    }, [appLoading]);

    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        // if (miniSidenav && !onMouseEnter) {
        //     setMiniSidenav(dispatch, false);
        //     setOnMouseEnter(true);
        // }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        // if (onMouseEnter) {
        //     setMiniSidenav(dispatch, true);
        //     setOnMouseEnter(false);
        // }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    if ((appLoading && isLoading) || appLoading) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    fontSize: "2rem",
                    padding: 0,
                    margin: 0,
                    fontWeight: "600",
                    overflow: "hidden",
                    backgroundColor: theme.black.main,
                }}
            >
                <img
                    style={{
                        transition: "opacity 1s",
                        opacity: opacity ? 1 : 0.1,
                    }}
                    src={cruxy}
                    alt={"image"}
                />
            </Stack>
        );
    }

    const userRoutes = routes.filter((route: any) => {
        return route.availableFor.indexOf(user?.role) !== -1;
    });

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
                <>
                    <Sidenav
                        undefineToken={undefineToken}
                        color={sidenavColor}
                        brandName="Material Dashboard PRO"
                        routes={userRoutes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                </>
            )}
            <RequireAuth routes={userRoutes} />
            <div>
                <Backdrop sx={{ color: "#fff", zIndex: 999999 }} open={Boolean(isLoading && !appLoading)}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </div>
        </ThemeProvider>
    );
}
