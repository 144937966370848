import { useMutation } from "@apollo/client";
import { useSignInMutation } from "graphql/types/graphql";
import { useState } from "react";
import { User } from "../../../../../utils/types";
import { CRUXY_ADMIN, LOGIN_ERROR, TOKEN_STORAGE } from "../../../../../common/names";

export function useAuth(getCompanies: any, getUserInfo: (myToken: string) => void, setToken: (value: string) => void) {
    const [signIn] = useSignInMutation();
    const [user, setUser] = useState<User | undefined>();

    function logOut() {
        localStorage.removeItem(TOKEN_STORAGE);
        setUser(undefined);
    }

    async function logIn(email: string, password: string): Promise<boolean | string> {
        try {
            const response: any = await signIn({
                variables: { email: email, password: password },
            });

            if (response?.errors) {
                return response.errors[0].message;
            } else {
                const token = response.data.signIn;
                setToken(token);
                getUserInfo(token);
                return true;
            }
        } catch (err: any) {
            try {
                console.log(JSON.stringify(err));
                return err.graphQLErrors[0].message;
            } catch (error) {
                return LOGIN_ERROR;
            }
        }
    }

    return {
        user,
        setUser,
        logIn,
        logOut,
    };
}
