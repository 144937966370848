import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export default styled(Switch)(({ theme, ownerState }: any) => {
    const { palette } = theme;
    const { color } = ownerState;
    const { white, secondary, grey } = palette;

    // styles for the button with variant="contained"
    const containedStyles = () => {
        // background color value
        const backgroundValue = palette[color] ? palette[color].main : white.main;

        // color value when button is focused

        return {
            "&.MuiSwitch-root": {
                "& .MuiSwitch-thumb": {
                    backgroundColor: white.main,
                    height: "24px",
                    width: "24px",
                    borderRadius: "12px",
                },
                "& .MuiSwitch-track": {
                    backgroundColor: `${grey[700]} !important`,
                    borderColor: "transparent",
                    height: "20px",
                    width: "40px",
                    borderRadius: "10px",
                },
                "& .Mui-checked+.MuiSwitch-track": {
                    backgroundColor: `${backgroundValue} !important`,
                },
            },
        };
    };

    return {
        ...containedStyles(),
    };
});
