import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import { User } from "graphql/types/graphql";
import { scrollbar } from "utils/scrollBar";

interface Column {
    id: "id" | "email" | "role" | "cruxyId";
    label: string;
}

const columns: Column[] = [
    // {
    //     id: "id",
    //     label: "ID",
    // },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "role",
        label: "Role",
    },
    {
        id: "cruxyId",
        label: "CruxyId",
    },
];

function UsersTable({ users }: { users: User[] }) {
    return (
        <TableContainer
            sx={{
                color: colors.white.main,
                height: "100%",
                background: "transparent",
                borderRadius: "0px",
                maxHeight: "500px",
                ...scrollbar,
            }}
        >
            <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead sx={{ width: "100%", backgroundColor: colors.background.default }}>
                    {columns.map((column) => (
                        <TableCell align="center" sx={{ color: colors.white.main }} key={column.id}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                    {users.map((row, index) => {
                        return (
                            <TableRow
                                sx={{
                                    color: colors.white.main,

                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                                key={row.id}
                            >
                                {columns.map((column) => {
                                    const value: any = row[column.id];
                                    return (
                                        <TableCell align="center" sx={{ color: colors.white.main }} key={column.id}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default UsersTable;
