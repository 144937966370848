declare global {
    interface Window {
        _env_?: Record<string, string>;
    }
}

const BACKEND_URL = window._env_?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL;
const WHY = window._env_?.REACT_APP_WHY || process.env.REACT_APP_WHY;
const WHERE = window._env_?.REACT_APP_WHERE || process.env.REACT_APP_WHERE;
const REALITY = window._env_?.REACT_APP_REALITY || process.env.REACT_APP_REALITY;
const EXPECTATION = window._env_?.REACT_APP_EXPECTATION || process.env.REACT_APP_EXPECTATION;
const VISUAL = window._env_?.REACT_APP_VISUAL || process.env.REACT_APP_VISUAL;
const WHY_TABLE = window._env_?.REACT_APP_WHY_TABLE || process.env.REACT_APP_WHY_TABLE;
export { BACKEND_URL, WHY, WHERE, REALITY, EXPECTATION, VISUAL, WHY_TABLE };
