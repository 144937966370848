import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { ClientsResponse } from "graphql/types/graphql";
import { useEffect, useRef, useState } from "react";
import { FilterUpdateType, TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import { VISUAL } from "common/constants";

interface Props {
    pipelineId: string;
    token: string;
}

function TableauView({ pipelineId, token }: Props) {
    const viz: any = useRef(null);
    const [width, setWidth] = useState<number>(1300);
    const [height, setHeight] = useState<number>(300);
    const idRef: any = useRef(null);
    idRef.current = pipelineId;

    useEffect(() => {
        viz?.current?.addFilter("ID", idRef.current);
    }, [pipelineId]);

    return (
        <MDBox
            sx={{
                bottom: 0,
                flex: 1,
                overflowX: "auto",
                backgroundColor: colors.background.sidenav,
                width: "60%",
                height: "300px",
            }}
        >
            <TableauEmbed
                height={height}
                ref={(el) => {
                    if (el !== viz.current) {
                        el?.addFilter("ID", idRef.current);
                    }
                    viz.current = el;
                }}
                toolbar="hidden"
                token={token}
                sourceUrl={VISUAL}
            />
        </MDBox>
    );
}

export default TableauView;
