import { MenuItem, TextField, Typography } from "@mui/material";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
    ClientsResponse,
    PipelinesResponse,
    Projection,
    useExpectationLazyQuery,
    useRecalculateExpectationsMutation,
    useRefreshRealityMutation,
} from "graphql/types/graphql";
import { useContext, useEffect, useRef, useState } from "react";
import Inputs from "./Inputs";
import { User } from "utils/types";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { v4 } from "uuid";
import { REALITY, EXPECTATION } from "common/constants";
import { context } from "utils/Provider";
import { linearGradientStyle } from "utils/linearGradientsStyle";

function Charts(props: {
    token: string;
    setIndex: (value: number) => void;
    setIsLoading: (value: boolean) => void;
    user: User;
    selectedCompany: ClientsResponse;
    properties: {
        pipelines: PipelinesResponse[];
        instances: string[];
        expectations: Projection[];
        reality: Projection[];
        index: number;
    };
}) {
    const { refreshAccessToken } = useContext(context);
    const [realityId, setRealityId] = useState<string>("1");
    const [expectationId, setExpectationId] = useState<string>("2");
    const { token, setIndex, setIsLoading, user, properties, selectedCompany } = props;
    const [date, setDate] = useState<string>();

    const [refreshProjection] = useRefreshRealityMutation();
    const [recalculateExpectation] = useRecalculateExpectationsMutation();

    async function refreshProjectionHandler() {
        try {
            setIsLoading(true);

            const response = await refreshProjection({
                variables: {
                    cruxyId: selectedCompany.cruxyId,
                    pipelineId: properties.pipelines[properties.index].id,
                    untilDate: date,
                },
            });

            if (response.data.refreshReality !== null) {
                // vizExpectation.current.refreshDataAsync();
                refreshAccessToken();
                enqueueSnackbar("Projection have been refreshed!!!", { variant: "success" });
                setIsLoading(false);
                return true;
            } else {
                setIsLoading(false);
                return false;
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setIsLoading(false);
            return false;
        }
    }

    async function recalculateExpectationHandler(input: any) {
        try {
            setIsLoading(true);
            const response = await recalculateExpectation({
                variables: {
                    cruxyId: selectedCompany.cruxyId,
                    pipelineId: properties.pipelines[properties.index].id,
                    input: input,
                },
            });
            if (response) {
                refreshAccessToken();
                enqueueSnackbar("Expectation has been implemented!", { variant: "success" });
                setIsLoading(false);
                setRealityId(v4());
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            enqueueSnackbar("Something went wrong!", { variant: "error" });
            setIsLoading(false);
        }
    }

    function isDateInPast() {
        if (!date) {
            return true;
        } else {
            const currentDate = new Date();

            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();

            const today = year + "-" + month + "-" + day;

            const dateValue1 = new Date(date);
            const dateValue2 = new Date(today);

            if (dateValue1 < dateValue2) {
                return true;
            } else {
                return false;
            }
        }
    }

    function prepareInitials() {
        const initials: any = {};

        if (properties.expectations.length === 0) {
            const setupInitials = properties.instances.map((instance: string) => (initials[instance] = 0));
        } else {
            const setupInitials = properties.instances.map((instance: string) => {
                const currentExpectation = properties.expectations.find(
                    (expectation: Projection) => expectation.instanceName === instance
                );
                const currentReality = properties.reality.find(
                    (reality: Projection) => reality.instanceName === instance
                );

                initials[instance] = currentExpectation?.numberOfDeals - currentReality?.numberOfDeals || 0;
            });
        }
        return initials;
    }

    return (
        <MDBox
            sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",

                //backgroundColor: colors.background.card,
                position: "relative",
                minHeight: "750px",
                ...linearGradientStyle,
            }}
        >
            <MDBox
                sx={{
                    width: "20%",
                    minWidth: "12rem",
                    display: "flex",
                    flexDirection: "column",
                    //alignItems: "center",
                    marginBottom: "2rem",
                }}
            >
                <MDBox sx={{ width: "100%" }}>
                    <TextField
                        size="small"
                        sx={{
                            width: "100%",
                            "@media (max-width:1600px)": {
                                width: "100%",
                            },
                            //marginBottom: "1.5rem",
                        }}
                        value={properties.pipelines[properties.index].name}
                        select
                        label="Pipelines"
                    >
                        {properties.pipelines.map((option: { id: string; name: string }, index: number) => (
                            <MenuItem
                                onClick={() => {
                                    setIndex(index);
                                }}
                                key={option.id}
                                value={option.name}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </MDBox>
            </MDBox>
            <MDBox
                sx={{
                    width: "100%",
                    //minWidth: "500px",
                    gap: 4,
                    display: "flex",
                    flexDirection: { xxl: "row", lg: "column", md: "column", xs: "column" },
                    flex: 1,
                }}
            >
                <MDBox
                    sx={{
                        width: { xxl: "80%", lg: "100%", md: "100%", xs: "100%" },
                        //marginBottom: { xxl: "0rem", lg: "2rem", md: "2rem", xs: "2rem" },
                        height: "650px",
                        //border: "1px solid white",
                    }}
                >
                    <RealityChart
                        key={selectedCompany.cruxyId + "reality"}
                        id={realityId}
                        index={properties.index}
                        token={token}
                        pipelineId={properties.pipelines[properties.index].id}
                        selectedCompany={selectedCompany}
                    />
                </MDBox>
                <MDBox
                    sx={{
                        width: { xxl: "20%", lg: "100%", md: "100%", xs: "100%" },
                        display: "flex",
                        flexDirection: "column",
                        //border: "1px solid white",
                        justifyContent: "space-between",
                        gap: 2,
                    }}
                >
                    <MDBox
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "flex-end",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <TextField
                            size="small"
                            type="date"
                            sx={{
                                width: "100%",

                                marginLeft: "auto",
                                "& p": {
                                    color: colors.white.main,
                                },
                            }}
                            color="secondary"
                            onChange={(e: any) => {
                                setDate(e.target.value);
                            }}
                            helperText="Choose a projection date that is not in the past"
                        />
                        <MDButton
                            variant="gradient"
                            color="info"
                            size="large"
                            disabled={isDateInPast()}
                            sx={{
                                width: "100%",
                                marginTop: "1.5rem",
                                marginBottom: { xxl: "0rem", lg: "3rem", md: "3rem", xs: "3rem" },
                            }}
                            onClick={async () => {
                                const response = await refreshProjectionHandler();
                                if (response) {
                                    setRealityId(v4());
                                }
                            }}
                        >
                            Refresh
                        </MDButton>
                    </MDBox>
                    <Inputs
                        recalculateExpectationHandler={recalculateExpectationHandler}
                        pipelines={properties.pipelines}
                        index={properties.index}
                        setIndex={setIndex}
                        instances={prepareInitials()}
                    />
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default Charts;

const RealityChart = React.memo(function RealityChart({
    index,
    token,
    pipelineId,
    id,
    selectedCompany,
}: {
    id: string;
    index: number;
    token: string;
    pipelineId: string;
    selectedCompany: ClientsResponse;
}) {
    const vizReality: any = useRef(null);
    const idRef: any = useRef(null);
    idRef.current = pipelineId;

    useEffect(() => {
        if (id.length > 5) {
            vizReality?.current?.refreshDataAsync();
        }
    }, [id]);

    return (
        <div>
            <TableauEmbed
                key={v4()}
                ref={(el) => {
                    if (el !== vizReality.current) {
                        el?.addFilter("Pipeline Id", idRef.current);
                    }
                    vizReality.current = el;
                }}
                toolbar="hidden"
                device="desktop"
                token={token}
                sourceUrl={REALITY}
            />
        </div>
    );
});
