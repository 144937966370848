import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { testIds } from "utils/testIds";

function Action(props: { snackbarId: any; myFunction: () => void }) {
    const { snackbarId, myFunction } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
        <>
            <Button
                data-testid={testIds.confirmBtn}
                variant="text"
                color="success"
                sx={{ fontWeight: "900" }}
                onClick={() => {
                    closeSnackbar(snackbarId);
                    myFunction();
                }}
            >
                Confirm
            </Button>
            <Button
                variant="text"
                color="error"
                sx={{ fontWeight: "900" }}
                onClick={() => {
                    closeSnackbar(snackbarId);
                }}
            >
                Dismiss
            </Button>
        </>
    );
}

export default Action;
