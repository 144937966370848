export default function removeSubObject(obj: any, subObjectToRemove: any): any {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        const filteredArray = obj.filter((item) => {
            if (typeof item === "object") {
                return item.id !== subObjectToRemove.id;
            }
            return true;
        });

        return filteredArray.map((item: any) => removeSubObject(item, subObjectToRemove));
    } else {
        const filteredObj = Object.fromEntries(
            Object.entries(obj).filter(([, value]) => {
                if (typeof value === "object") {
                    //@ts-ignore
                    return value.id !== subObjectToRemove.id;
                }
                return true;
            })
        );

        for (const key in filteredObj) {
            filteredObj[key] = removeSubObject(filteredObj[key], subObjectToRemove);
        }

        return filteredObj;
    }
}
