/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Theme } from "@mui/material/styles";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { useContext } from "react";

function collapseItem(theme: Theme, ownerState: any) {
    const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
    const { active, transparentSidenav, whiteSidenav, darkMode } = ownerState;

    const { white, transparent, dark, grey } = palette;
    const { md } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem, rgba } = functions;

    return {
        //border: "1px solid white",
        background: () => {
            let backgroundValue;

            if (transparentSidenav && darkMode) {
                backgroundValue = active ? rgba(white.main, 0.2) : transparent.main;
            } else if (transparentSidenav && !darkMode) {
                backgroundValue = active ? colors.primary.main : transparent.main;
            } else if (whiteSidenav) {
                backgroundValue = active ? colors.primary.main : transparent.main;
            } else {
                backgroundValue = active ? rgba(white.main, 0.2) : transparent.main;
            }

            return active ? colors.primary.main : transparent.main;
        },
        color: active ? colors.dark.main : colors.white.main,
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "3rem",

        padding: `${pxToRem(8)} ${pxToRem(16)}`,
        margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        boxShadow: active && !whiteSidenav && !darkMode && !transparentSidenav ? md : "none",
        [breakpoints.up("xl")]: {
            transition: transitions.create(["box-shadow", "background-color"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
            }),
        },

        "&:hover, &:focus": {
            backgroundColor: active ? colors.primary.main : rgba(whiteSidenav ? colors.primary.main : white.main, 0.2),
        },
    };
}

function collapseIconBox(theme: Theme, ownerState: any) {
    const { palette, transitions, borders, functions } = theme;
    const { transparentSidenav, whiteSidenav, darkMode, active } = ownerState;

    const [controller, dispatch] = useMaterialUIController();

    const { miniSidenav } = controller;
    const { white, dark } = palette;
    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        //border: "1px solid white",
        position: "absolute",
        borderRadius: 0,
        display: "grid",
        placeItems: "center",
        transition: transitions.create("margin", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),
        left: miniSidenav ? pxToRem(32) : 20,

        // "& svg, svg g": {
        //     color: active ? colors.dark.main : colors.secondary.main,
        // },
    };
}

const collapseIcon = ({ palette: { white, gradients } }: Theme, { active }: any) => ({
    color: active ? white.main : gradients.dark.state,
});

function collapseText(theme: any, ownerState: any) {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav, transparentSidenav, active } = ownerState;

    const { size, fontWeightRegular, fontWeightLight } = typography;
    const { pxToRem } = functions;

    return {
        //marginLeft: pxToRem(26),
        //border: "1px solid white",
        textAlign: "center",
        //opacity: 0,
        [breakpoints.up("xl")]: {
            opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
            maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
            //marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
            transition: !miniSidenav
                ? transitions.create(["opacity"], {
                      easing: transitions.easing.easeInOut,
                      duration: transitions.duration.standard,
                  })
                : "none",
        },

        "& span": {
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: 0,
        },
    };
}

function collapseArrow(theme: Theme, ownerState: any) {
    const { palette, typography, transitions, breakpoints, functions } = theme;
    const { noCollapse, transparentSidenav, whiteSidenav, miniSidenav, open, active, darkMode } = ownerState;

    const { white, dark } = palette;
    const { size } = typography;
    const { pxToRem, rgba } = functions;

    return {
        fontSize: `${size.lg} !important`,
        fontWeight: 700,
        marginBottom: pxToRem(-1),
        transform: open ? "rotate(0)" : "rotate(-180deg)",
        color: () => {
            let colorValue;

            if (transparentSidenav && darkMode) {
                colorValue = open || active ? white.main : rgba(white.main, 0.25);
            } else if (transparentSidenav || whiteSidenav) {
                colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
            } else {
                colorValue = open || active ? white.main : rgba(white.main, 0.5);
            }

            return colorValue;
        },
        transition: transitions.create(["color", "transform", "opacity"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
            display:
                noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
                    ? "none !important"
                    : "block !important",
        },
    };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow };
