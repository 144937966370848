import colors from "assets/theme-dark/base/colors";
import linearGradient from "assets/theme-dark/functions/linearGradient";
import { addOpacityToColor } from "./addOpacityToColor";

export const linearGradientStyle = {
    background: linearGradient(
        addOpacityToColor(colors.background.card, 0.15),
        addOpacityToColor(colors.background.state, 0.5),
        120
    ),
    //backdropFilter: "blur(20px)",
};
