/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { background } = colors;
const { borderRadius } = borders;

// types
type Types = any;

const sidenav: Types = {
    styleOverrides: {
        root: {
            width: pxToRem(250),
            whiteSpace: "nowrap",
            border: "none",
        },

        paper: {
            width: pxToRem(250),
            backgroundColor: background.sidenav,
            // height: {
            //     xxl: `calc(100vh - ${pxToRem(32)})`,
            //     lg: `calc(100vh - ${pxToRem(32)})`,
            //     md: `calc(100vh - ${pxToRem(32)})`,
            //     xs: `calc(100vh - ${pxToRem(0)})`,
            // },
            // margin: { xxl: pxToRem(16), lg: pxToRem(16), md: pxToRem(16), xs: pxToRem(16) },
            borderRadius: borderRadius.xl,
            border: "none",
        },

        paperAnchorDockedLeft: {
            borderRight: "none",
        },
    },
};

export default sidenav;
