import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import {
    Button,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import {
    ApplyFormulaMutationHookResult,
    CrmTables,
    CustomColumn,
    CustomColumnsDocument,
    useApplyFormulasForColumnMutation,
    useDeleteColumnMutation,
    useUpsertColumnMutation,
} from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import colors from "assets/theme-dark/base/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import confirmSnack from "utils/confirmSnack";
import { context } from "utils/Provider";
import CalculateIcon from "@mui/icons-material/Calculate";
import Upload from "components/Upload/Upload";
import { scrollbar } from "utils/scrollBar";
import { testIds } from "utils/testIds";
import CustomButton from "components/CustomButton";

function Utils(props: {
    setIsLoading: (value: boolean) => void;
    columns: CustomColumn[] | undefined;
    currentTable: CrmTables;
}) {
    const { user, selectedCompany } = useContext(context);

    const { setIsLoading, columns, currentTable } = props;

    const [formValue, setFormValue] = useState("");
    const [source, setSource] = useState<string>();
    const [endpoint, setEndpoint] = useState<string>();

    const [isFiltered, setIsFiltered] = useState(false);

    const handeSetFormValue = ({ currentTarget }: any) => {
        setFormValue(currentTarget.value.replace(" ", "_").toUpperCase());
    };
    const [createColumn] = useUpsertColumnMutation();
    const [deleteColumn] = useDeleteColumnMutation();
    const [applyFormulas] = useApplyFormulasForColumnMutation();

    useEffect(() => {
        if (source === "hubspot" && currentTable.id === "0d6cc0bc-95b7-454a-b0a0-773a081f42bb")
            setEndpoint("/hubspot/companies");
        if (source === "hubspot" && currentTable.id === "4a9ca6cd-c033-4a13-b23c-b1181e195b87")
            setEndpoint("/hubspot/deals");
        if (source === "salesforce" && currentTable.id === "0d6cc0bc-95b7-454a-b0a0-773a081f42bb")
            setEndpoint("/salesforce/companies");
        if (source === "salesforce" && currentTable.id === "4a9ca6cd-c033-4a13-b23c-b1181e195b87")
            setEndpoint("/salesforce/deals");
    }, [source, currentTable]);

    async function handleCreateColumn() {
        setIsLoading(true);
        try {
            const response = await createColumn({
                variables: {
                    input: { columnName: formValue, cruxyId: selectedCompany.cruxyId, tableId: currentTable.id },
                },
                update: (cache: any, { data }: any) => {
                    const data2 = cache.readQuery({
                        query: CustomColumnsDocument,
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: currentTable.id,
                            },
                        },
                    });
                    cache.writeQuery({
                        query: CustomColumnsDocument,
                        data: {
                            customColumns: [
                                ...data2.customColumns,
                                {
                                    columnId: data.upsertColumn,
                                    columnName: formValue,
                                    cruxyId: selectedCompany.cruxyId,
                                    tableId: currentTable.id,
                                },
                            ],
                        },
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: currentTable.id,
                            },
                        },
                    });
                },
            });
            if (response) {
                enqueueSnackbar("Column added succesfully!", {
                    variant: "success",
                });
                setFormValue("");
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar("Something went wrong!", {
                variant: "error",
            });
            setIsLoading(false);
        }
    }

    async function deleteColumnHandler(column: CustomColumn) {
        try {
            setIsLoading(true);
            const response = await deleteColumn({
                variables: {
                    deleteColumnId: column.columnId,
                },
                update: (cache: any, { data }: any) => {
                    const data2 = cache.readQuery({
                        query: CustomColumnsDocument,
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: currentTable.id,
                            },
                        },
                    });
                    const items = data2.customColumns.filter((item: CustomColumn) => {
                        return item.columnId !== column.columnId;
                    });
                    cache.writeQuery({
                        query: CustomColumnsDocument,
                        data: {
                            customColumns: items,
                        },
                        variables: {
                            filter: {
                                cruxyId: selectedCompany.cruxyId,
                                tableId: currentTable.id,
                            },
                        },
                    });
                },
            });

            if (response) {
                enqueueSnackbar("Column deleted succesfully!", {
                    variant: "success",
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            enqueueSnackbar("Something went wrong!!!", {
                variant: "error",
            });
            setIsLoading(false);
        }
    }

    async function calculateFormulas(columnId: string) {
        try {
            setIsLoading(true);
            const response = await applyFormulas({
                variables: { columnId: columnId },
                update: (cache: any) => {
                    if (currentTable.name === "Deals") {
                        cache.evict({ id: "ROOT_QUERY", fieldName: "deals" });
                    }

                    if (currentTable.name === "Companies") {
                        cache.evict({ id: "ROOT_QUERY", fieldName: "companies" });
                    }
                },
            });
            if (response) {
                enqueueSnackbar(response.data.applyFormulasForColumn[0].message.replace(" = ", ": "), {
                    variant: "success",
                });
                setIsLoading(false);
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong!", {
                variant: "error",
            });
            setIsLoading(false);
        }
    }
    return (
        <MDBox sx={{ display: "flex", width: "100%", flex: 1 }}>
            <MDBox
                sx={{
                    width: "50%",
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 5,
                    //border: "1px solid white",
                }}
            >
                <MDBox>
                    <MDTypography sx={{ fontWeight: 600, marginBottom: "1rem" }} variant="subtitle1">
                        New Column
                    </MDTypography>
                    <MDBox sx={{ width: "80%", display: "flex", justifyContent: "space-between" }}>
                        <TextField
                            inputProps={{ "data-testid": testIds.addColumnInput }}
                            sx={{ width: "80%" }}
                            value={formValue}
                            onChange={handeSetFormValue}
                            variant="standard"
                        />

                        <CustomButton
                            testId={testIds.addColumnBtn}
                            title="Add"
                            background={colors.white.main}
                            job={() => {
                                if (formValue.length < 3) return;
                                handleCreateColumn();
                            }}
                        />
                    </MDBox>
                </MDBox>
                <MDBox
                    sx={{
                        flexGrow: 1,
                        borderRadius: "5px",
                        minHeight: "150px",
                        width: "80%",
                        marginTop: "1rem",
                        //paddingTop: "1.5rem",
                        color: colors.white.main,
                        fontSize: "1rem !important",
                        //display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        gap: 2,
                        padding: "1rem",
                        paddingLeft: "0rem",
                        height: "300px",
                        //border: "1px solid white",
                        overflow: "auto",
                        ...scrollbar,
                    }}
                >
                    {columns &&
                        columns.map((column: CustomColumn) => {
                            return (
                                <>
                                    <ListItem
                                        data-testid={testIds.columnList}
                                        sx={{
                                            //marginBottom: "0.75rem",
                                            padding: "0.75rem",
                                            borderBottom: "0.5px solid white",
                                            marginBottom: "0.5rem",
                                        }}
                                        key={column.columnId}
                                        secondaryAction={
                                            <MDBox sx={{ display: "flex", gap: 1 }}>
                                                <MDButton
                                                    data-testid={testIds.columnListCalcBtn}
                                                    onClick={() => {
                                                        confirmSnack(
                                                            () => calculateFormulas(column.columnId),
                                                            `Are you sure you want to recalculate the ${column.columnName} column?`
                                                        );
                                                    }}
                                                    iconOnly
                                                    variant="gradient"
                                                    color="secondary"
                                                    sx={{
                                                        borderRadius: "50%",
                                                        width: "2rem ",
                                                        height: "2rem",
                                                    }}
                                                >
                                                    <CalculateIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.white.main,
                                                            width: "1.25rem",
                                                            height: "1.25rem",
                                                        }}
                                                    />
                                                </MDButton>
                                                <MDButton
                                                    data-testid={testIds.columnListDeleteBtn}
                                                    onClick={() => {
                                                        confirmSnack(
                                                            () => deleteColumnHandler(column),
                                                            `Are you sure you want to delete the ${column.columnName} column?`
                                                        );
                                                    }}
                                                    iconOnly
                                                    variant="gradient"
                                                    color="primary"
                                                    sx={{
                                                        borderRadius: "50%",
                                                        width: "2rem ",
                                                        height: "2rem",
                                                        //margin: "0.5rem",
                                                    }}
                                                >
                                                    <DeleteIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.white.main,
                                                            width: "1.25rem",
                                                            height: "1.25rem",
                                                        }}
                                                    />
                                                </MDButton>
                                            </MDBox>
                                        }
                                    >
                                        <List>{column.columnName}</List>
                                    </ListItem>
                                </>
                            );
                        })}
                </MDBox>
            </MDBox>
            <MDBox sx={{ width: "50%", flex: 1, display: "flex", flexDirection: "column", padding: "2rem" }}>
                <MDBox sx={{ display: "flex", gap: 3, alignItems: "center", marginBottom: "2rem" }}>
                    <TextField
                        size="small"
                        sx={{
                            width: "12rem",
                            minWidth: "140px",
                        }}
                        id="outlined-select-currency"
                        select
                        label="Destination"
                        value={source}
                    >
                        <MenuItem
                            onClick={() => {
                                setSource("hubspot");
                            }}
                            value={"HubSpot"}
                        >
                            {"HubSpot"}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setSource("salesforce");
                            }}
                            value={"Salesforce"}
                        >
                            {"Salesforce"}
                        </MenuItem>
                    </TextField>
                    <MDBox sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ color: "white !important" }}>Filtered?:</Typography>
                        <Checkbox
                            sx={{}}
                            color="primary"
                            checked={isFiltered}
                            onChange={() => setIsFiltered(!isFiltered)}
                        />
                    </MDBox>
                </MDBox>

                <MDBox sx={{ flex: 1 }}>
                    <Upload isFiltered={isFiltered} endpoint={endpoint} />
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default Utils;
