import { ReactNode } from "react";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from "examples/Sidenav/styles/sidenavItem";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

// Declaring props types for SidenavCollapse
interface Props {
    color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
    name: string;
    active?: boolean | string;
    nested?: boolean;
    children?: ReactNode;
    open?: boolean;
    [key: string]: any;
}

function SidenavItem({ color, name, active, nested, children, open, ...rest }: Props): JSX.Element {
    const [controller] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

    return (
        <>
            <ListItem
                {...rest}
                component="li"
                sx={(theme) =>
                    item(theme, {
                        active,
                        color,
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                    })
                }
            >
                <MDBox
                    sx={(theme: Theme): any =>
                        itemContent(theme, {
                            active,
                            miniSidenav,
                            name,
                            open,
                            nested,
                            transparentSidenav,
                            whiteSidenav,
                            darkMode,
                        })
                    }
                >
                    <ListItemText primary={name} />
                </MDBox>
            </ListItem>
        </>
    );
}

// Declaring default props for SidenavItem
SidenavItem.defaultProps = {
    color: "info",
    active: false,
    nested: false,
    children: false,
    open: false,
};

export default SidenavItem;
