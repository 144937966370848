import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import { testIds } from "utils/testIds";

interface Props {
    board: any;
    props: any;
    openNewCardForm: (event: any, id: string) => void;
    handleCurrentlyEditing: (id: string) => void;
    isEditingColumn: boolean;
    handleStageName: (value: { stageId: string; name: string }) => void;
    removeColumn: (stageId: string) => void;
}

function Column(prop: Props) {
    const { board, props, handleCurrentlyEditing, handleStageName, isEditingColumn, removeColumn } = prop;
    const [title, setTitle] = useState<string>(props.title);

    const handeSetFormValue = ({ currentTarget }: any) => {
        setTitle(currentTarget.value);
        handleStageName({ stageId: props.id, name: currentTarget.value });
    };

    if (props.creatingPipeline) {
        handleCurrentlyEditing(props.id);
    }

    useEffect(() => {
        setTitle(props.title);
    }, [board]);

    return (
        <MDBox
            data-testid="columnKanban"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            mb={3}
            //sx={{ backgroundColor: colors.primary.main }}
        >
            {isEditingColumn && props.id !== "instances" && props.id !== "total" && props.id !== "closedwon" ? (
                <div
                    style={{
                        width: "100%",
                        //border: "1px solid white",
                        fontSize: "14px",
                        fontWeight: "900",
                        textAlign: "center",
                    }}
                    onDoubleClick={() => {
                        handleCurrentlyEditing(props.id);
                    }}
                >
                    <TextField
                        inputProps={{ "data-testid": testIds.columnInput }}
                        value={title}
                        onChange={handeSetFormValue}
                        variant="standard"
                    />
                </div>
            ) : (
                <div
                    data-testid={testIds.columnDiv}
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        alignItems: "center",
                        fontSize: "24px",
                        height: "100%",
                    }}
                    onDoubleClick={() => {
                        if (props.id === "closedwon") return;
                        handleCurrentlyEditing(props.id);
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            display: "inline-block",
                            width: "90%",
                            height: "100%",
                            fontSize: "14px",
                            fontWeight: "600 !important",
                            textAlign: "center",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            color:
                                props.id === "total" || props.id === "instances"
                                    ? colors.white.main
                                    : colors.secondary.main,
                        }}
                    >
                        {title}
                    </Typography>
                </div>
            )}

            <MDBox
                sx={{
                    zIndex: 500,
                    top: "0px",
                    right: "0%",
                    //transform: "translate(-50%,-50%)",
                }}
                position="absolute"
                display={isEditingColumn && props.id !== "instances" && props.id !== "total" ? "flex" : "none"}
                justifyContent="flex-end"
                alignItems="center"
                gap={1}
                width="30%"
            >
                <MDButton
                    onClick={() => {
                        removeColumn(props.id);
                    }}
                    size="small"
                    iconOnly
                >
                    <Icon
                        sx={{
                            fontWeight: "bold",
                            color: ({ palette: { dark } }) => dark.main,
                        }}
                    >
                        close
                    </Icon>
                </MDButton>
            </MDBox>
        </MDBox>
    );
}

export default Column;
