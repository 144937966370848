import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "assets/theme-dark/base/colors";
import { InvoiceLog } from "graphql/types/graphql";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";

interface Column {
    id: "invoiceName" | "matchId" | "matchName" | "numberOfDealsHidden" | "newDealID";
    label: string;
}

const columns: Column[] = [
    {
        id: "newDealID",
        label: "ID",
    },
    {
        id: "invoiceName",
        label: "Invoice Name",
    },
    {
        id: "matchName",
        label: "Match Name",
    },
    {
        id: "numberOfDealsHidden",
        label: "Deals Hidden",
    },
    // {
    //     id: "similarity",
    //     label: "Similarity",
    // },
];

function MatchesTable({
    matches,
    matchesIndex,
    setMatchesIndex,
}: {
    matches: InvoiceLog[];
    matchesIndex: number;
    setMatchesIndex: (value: number) => void;
}) {
    return (
        <>
            <TableContainer
                sx={{
                    color: colors.white.main,
                    height: "100%",

                    borderRadius: "0px",
                    maxHeight: "500px",
                    background: "transparent",
                }}
            >
                <Table sx={{ width: "100%" }} stickyHeader>
                    <TableHead sx={{ width: "100%", background: "transparent" }}>
                        {columns.map((column) => (
                            <TableCell align="center" sx={{ color: colors.white.main }} key={column.id}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {matches.map((row, index) => {
                            return (
                                <TableRow
                                    onClick={() => {
                                        setMatchesIndex(index);
                                    }}
                                    sx={{
                                        color: colors.white.main,

                                        "&:hover": {
                                            cursor: "pointer",
                                        },
                                        backgroundColor:
                                            matchesIndex === index
                                                ? colors.primary.main
                                                : row.insertedNow
                                                ? colors.secondary.main
                                                : "transparent",
                                    }}
                                    key={row.newDealID}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell align="center" sx={{ color: colors.white.main }} key={column.id}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <MDBox sx={{ display: "flex", marginTop: "2rem", gap: 4 }}>
                <MDBox
                    sx={{
                        width: "8rem",
                        height: "2rem",
                        backgroundColor: colors.primary.main,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography sx={{ fontSize: "1rem", color: colors.white.main, fontWeight: 600 }}>
                        Current
                    </Typography>
                </MDBox>
                <MDBox
                    sx={{
                        width: "8rem",
                        height: "2rem",
                        backgroundColor: colors.secondary.main,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 0,
                    }}
                >
                    <Typography sx={{ fontSize: "1rem", color: colors.white.main, fontWeight: 600 }}>
                        Inserted
                    </Typography>
                </MDBox>
            </MDBox>
        </>
    );
}

export default MatchesTable;
