import { CompaniesQuery, DealsQuery } from "graphql/types/graphql";

export function prepareTableDataForCompanies(dataCompanies: CompaniesQuery): {
    customColumns: string[];
    finalColumns: string[];
    finalRows: string[];
} {
    const customCols: string[] = [];
    const columns: string[] = Object.keys(dataCompanies.companies.edges[0]).filter((column: string) => {
        if (column !== "__typename" && column !== "id" && column !== "customProperties") {
            customCols.push(column);
            return true;
        } else return false;
    });

    const customColumns = Object.keys(dataCompanies.companies.edges[0].customProperties);
    const finalColumns = [...columns, ...customColumns];

    const finalRows = dataCompanies.companies.edges.map((row: any) => {
        const { customProperties, ...newObject } = row;
        Object.assign(newObject, customProperties);

        return newObject;
    });

    return {
        customColumns: customCols,
        finalColumns,
        finalRows,
    };
}

export function prepareTableDataForDeals(data: DealsQuery): {
    customColumns: string[];
    finalColumns: string[];
    finalRows: string[];
} {
    const customCols: string[] = [];
    const columns = Object.keys(data.deals.edges[0]).filter((column: string) => {
        if (column !== "__typename" && column !== "id" && column !== "customProperties") {
            customCols.push(column);
            return true;
        } else return false;
    });

    const customColumns = Object.keys(data.deals.edges[0].customProperties);
    const finalColumns = [...columns, ...customColumns];

    const finalRows = data.deals.edges.map((row: any) => {
        const { customProperties, ...newObject } = row;
        Object.assign(newObject, customProperties);

        return newObject;
    });
    return {
        customColumns: customCols,
        finalColumns,
        finalRows,
    };
}
