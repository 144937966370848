import {
    ClientsResponse,
    ConversionRatesResponse,
    CrmPipelinesResponse,
    CustomColumn,
    InstancesResponse,
    PipelineInstance,
    PipelinesDocument,
    PipelinesQuery,
    PipelinesResponse,
    Stage,
    StagesResponse,
    useConversionRatesLazyQuery,
    useCrmPipelinesLazyQuery,
    useCustomColumnsLazyQuery,
    useDeletePipelineTagMutation,
    useInstancesLazyQuery,
    useStagesLazyQuery,
    useUpsertPipelineMutation,
    useUpsertPipelineTagMutation,
} from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import confirmSnack from "utils/confirmSnack";
import { Duration } from "graphql/types/graphql";
import { v4 as uuidv4 } from "uuid";
import { boardForDurations } from "./boardForDurations";
import { boardForConversions } from "./boardForConversions";
import { boardForDeals } from "./boardForDeals";
import { pipeline } from "stream";
import enqueueError from "utils/enqueueError";
import nextValueFinder from "./nextValueFinder";
import errorDecider from "./errorDecider";
import { PageData, Pipeline } from "./types";
import buildNewPipeline from "./buildNewPipeline";
import newDurationHandler from "./newDurationHandler";
import newInstanceHandler from "./newInstanceHandler";
import newStageHandler from "./newStageHandler";

interface CurrentClient {
    cruxyId: string;
    name?: string;
}

export default function usePipelines(
    setIsLoading: (value: boolean) => void,
    data: PipelinesQuery,
    updatePageData: (object: Partial<PageData>) => void,
    pageData: PageData,
    selectedCompany: CurrentClient
) {
    const [getCrmPipes, { loading: loadingCrmPipes }] = useCrmPipelinesLazyQuery();
    const [getStages, { loading: loadingStages }] = useStagesLazyQuery();
    const [getColumns, { loading: loadingColumns }] = useCustomColumnsLazyQuery();
    const [getInstances, { loading: loadingInstances }] = useInstancesLazyQuery();

    const loadingQueries = [loadingCrmPipes, loadingStages, loadingColumns, loadingInstances];

    const [getConversions] = useConversionRatesLazyQuery({ fetchPolicy: "no-cache" });

    const [deletePipelineTag] = useDeletePipelineTagMutation();
    const [upsertPipeline] = useUpsertPipelineMutation();
    const [upsertPipelineTag] = useUpsertPipelineTagMutation();

    const {
        currentPipeline,
        newPipeline,

        pipelineIndex,
        crmPipeIndex,
        columnIndex,
        selectedColumn,
        selectedCrmPipe,
        isVisible,
        crmPipes,
        isConversionVisible,
        calculatedNewPipeline,
    } = pageData;

    async function getCrmPipesHandler() {
        try {
            const response = await getCrmPipes({ variables: { cruxyId: selectedCompany.cruxyId } });
            if (response.data.crmPipelines.length > 0) {
                return response.data.crmPipelines;
            } else {
                return "NO_CRM_PIPELINES";
            }
        } catch (error) {
            enqueueError(error);
            return "ERROR";
        }
    }

    async function getStagesHandler(id: string) {
        try {
            //setIsLoading(true);
            const response = await getStages({ variables: { pipelineId: id } });
            if (response?.data?.stages) {
                return response.data.stages;
                //setIsLoading(false);
            } else {
                return "NO_STAGES";
            }
        } catch (error: any) {
            //setIsLoading(false);
            enqueueError(error);
            return "NO_STAGES";
        }
    }

    async function getInstancesHandler(selectedColumn: CustomColumn) {
        try {
            const responseInstances = await getInstances({
                variables: {
                    columnId: selectedColumn.columnId,
                },
            });

            if (responseInstances.data) {
                return responseInstances.data.instances;
            } else {
                return responseInstances.error.message;
            }
        } catch (error: any) {
            console.log("err", error);
            setIsLoading(false);
            enqueueError(error);
        }
    }

    async function getColumnsHandler() {
        try {
            //setIsLoading(true);
            const responseColumns = await getColumns({
                variables: {
                    filter: { cruxyId: selectedCompany.cruxyId, tableId: "4a9ca6cd-c033-4a13-b23c-b1181e195b87" },
                },
            });
            if (responseColumns.data.customColumns) {
                return responseColumns.data.customColumns;
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            enqueueError(error);
        }
    }

    function removeStage(stageId: string) {
        const newStages = newPipeline.input.pipeline.schema.stages.filter((stage: any) => {
            return stage.id !== stageId;
        });
        newStages.sort((a: any, b: any) => {
            return a.orderNumber - b.orderNumber;
        });
        const finalStages = newStages.map((stage: any, index: number) => {
            return { ...stage, orderNumber: index };
        });

        const newDurations = newPipeline.input.pipeline.durations.filter((duration: any) => {
            return duration.stageId !== stageId;
        });

        updatePageData({
            newPipeline: {
                pipelineId: newPipeline.pipelineId,
                input: {
                    pipeline: {
                        durations: newDurations,
                        schema: {
                            ...newPipeline.input.pipeline.schema,
                            stages: finalStages,
                        },
                    },
                },
            },
        });

        const pipeline: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId"> = {
            durations: newDurations,
            schema: {
                ...newPipeline.input.pipeline.schema,
                stages: finalStages,
            },
        };
        boardMaker(pipeline);
    }

    function handleDuration(duration: Duration) {
        const newDurations = newPipeline.input.pipeline.durations.map((item: Duration) => {
            if (duration.pipelineInstanceId === item.pipelineInstanceId && duration.stageId === item.stageId) {
                return duration;
            } else {
                return item;
            }
        });
        updatePageData({
            newPipeline: {
                pipelineId: newPipeline.pipelineId,
                input: {
                    pipeline: {
                        durations: newDurations,
                        schema: newPipeline.input.pipeline.schema,
                    },
                },
            },
        });
    }

    function handleConversionRate(conversion: { stageId: string; conversion: number }) {
        const newStages = newPipeline.input.pipeline.schema.stages.map((stage: any) => {
            if (stage.id === conversion.stageId) {
                return {
                    id: stage.id,
                    name: stage.name,
                    orderNumber: stage.orderNumber,
                    conversionRate: conversion.conversion,
                };
            } else {
                return stage;
            }
        });
        updatePageData({
            newPipeline: {
                pipelineId: newPipeline.pipelineId,
                input: {
                    pipeline: {
                        durations: newPipeline.input.pipeline.durations,
                        schema: {
                            ...newPipeline.input.pipeline.schema,
                            stages: newStages,
                        },
                    },
                },
            },
        });
    }

    function handleStageName(value: { stageId: string; name: string }) {
        const newStages = newPipeline.input.pipeline.schema.stages.map((stage: any) => {
            if (stage.id === value.stageId) {
                return {
                    id: stage.id,
                    name: value.name,
                    orderNumber: stage.orderNumber,
                    conversionRate: stage.conversionRate,
                };
            } else {
                return stage;
            }
        });
        updatePageData({
            newPipeline: {
                pipelineId: newPipeline.pipelineId,
                input: {
                    pipeline: {
                        durations: newPipeline.input.pipeline.durations,
                        schema: {
                            ...newPipeline.input.pipeline.schema,
                            stages: newStages,
                        },
                    },
                },
            },
        });
    }

    function handleInstanceName(id: string, name: string) {
        const newInstances = newPipeline.input.pipeline.schema.pipelineInstances.map((instance: any) => {
            if (instance.id === id) {
                return {
                    id: instance.id,
                    name: name,
                    orderNumber: instance.orderNumber,
                    averageAmount: instance.averageAmount,
                };
            } else {
                return instance;
            }
        });
        updatePageData({
            newPipeline: {
                pipelineId: newPipeline.pipelineId,
                input: {
                    pipeline: {
                        durations: newPipeline.input.pipeline.durations,
                        schema: {
                            ...newPipeline.input.pipeline.schema,
                            pipelineInstances: newInstances,
                        },
                    },
                },
            },
        });
    }

    function undoChanges() {
        updatePageData({
            newPipeline: currentPipeline,
            calculatedNewPipeline: undefined,
        });
        const pipeline: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId"> = {
            ...currentPipeline.input.pipeline,
        };

        boardMaker(pipeline);
    }

    async function saveContent() {
        try {
            setIsLoading(true);
            const newDurations = newDurationHandler(newPipeline);
            const newInstances = newInstanceHandler(newPipeline);
            const newStages = newStageHandler(newPipeline);

            const newPipelineInput = {
                durations: newDurations,
                schema: {
                    pipelineInstances: newInstances,
                    stages: newStages,
                },
            };
            const response = await upsertPipeline({
                variables: {
                    input: newPipelineInput,
                    pipelineId: newPipeline.pipelineId,
                    columnId: isVisible ? selectedColumn?.columnId : data?.pipelines[pipelineIndex]?.data?.columnId,
                    basePipelineId: isVisible
                        ? selectedCrmPipe.id
                        : data?.pipelines[pipelineIndex]?.data?.basePipelineId,
                },
                update: (cache: any, { data }) => {
                    const data2 = cache.readQuery({
                        query: PipelinesDocument,
                        variables: {
                            cruxyId: selectedCompany.cruxyId,
                        },
                    });
                    const items = data2.pipelines.map((pipeline: PipelinesResponse) => {
                        if (pipeline.id === newPipeline.pipelineId) {
                            return {
                                id: pipeline.id,
                                name: pipeline.name,
                                data: {
                                    durations: data.upsertPipeline.durations,
                                    schema: data.upsertPipeline.schema,
                                    columnId: data.upsertPipeline.columnId,
                                    basePipelineId: data.upsertPipeline.basePipelineId,
                                },
                            };
                        } else {
                            return pipeline;
                        }
                    });
                    cache.writeQuery({
                        query: PipelinesDocument,
                        data: {
                            pipelines: items,
                        },
                        variables: { cruxyId: selectedCompany.cruxyId },
                    });
                },
            });
            if (response) {
                updatePageData({
                    newPipeline: newPipeline,
                    calculatedNewPipeline: undefined,
                    currentPipeline: newPipeline,
                });
                const pipeline: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId"> = {
                    ...newPipeline.input.pipeline,
                };
                boardMaker(pipeline);
                enqueueSnackbar("Successfully modified the pipeline!", {
                    variant: "success",
                });
                setIsLoading(false);
            } else {
                setIsLoading(false);
                enqueueSnackbar("Something went wrong!", {
                    variant: "error",
                });
            }
        } catch (error) {
            setIsLoading(false);
            console.log(JSON.stringify(error), error);
            enqueueError(error);
        }
    }

    async function saveNew() {
        try {
            setIsLoading(true);
            const name = "pipeline";
            const response = await upsertPipelineTag({
                variables: { name: `new ${name} `, cruxyId: selectedCompany.cruxyId },
                update: (cache: any, { data }: any) => {
                    const data2 = cache.readQuery({
                        query: PipelinesDocument,
                        variables: {
                            cruxyId: selectedCompany.cruxyId,
                        },
                    });

                    cache.writeQuery({
                        query: PipelinesDocument,
                        data: {
                            pipelines: [
                                ...data2.pipelines,
                                {
                                    __typename: "PipelineResponse",
                                    id: data.upsertPipelineTag,
                                    name: `new ${name}`,
                                    data: null,
                                },
                            ],
                        },
                        variables: { cruxyId: selectedCompany.cruxyId },
                    });
                },
            });
            if (response) {
                enqueueSnackbar("Successfully added a new pipeline!", {
                    variant: "success",
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            enqueueError(error);
            setIsLoading(false);
        }
    }

    async function deletePipeline() {
        try {
            setIsLoading(true);

            const id = currentPipeline ? currentPipeline.pipelineId : newPipeline.pipelineId;
            const response = await deletePipelineTag({
                variables: {
                    deletePipelineTagId: id,
                },
                update: (cache: any) => {
                    const data = cache.readQuery({
                        query: PipelinesDocument,
                        variables: {
                            cruxyId: selectedCompany.cruxyId,
                        },
                    });

                    const items = data.pipelines.filter((pipeline: any) => pipeline.id !== id);

                    cache.writeQuery({
                        query: PipelinesDocument,
                        data: { pipelines: items },
                        variables: { cruxyId: selectedCompany.cruxyId },
                    });
                },
            });
            if (response) {
                enqueueSnackbar("Successfully deleted the pipeline!", {
                    variant: "success",
                });
                updatePageData({
                    pipelineIndex: pipelineIndex === 0 ? 0 : pipelineIndex - 1,
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            setIsLoading(false);
            enqueueError(error);
        }
    }

    async function callForDeletePipeline() {
        const question = "Do you really want to delete this pipeline?";
        confirmSnack(() => deletePipeline(), question);
    }

    async function saveExistingTag(pipelineId: string, name: string, cruxyId: string) {
        try {
            setIsLoading(true);
            const response = await upsertPipelineTag({
                variables: { upsertPipelineTagId: pipelineId, name: name },
                update: (cache: any, { data }: any) => {
                    const data2 = cache.readQuery({
                        query: PipelinesDocument,
                        variables: {
                            cruxyId: selectedCompany.cruxyId,
                        },
                    });

                    const newItems = data2.pipelines.map((pipeline: PipelinesResponse) => {
                        if (pipeline.id === pipelineId) {
                            return {
                                ...pipeline,
                                name: name,
                            };
                        } else {
                            return pipeline;
                        }
                    });
                    cache.writeQuery({
                        query: PipelinesDocument,
                        data: {
                            pipelines: newItems,
                        },
                        variables: { cruxyId: selectedCompany.cruxyId },
                    });
                },
            });
            if (response) {
                enqueueSnackbar("Successfully updated pipeline header!", {
                    variant: "success",
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            enqueueError(error);
        }
    }

    function boardMaker(pipeline: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId">) {
        if (!pipeline) {
            updatePageData({
                board: undefined,
            });
            return;
        }
        const sortedStages = pipeline.schema.stages.slice();

        const instances = pipeline.schema.pipelineInstances.slice();
        instances.sort(function (a: any, b: any) {
            return a.averageAmount - b.averageAmount;
        });

        sortedStages.sort(function (a: any, b: any) {
            return a.orderNumber - b.orderNumber;
        });

        const totalsPerInstances = pipeline.schema.pipelineInstances.map((instance: any) => {
            return {
                id: instance.id,
                averageAmount: instance.averageAmount,
                total: pipeline.durations
                    .filter((duration: Duration) => {
                        return duration.pipelineInstanceId === instance.id;
                    })
                    .map((number: any) => {
                        return number.duration;
                    })
                    .reduce((total: number, value: any) => {
                        return total + value;
                    }),

                totalConversionRate: pipeline.durations
                    .filter((duration: any) => {
                        return duration.pipelineInstanceId === instance.id;
                    })
                    .map((number: any) => {
                        return number.conversionRate;
                    })
                    .reduce((total: number, value: any) => total * value, 1),

                totalNumberOfDeals:
                    instance.numberOfClosedWons +
                    pipeline.durations
                        .filter((duration: any) => {
                            return duration.pipelineInstanceId === instance.id;
                        })
                        .map((number: any) => {
                            return number.numberOfDeals;
                        })
                        .reduce((total: number, value: any) => {
                            return total + value;
                        }),
            };
        });

        if (isConversionVisible === 0) {
            const newBoard = boardForDurations(instances, sortedStages, pipeline, totalsPerInstances);
            updatePageData({
                board: newBoard,
            });
        }
        if (isConversionVisible === 1) {
            const newBoard = boardForConversions(
                instances,
                sortedStages,
                pipeline,
                totalsPerInstances,
                nextValueFinder
            );

            updatePageData({
                board: newBoard,
            });
        }
        if (isConversionVisible === 2) {
            const newBoard = boardForDeals(instances, sortedStages, pipeline, totalsPerInstances);

            updatePageData({
                board: newBoard,
            });
        }
    }

    function moveStageToLeft(from: number, to: number, stageId: string, stages: Stage[]) {
        const newStages = stages
            .map((stage: Stage) => {
                if (stage.id === stageId) {
                    return {
                        ...stage,
                        orderNumber: to,
                    };
                }
                if (stage.orderNumber >= to) {
                    return {
                        ...stage,
                        orderNumber: stage.orderNumber + 1,
                    };
                }
                return stage;
            })
            .sort((a: any, b: any) => {
                return a.orderNumber - b.orderNumber;
            });
        return newStages;
    }

    function moveStageToRight(from: number, to: number, stageId: string, stages: Stage[]) {
        const newStages = stages
            .map((stage: Stage) => {
                if (stage.id === stageId) {
                    return {
                        ...stage,
                        orderNumber: to,
                    };
                }
                if (stage.orderNumber <= to) {
                    return {
                        ...stage,
                        orderNumber: stage.orderNumber - 1,
                    };
                }
                return stage;
            })
            .sort((a: any, b: any) => {
                return a.orderNumber - b.orderNumber;
            });
        return newStages;
    }

    function changeOrder(from: number, to: number, newBoard: any) {
        const stages = newPipeline.input.pipeline.schema.stages;
        const stage = stages[from];

        const newStages =
            from > to ? moveStageToLeft(from, to, stage.id, stages) : moveStageToRight(from, to, stage.id, stages);

        updatePageData({
            newPipeline: {
                pipelineId: newPipeline.pipelineId,
                input: {
                    pipeline: {
                        durations: newPipeline.input.pipeline.durations,
                        schema: {
                            ...newPipeline.input.pipeline.schema,
                            stages: newStages,
                        },
                    },
                },
            },
        });

        boardMaker({
            durations: newPipeline.input.pipeline.durations,
            schema: {
                ...newPipeline.input.pipeline.schema,
                stages: newStages,
            },
        });
    }

    async function calculateConversions() {
        try {
            setIsLoading(true);
            const response = await getConversions({ variables: { pipelineId: data.pipelines[pipelineIndex].id } });

            if (response) {
                setIsLoading(false);
                const newDurations = newPipeline.input.pipeline.durations.map((item: Duration) => {
                    const conversionForDuration = response.data.conversionRates.find(
                        (conversion: ConversionRatesResponse) => {
                            return (
                                conversion.stageId === item.stageId && conversion.instanceId === item.pipelineInstanceId
                            );
                        }
                    ).conversionRate;
                    const dealsForDuration = response.data.conversionRates.find(
                        (conversion: ConversionRatesResponse) => {
                            return (
                                conversion.stageId === item.stageId && conversion.instanceId === item.pipelineInstanceId
                            );
                        }
                    ).numberOfDeals;
                    return {
                        ...item,
                        conversionRate: conversionForDuration,
                        numberOfDeals: dealsForDuration,
                    };
                });

                updatePageData({
                    calculatedNewPipeline: {
                        pipelineId: newPipeline.pipelineId,
                        input: {
                            pipeline: {
                                durations: newDurations,
                                schema: newPipeline.input.pipeline.schema,
                            },
                        },
                    },
                    newPipeline: {
                        pipelineId: newPipeline.pipelineId,
                        input: {
                            pipeline: {
                                durations: newDurations,
                                schema: newPipeline.input.pipeline.schema,
                            },
                        },
                    },
                });
                boardMaker({
                    durations: newDurations,
                    schema: newPipeline.input.pipeline.schema,
                });
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            enqueueError(error);
        }
    }

    function preparePipelineData(pipeline: PipelinesResponse) {
        if (pipeline?.id !== calculatedNewPipeline?.pipelineId) {
            updatePageData({
                newPipeline: {
                    pipelineId: pipeline.id,
                    input: {
                        pipeline: {
                            durations: pipeline.data.durations,
                            schema: {
                                pipelineInstances: pipeline.data?.schema?.pipelineInstances,
                                stages: pipeline.data.schema.stages,
                            },
                        },
                    },
                },
                currentPipeline: {
                    pipelineId: pipeline.id,
                    input: {
                        pipeline: {
                            durations: pipeline.data.durations,
                            schema: {
                                pipelineInstances: pipeline.data?.schema?.pipelineInstances,
                                stages: pipeline.data.schema.stages,
                            },
                        },
                    },
                },
            });

            boardMaker({
                durations: pipeline.data.durations,
                schema: {
                    pipelineInstances: pipeline.data?.schema?.pipelineInstances,
                    stages: pipeline.data.schema.stages,
                },
            });
        } else {
            updatePageData({
                newPipeline: calculatedNewPipeline,
                currentPipeline: {
                    pipelineId: pipeline.id,
                    input: {
                        pipeline: {
                            durations: pipeline.data.durations,
                            schema: {
                                pipelineInstances: pipeline.data?.schema?.pipelineInstances,
                                stages: pipeline.data.schema.stages,
                            },
                        },
                    },
                },
            });
            boardMaker(calculatedNewPipeline.input.pipeline);
        }
    }

    async function handleColumnsAndInstances() {
        try {
            const columnsRes = await getColumnsHandler();
            if (columnsRes) {
                if (columnsRes.length > 0) {
                    const instancesRes = await getInstancesHandler(columnsRes[columnIndex]);
                    if (typeof instancesRes !== "string" && instancesRes?.length > 0) {
                        return { columns: columnsRes, instances: instancesRes };
                    } else if (instancesRes?.length === 0) {
                        //cannot create cause no instances
                        return { columns: columnsRes, instances: null };
                    } else {
                        return { columns: columnsRes, instances: null };
                    }
                } else {
                    //cannot create cause no columns
                    return "NO_COLUMNS";
                }
            }
            //@ts-ignore
        } catch (error: GraphQLErrors) {
            //if (error[0].extensions.code === "EMPTY_COLUMN") return "NO_INSTANCE";
            console.log(error);
            return "ERROR";
        }
    }

    async function handleStages(id: string) {
        try {
            const stagesRes = await getStagesHandler(id);
            if (stagesRes.length) {
                return stagesRes;
            } else {
                //cannot create because no stages
                return "NO_STAGES";
            }
        } catch (error) {
            return "ERROR";
        }
    }

    async function setupTemplate() {
        const crmPipesRes = await getCrmPipesHandler();
        if (typeof crmPipesRes !== "string") {
            const stagesRes = await handleStages(crmPipesRes[crmPipeIndex].id);

            const colAndInstRes = await handleColumnsAndInstances();

            if (typeof colAndInstRes === "string" && colAndInstRes === "NO_COLUMNS") {
                errorDecider("NO_COLUMNS", updatePageData);
            }

            if (typeof stagesRes !== "string" && typeof colAndInstRes !== "string" && colAndInstRes) {
                if (colAndInstRes.instances === null) {
                    errorDecider("NO_INSTANCES", updatePageData);
                    updatePageData({
                        template: {
                            //@ts-ignore
                            columns: colAndInstRes.columns,
                            //@ts-ignore
                            instances: [],
                            stages: stagesRes,
                        },
                        crmPipes: crmPipesRes,
                        selectedCrmPipe: crmPipesRes[crmPipeIndex],
                        selectedColumn: colAndInstRes.columns[columnIndex],
                        newPipeline: {
                            pipelineId: data?.pipelines[pipelineIndex]?.id,
                            input: null,
                        },
                        currentPipeline: null,
                    });

                    setIsLoading(false);
                    boardMaker(null);
                    return;
                } else {
                    const myNewPipeline = buildNewPipeline(
                        {
                            //@ts-ignore
                            columns: colAndInstRes.columns,
                            //@ts-ignore
                            instances: colAndInstRes.instances,
                            stages: stagesRes,
                        },
                        pipelineIndex,
                        data
                    );
                    updatePageData({
                        template: {
                            //@ts-ignore
                            columns: colAndInstRes.columns,
                            //@ts-ignore
                            instances: colAndInstRes.instances,
                            stages: stagesRes,
                        },
                        newPipeline: myNewPipeline,
                        crmPipes: crmPipesRes,
                        selectedCrmPipe: crmPipesRes[crmPipeIndex],
                        currentPipeline: null,
                        selectedColumn: colAndInstRes.columns[columnIndex],
                    });

                    const pipeline: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId"> = {
                        durations: myNewPipeline.input.pipeline.durations,
                        schema: myNewPipeline.input.pipeline.schema,
                    };
                    boardMaker(pipeline);
                    errorDecider("CLEAN", updatePageData);
                    setIsLoading(false);
                }
            } else {
                if (typeof stagesRes === "string") {
                    updatePageData({
                        template: {
                            //@ts-ignore
                            columns: colAndInstRes.columns,
                            //@ts-ignore
                            instances: colAndInstRes.instances,
                            stages: [],
                        },
                        crmPipes: crmPipesRes,
                        selectedCrmPipe: crmPipesRes[crmPipeIndex],
                        newPipeline: {
                            pipelineId: data?.pipelines[pipelineIndex]?.id,
                            input: null,
                        },
                        currentPipeline: null,
                    });
                    setIsLoading(false);
                    errorDecider(stagesRes, updatePageData);
                    boardMaker(null);
                    return;
                }

                if (typeof colAndInstRes === "string") {
                    setIsLoading(false);
                    errorDecider(colAndInstRes, updatePageData);
                    return;
                }
                errorDecider("ERROR", updatePageData);
            }
        } else {
            console.log(crmPipesRes);
            errorDecider(crmPipesRes, updatePageData);
            setIsLoading(false);
        }
    }

    return {
        changeOrder,
        saveExistingTag,
        callForDeletePipeline,
        saveNew,
        saveContent,
        undoChanges,
        handleInstanceName,
        handleStageName,
        getInstancesHandler,
        getColumnsHandler,
        removeStage,
        handleDuration,
        handleConversionRate,
        boardMaker,
        calculateConversions,
        getCrmPipesHandler,
        getStagesHandler,
        loadingQueries,
        preparePipelineData,
        setupTemplate,
    };
}
