import { useContext, useEffect, useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Theme } from "@mui/material/styles";
import { context } from "utils/Provider";
import {
    CrmTables,
    CustomColumn,
    useApplyInvoicesMutation,
    useInvoiceLogDatesQuery,
    useInvoicesQuery,
} from "graphql/types/graphql";
import { Table } from "utils/types";
import MDTypography from "components/MDTypography";
import { Card, TextField, Typography } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useMaterialUIController } from "context";
import UploadModal from "./components/UploadModal";
import MDButton from "components/MDButton";
import colors from "assets/theme-dark/base/colors";
import Tabs from "components/Tabs/Tabs";
import Logs from "./components/Logs";
import LogsPlan from "./components/LogsPlan";
import formatDate from "./utils/formatDate";
import { linearGradientStyle } from "utils/linearGradientsStyle";

function Invoice(): JSX.Element {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const { setIsLoading, selectedCompany } = useContext(context);

    const [table, setTable] = useState<Table | null>(null);
    const [currentTable, setCurrentTable] = useState<CrmTables>();
    const [customColumns, setCustomColumns] = useState<string[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState(10);
    const [selected, setSelected] = useState<number>(0);
    const [dates, setDates] = useState<{ original: string; formatted: string }[]>([]);

    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const { error, data, loading } = useInvoicesQuery({
        variables: {
            filter: {
                cruxyId: selectedCompany.cruxyId,
            },
            limit: pageSize,
            offset: pageSize * currentPage,
        },
    });

    const {
        data: dataDateLog,
        loading: loadingDateLog,
        error: errorDateLog,
        refetch: refetchLogDates,
    } = useInvoiceLogDatesQuery({ variables: { cruxyId: selectedCompany.cruxyId } });

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setCurrentPage(0);
    }, [pageSize]);

    useEffect(() => {
        setTable(undefined);
    }, [selectedCompany]);

    useEffect(() => {
        if (data?.invoices?.edges.length === 0) {
            setTable({ columns: [], rows: [] });
            return;
        }
        if (error) console.log(error);
        if (data?.invoices?.edges.length > 0) {
            const customCols: string[] = [];

            const columns = Object.keys(data.invoices.edges[0]).filter((column: string) => {
                if (column !== "__typename" && column !== "id" && column !== "customProperties") {
                    customCols.push(column);
                    return true;
                } else return false;
            });
            setTotal(parseInt(data.invoices.totalCount));
            setCustomColumns(customCols);
            const customColumns: any = []; //Object.keys(data.invoices.edges[0].customProperties);
            const finalColumns = [...columns, ...customColumns];

            const finalRows = data.invoices.edges.map((row: any) => {
                const { customProperties, ...newObject } = row;
                Object.assign(newObject, customProperties);

                return newObject;
            });

            setTable({
                columns: finalColumns.map((item) => {
                    return { Header: item, accessor: item };
                }),
                rows: finalRows,
            });
        }
    }, [data, error]);

    useEffect(() => {
        if (errorDateLog) console.log(errorDateLog);
        if (dataDateLog?.invoiceLogDates.length > 0) {
            const newDates = dataDateLog.invoiceLogDates.map((date: string) => {
                return {
                    original: date,
                    formatted: formatDate(date),
                };
            });
            setDates(newDates);
        }
    }, [dataDateLog, errorDateLog]);

    function setNextCursor() {
        setCurrentPage(currentPage + 1);
    }

    function setPreviousCursor() {
        setCurrentPage(currentPage - 1);
    }

    return (
        <>
            <Tabs
                selected={selected}
                setSelected={setSelected}
                columns={dates}
                tabs={["Invoices", "Preview", "Logs"]}
            />
            <MDBox
                sx={{
                    height: "100%",
                    flex: 1,
                    backgroundColor: colors.background.card,
                    padding: "2rem",
                    //border: "1px solid red",
                    // display: "flex",
                    ...linearGradientStyle,
                }}
            >
                {selected === 0 && (
                    <>
                        <MDBox
                            sx={{
                                // marginTop: "1rem",
                                marginBottom: "2rem",
                                // marginLeft: "1rem",
                                // paddingRight: "1rem",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <MDTypography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "1.75rem",
                                }}
                            >
                                Invoices
                            </MDTypography>
                            <MDButton onClick={() => setOpen(true)} variant="gradient" color={"secondary"}>
                                Import
                            </MDButton>
                        </MDBox>
                        {table && (
                            <>
                                {table?.columns?.length === 0 ? (
                                    <Typography
                                        sx={{
                                            fontWeight: "600",
                                            color: colors.white.main,
                                            fontSize: "1.25rem",
                                            padding: "1.5rem",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: "600",
                                                color: colors.primary.main,
                                                fontSize: "1.25rem",
                                            }}
                                        >
                                            {selectedCompany?.name ? selectedCompany.name + " " : "Your company "}
                                        </span>
                                        doesn{"'"}t have invoice data!
                                    </Typography>
                                ) : (
                                    <DataTable
                                        currentTable={currentTable}
                                        total={total}
                                        loading={loading}
                                        table={table}
                                        nextPageHandler={setNextCursor}
                                        previousPageHandler={setPreviousCursor}
                                        pageI={parseInt(currentPage + "") + 1}
                                        setPage={setCurrentPage}
                                        pageSize={pageSize}
                                        setPageSizeData={setPageSize}
                                        currentPage={currentPage}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                {selected === 1 && <LogsPlan refetchLogDates={refetchLogDates} />}
                {selected === 2 && <Logs dates={dates} />}
            </MDBox>

            <UploadModal open={open} handleClose={handleClose} setIsLoading={setIsLoading} />
        </>
    );
}

export default Invoice;
