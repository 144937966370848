/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import borders from "assets/theme-dark/base/borders";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { borderRadius } = borders;

// types
type Types = any;

const tableHead: Types = {
    styleOverrides: {
        root: {
            // display: "block",
            // padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
            // borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
        },
    },
};

export default tableHead;
