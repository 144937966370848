import MDBox from "components/MDBox";
import colors from "assets/theme-dark/base/colors";
import { testIds } from "utils/testIds";

function Criteria(props: {
    currentSubTree: any;
    relation: string;
    alias: string;
    setSelectedSubTree: (value: any) => any;
}) {
    const { alias, currentSubTree, setSelectedSubTree, relation } = props;

    return (
        <MDBox
            data-testid={testIds.criteria}
            onClick={() => {
                if (!Array.isArray(currentSubTree)) {
                    setSelectedSubTree(currentSubTree);
                }
            }}
            sx={{
                border: "1px solid white",
                display: "flex",
                width: "100%",
                //minWidth: "200px",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.75rem",
                borderRadius: "8px",
                marginBottom: "1rem",
                //gap: 5,
                overflow: "hidden",
                height: "3.5rem",
                boxShadow:
                    relation == ""
                        ? "none"
                        : `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                "&:hover": {
                    cursor: relation !== "" ? "pointer" : "default",
                    boxShadow: "none",
                },
            }}
        >
            <MDBox sx={{ color: "white !important", fontSize: "1rem" }}>{alias}</MDBox>
        </MDBox>
    );
}

export default Criteria;
