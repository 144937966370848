import { Button } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import { ReactNode, useState } from "react";

function CustomButton({
    title,
    background,
    job,
    props,
    testId,
}: {
    title?: string;
    icon?: ReactNode;
    background: string;
    job: () => any;
    props?: any;
    testId?: string;
}) {
    return (
        <Button
            data-testid={testId}
            color="inherit"
            disableFocusRipple
            sx={{
                backgroundColor: "transparent",
                "&:hover": {
                    color: colors.dark.main,
                    backgroundColor: background,
                    textShadow: `0 0 0.125rem white, 0 0 0.125rem white`,
                    boxShadow: `0 0 0.5rem 0 ${background}`,
                },
                "&:focus": {
                    // color: colors.dark.main,
                    // textShadow: `0 0 0.125rem hsl(0 0% 100% / 0.5), 0 0 0.125rem ${background}`,
                    // boxShadow: `inset  0 0 0.5rem 0 ${background}, 0 0 0.5rem 0 ${background}`,
                    // backgroundColor: "transparent",
                    // fontWeight: 600,
                    // outline: "none",
                },

                border: "3px solid",
                color: background,
                borderColor: background,
                textShadow: `0 0 0.125rem hsl(0 0% 100% / 0.5), 0 0 0.125rem ${background}`,
                boxShadow: `inset  0 0 0.5rem 0 ${background}, 0 0 0.5rem 0 ${background}`,
                ...props,
            }}
            onClick={job}
        >
            {title}
        </Button>
    );
}

export default CustomButton;
