import { useLazyQuery } from "@apollo/client";
import { ListItemIcon, MenuItem, TextField, Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import { CustomColumn, CrmTables, useFormulasLazyQuery } from "graphql/types/graphql";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { Rule } from "utils/types";
import { v4 } from "uuid";
import { objectPropHandler } from "../utils/objectPropHandler";
import { getSubTreeById } from "../utils/getSubTreeById";
import { testIds } from "utils/testIds";

function ColumnDropdown(props: {
    columns: CustomColumn[];
    setIsLoading: (value: boolean) => void;
    setUpTree: (newTree: any, subTree?: any) => void;
    setSelectedTree: (value: any) => void;
    setSelectedRule: (value: any) => void;
    setSelectedColumn: (value: any) => void;
    selectedColumn: any;
    newColName: any;
    setNewColName: (value: string) => void;
    newRuleName: any;
    setNewRuleName: (value: string) => void;
    rules: Rule[];
    setRules: (value: Rule[]) => void;
    getRulesForColumn: (id: string) => void;
    aliasGiver: (rule: any, relation: string, alias: string, id: string) => void;
    tree: any;
}) {
    const {
        columns,
        setIsLoading,
        setUpTree,
        setSelectedTree,
        setSelectedRule,
        setSelectedColumn,
        selectedColumn,
        newColName,
        setNewColName,
        newRuleName,
        setNewRuleName,
        rules,
        setRules,
        getRulesForColumn,
        aliasGiver,
        tree,
    } = props;

    const [selectedColumnName, setSelectedColumnName] = useState<string>(columns[0].columnName);
    const [selectedRuleValue, setSelectedRuleValue] = useState<string>();

    const [isEditingColName, setIsEditingColName] = useState(false);
    const [isEditingRuleName, setIsEditingRuleName] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getRulesForColumn(columns[0].columnId);
    }, []);

    useEffect(() => {
        setSelectedRuleValue(undefined);
        setNewRuleName(undefined);
        setSelectedTree(undefined);
        setSelectedRule(undefined);
        setUpTree(undefined);
    }, [selectedColumn]);

    return (
        <MDBox sx={{ width: "100%" }}>
            <MDBox
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                    flexDirection: { xxl: "row", lg: "row", md: "row", sm: "row", xs: "column" },
                }}
            >
                <TextField
                    data-testid={testIds.columnDropDown}
                    size="small"
                    sx={{
                        width: { xxl: "48%", lg: "48%", md: "48%", sm: "48%", xs: "100%" },
                        marginBottom: "1rem",
                    }}
                    value={selectedColumnName}
                    select
                    label="Column"
                >
                    {columns.map((option: CustomColumn) => (
                        <MenuItem
                            onClick={() => {
                                getRulesForColumn(option.columnId);
                                setSelectedColumnName(option.columnName);
                                setSelectedColumn(option);
                                setNewColName(option.columnName);
                            }}
                            key={option.columnId}
                            value={option.columnName}
                        >
                            {option.columnName}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    inputProps={{ "data-testid": testIds.formulaDropDown }}
                    size="small"
                    sx={{ width: { xxl: "48%", lg: "48%", md: "48%", sm: "48%", xs: "100%" }, marginBottom: "1rem" }}
                    select
                    value={selectedRuleValue}
                    label="Rule"
                >
                    <MenuItem
                        disableGutters={true}
                        sx={{
                            backgroundColor: colors.secondary.main,
                            textAlign: "center",
                            color: colors.white.main,
                            fontWeight: "600",
                            fontSize: "1.25rem",
                            marginBottom: "1rem",
                            "&:hover": {
                                backgroundColor: colors.secondary.main,
                            },
                        }}
                        onClick={() => {
                            const newRule = {
                                id: "newRule",
                                columnId: selectedColumn.id,
                                valueFormula: "New Rule",
                                conditionFormula: {
                                    id: v4(),
                                    alias: "C",
                                    LESS_THAN: [
                                        {
                                            value: "A.New Rule",
                                            isColumn: true,
                                        },
                                        {
                                            value: 100,
                                            isColumn: false,
                                        },
                                    ],
                                },
                            };
                            setUpTree(newRule.conditionFormula);
                            setSelectedTree(newRule.conditionFormula);
                            setSelectedRule(newRule);
                            setSelectedRuleValue("New Rule");
                            setNewRuleName("New Rule");
                        }}
                        value={"New Rule"}
                    >
                        {/* <ListItemIcon>
                            <AddIcon sx={{ color: colors.white.main }} fontSize="medium" />
                        </ListItemIcon> */}
                        New Rule
                    </MenuItem>
                    {rules?.map((option: any) => (
                        <MenuItem
                            onClick={() => {
                                setSelectedRuleValue(option.valueFormula);
                                setNewRuleName(option.valueFormula);
                                setSelectedTree(option.conditionFormula);
                                setSelectedRule(option);
                                const newTree = option.conditionFormula;
                                const aliasedTree = aliasGiver(
                                    newTree[objectPropHandler(newTree)],
                                    objectPropHandler(newTree),
                                    "C",
                                    newTree.id
                                );
                                const subAliasedTree = getSubTreeById(newTree, aliasedTree, newTree);

                                setUpTree(option.conditionFormula, subAliasedTree);
                            }}
                            key={option.id}
                            value={option.valueFormula}
                        >
                            {option.valueFormula}
                        </MenuItem>
                    ))}
                </TextField>
            </MDBox>
            <MDBox
                sx={{
                    height: "6rem",
                    visibility: tree ? "visible" : "hidden",
                }}
            >
                {isEditingColName ? (
                    <ClickAwayListener onClickAway={() => setIsEditingColName(false)}>
                        <TextField
                            inputProps={{ "data-testid": testIds.columnNameInput }}
                            variant="standard"
                            autoFocus
                            value={newColName}
                            sx={{
                                height: "3rem",
                                input: {
                                    fontSize: "1.75rem",

                                    fontWeight: 600,
                                    padding: 0,
                                    // textAlign: "center",
                                },
                            }}
                            // InputProps={{
                            //     disableUnderline: true,
                            // }}
                            onChange={(e: any) => setNewColName(e.target.value.replace(" ", "_").toUpperCase())}
                        />
                    </ClickAwayListener>
                ) : (
                    <Typography
                        data-testid={testIds.columnName}
                        sx={{
                            fontSize: { xxl: "1.75rem", lg: "1.75rem", md: "1.5rem", xs: "1.25rem" },
                            color: colors.white.main,
                            fontWeight: "600",
                            height: "3rem",
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() => setIsEditingColName(true)}
                    >
                        {newColName}
                    </Typography>
                )}
                {isEditingRuleName ? (
                    <ClickAwayListener onClickAway={() => setIsEditingRuleName(false)}>
                        <TextField
                            inputProps={{ "data-testid": testIds.ruleNameInput }}
                            sx={{
                                input: {
                                    fontSize: "1.5rem",
                                    color: colors.primary.main,
                                    fontWeight: 600,
                                    padding: 0,
                                    // textAlign: "center",
                                },
                            }}
                            variant="standard"
                            value={newRuleName}
                            autoFocus
                            onChange={(e: any) => setNewRuleName(e.target.value)}
                        />
                    </ClickAwayListener>
                ) : (
                    <Typography
                        data-testid={testIds.ruleName}
                        sx={{
                            color: colors.primary.main,
                            fontSize: "1.5rem",
                            fontWeight: "600",
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() => setIsEditingRuleName(true)}
                    >
                        {newRuleName}
                    </Typography>
                )}
            </MDBox>
        </MDBox>
    );
}

export default ColumnDropdown;
