import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarDesktopMenu,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator } from "context";
import MDSwitch from "components/MDSwitch";
import { Box, Divider, TextField, Tooltip } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { context } from "utils/Provider";
import { CRUXY_ADMIN, CRUXY_USER } from "common/names";
import { ClientsResponse } from "graphql/types/graphql";
import { testIds } from "utils/testIds";

// Declaring prop types for DashboardNavbar
interface Props {
    absolute?: boolean;
    light?: boolean;
    isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
    const { companies, user, selectedCompany, setSelectedCompany } = useContext(context);

    const [navbarType, setNavbarType] = useState<"fixed" | "absolute" | "relative" | "static" | "sticky">();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
    const route = useLocation().pathname.split("/").slice(1);

    const [menu, setMenu] = useState(null);

    const openMenu = (event: any) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const { background } = colors;

    const [age, setAge] = useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Render the notifications menu

    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }: { palette: any; functions: any }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            {companies.map((company: ClientsResponse) => (
                <MenuItem
                    key={company?.name}
                    onClick={() => {
                        closeMenu();
                        setSelectedCompany({ cruxyId: company.cruxyId, name: company.name });
                    }}
                >
                    {company?.name}
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <AppBar
            data-testid="appBarId"
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            <Toolbar sx={{ ...navbarContainer }}>
                <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                    <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
                        <Icon fontSize="medium" sx={iconsStyle}>
                            {miniSidenav ? "menu_open" : "menu"}
                        </Icon>
                    </IconButton>
                </MDBox>

                {(user?.role === CRUXY_ADMIN || user?.role === CRUXY_USER) && (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <MDBox display="flex">
                            <MDButton
                                data-testid={testIds.companiesMenu}
                                variant={menu ? "contained" : "outlined"}
                                sx={{ width: "10rem", backgroundColor: "transparent" }}
                                onClick={openMenu}
                            >
                                {selectedCompany?.name}&nbsp;
                                <Icon>keyboard_arrow_down</Icon>
                            </MDButton>
                            {renderMenu}
                        </MDBox>
                        <Tooltip title="Toggle Editor">
                            <MDBox color={light ? "white" : "inherit"}>
                                <MDSwitch size="large" color="primary" />
                            </MDBox>
                        </Tooltip>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

export default DashboardNavbar;
