import { MenuItem, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import { CrmTables } from "graphql/types/graphql";
import { testIds } from "utils/testIds";
import searchChange from "../utils/searchChange";
import { CompanyFilters, dealFilters } from "../utils/filters";

interface Props {
    selected: number;
    currentTable: CrmTables;
    crmTables: CrmTables[];
    setCurrentTable: (value: CrmTables) => void;
    debounceRef: any;
    filterBy: string;
    setDealName: (value: string) => void;
    setFiscalYear: (value: string) => void;
    setType: (value: string) => void;
    setCountry: (value: string) => void;
    setName: (value: string) => void;
    setFilterBy: (value: string) => void;
}

function Header(props: Props) {
    const {
        selected,
        currentTable,
        crmTables,
        setCurrentTable,
        debounceRef,
        filterBy,
        setDealName,
        setFiscalYear,
        setFilterBy,
        setType,
        setCountry,
        setName,
    } = props;

    function filterHandler() {
        return currentTable.name === "Deals" ? dealFilters : CompanyFilters;
    }

    return (
        <MDBox
            sx={{
                display: selected !== 2 ? "flex" : "none",
                justifyContent: "space-between",
                position: "relative",
                alignItems: "center",
                height: "6rem",
            }}
            p={3}
        >
            <TextField
                inputProps={{ "data-testid": testIds.crmTableInput }}
                size="small"
                sx={{
                    width: "15%",
                    minWidth: "140px",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                }}
                value={currentTable.name}
                id="outlined-select-currency"
                select
                label="Table"
            >
                {crmTables.map((table: CrmTables) => {
                    return (
                        <MenuItem
                            data-testid={testIds.menuOption}
                            key={table.id}
                            onClick={() => {
                                setCurrentTable(table);
                            }}
                            value={table.name}
                        >
                            {table.name}
                        </MenuItem>
                    );
                })}
            </TextField>
            {selected === 0 && (
                <MDBox
                    sx={{
                        display: "flex",
                        width: "50rem",
                        alignItems: "center",
                        height: "6rem",
                        position: "relative",
                        justifyContent: "flex-end",
                        gap: 2,
                    }}
                >
                    <TextField
                        size="small"
                        label="Filter Value"
                        sx={{ width: "20rem" }}
                        onChange={(event: any) => {
                            searchChange(
                                event,
                                debounceRef,
                                filterBy,
                                setDealName,
                                setFiscalYear,
                                setType,
                                setCountry,
                                setName
                            );
                        }}
                    />
                    <TextField
                        size="small"
                        sx={{
                            width: "15%",
                            minWidth: "140px",
                            marginBottom: "1rem",
                            marginTop: "1rem",
                        }}
                        //value={currentTable.name}
                        id="outlined-select-currency"
                        select
                        label="Filter By"
                    >
                        {filterHandler().map((filter: string) => {
                            return (
                                <MenuItem
                                    key={filter}
                                    onClick={() => {
                                        setFilterBy(filter);
                                    }}
                                    value={filter}
                                >
                                    {filter}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </MDBox>
            )}
        </MDBox>
    );
}

export default Header;
