import { MenuItem, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Field, FieldProps, Form, Formik } from "formik";
import { PipelinesResponse, useRecalculateExpectationsMutation } from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import { useCallback, useContext, useEffect } from "react";
import { context } from "utils/Provider";

interface Props {
    pipelines: PipelinesResponse[];
    setIndex: (value: number) => void;
    instances: any;
    index: number;
    recalculateExpectationHandler: (input: any) => void;
}

function convertObjectPropertiesToNumbers(obj: any) {
    const result = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            const numericValue = parseFloat(value); // or use parseInt() if you want integers
            //@ts-ignore
            result[key] = numericValue;
        }
    }

    return result;
}

function Inputs(props: Props) {
    const { pipelines, setIndex, instances, recalculateExpectationHandler } = props;

    return (
        <MDBox
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",

                // position: "absolute",
                // right: "0%",
                // bottom: 0,
                transform: "translate(0%,0%)",
            }}
        >
            <Formik
                initialValues={instances}
                enableReinitialize
                onSubmit={async (values) => {
                    recalculateExpectationHandler(convertObjectPropertiesToNumbers(values));
                }}
            >
                <Form style={{ width: "100%" }}>
                    {Object.keys(instances).map((item, index) => {
                        return (
                            <Field key={item} sx={{ width: "100%" }} name={item}>
                                {(props: FieldProps<string, any>) => {
                                    return (
                                        <TextField
                                            size="small"
                                            type="number"
                                            sx={{ width: "100%", marginBottom: "1.5rem" }}
                                            name={item}
                                            label={item}
                                            color="secondary"
                                            value={props.field.value}
                                            onChange={(e) => {
                                                props.form.setFieldValue(props.field.name, e.target.value, true);
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                        );
                    })}
                    <MDButton type="submit" variant="gradient" color="info" size="large" fullWidth>
                        Calculate
                    </MDButton>
                </Form>
            </Formik>
        </MDBox>
    );
}

export default Inputs;
