import TreeView from "@mui/lab/TreeView";
import React from "react";
import treeMaker from "./treeMaker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { objectPropHandler } from "./objectPropHandler";
import { testIds } from "utils/testIds";

interface TreeComponentProps {
    expanded: string[];
    handleToggle: (event: React.SyntheticEvent, nodes: string[]) => void;
    tree: any; // Adjust the type of your tree data
    setSelectedSubTree: (subTree: any) => void; // Adjust the type as needed
}

const TreeComponent: any = ({ expanded, handleToggle, tree, setSelectedSubTree }: TreeComponentProps) => {
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
                overflowY: "auto",
                flexGrow: 1,
                color: "white !important",
            }}
            expanded={expanded}
            onNodeToggle={handleToggle}
        >
            {treeMaker(tree, setSelectedSubTree, "C")}
        </TreeView>
    );
};

export default TreeComponent;
