import { enqueueSnackbar } from "notistack";

export default function enqueueError(error: any) {
    if (error?.extensions?.code !== "INTERNAL_SERVER_ERROR") {
        enqueueSnackbar(error.message, { variant: "error" });
    } else {
        console.log(error);
        enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
}
