import { ClickAwayListener, Theme, Typography } from "@mui/material";
import CustomButton from "components/CustomButton";
import MDBox from "components/MDBox";
import { testIds } from "utils/testIds";
import MyBoard from "./Board";
import Header from "./Header";
import colors from "assets/theme-dark/base/colors";
import { PageData } from "../utils/types";
import { Duration, PipelinesQuery, PipelinesResponse } from "graphql/types/graphql";

interface Props {
    pageData: PageData;
    getStagesHandler: (id: string) => void;
    updatePageData: (object: Partial<PageData>) => void;
    undoChanges: () => void;
    saveChanges: () => void;
    boardMaker: (value: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId">) => void;
    handleDuration: (duration: Duration) => void;
    handleConversionRate: (conversion: { stageId: string; conversion: number }) => void;
    handleStageName: (value: { stageId: string; name: string }) => void;
    handleInstanceName: (id: string, name: string) => void;
    removeStage: (stageId: string) => void;
    changeOrder: (stage1: number, stage2: number, newBoard: any) => void;
    calculateConversions: () => Promise<void>;
    setupTemplate: () => void;
}

function Table(props: Props) {
    const {
        pageData,
        boardMaker,
        handleDuration,
        handleConversionRate,
        handleStageName,
        handleInstanceName,
        removeStage,
        changeOrder,
        calculateConversions,
        updatePageData,
        getStagesHandler,
        undoChanges,
        saveChanges,
        setupTemplate,
    } = props;
    const { currentPipeline, board, newPipeline, currentlyEditing, isVisible, parameterError } = pageData;
    return (
        <>
            <>
                <MDBox
                    sx={{
                        margin: `0 0px`,
                        paddingRight: { xxl: "3rem", lg: "3rem", md: "3rem", xs: "1.5rem" },
                        paddingLeft: { xxl: "3rem", lg: "3rem", md: "3rem", xs: "1.5rem" },
                    }}
                    m={2}
                    width="100%"
                >
                    <Header
                        pageData={pageData}
                        updatePageData={updatePageData}
                        pipelineChanged={JSON.stringify(newPipeline) === JSON.stringify(currentPipeline)}
                        getStagesHandler={getStagesHandler}
                        undoChanges={undoChanges}
                        saveChanges={saveChanges}
                        setupTemplate={setupTemplate}
                    />
                </MDBox>
                {board && (
                    <>
                        <ClickAwayListener
                            onClickAway={() => {
                                if (currentlyEditing !== "") {
                                    const pipeline = {
                                        durations: newPipeline.input.pipeline.durations,
                                        schema: newPipeline.input.pipeline.schema,
                                    };

                                    boardMaker(pipeline);
                                    updatePageData({
                                        currentlyEditing: "",
                                    });
                                }
                            }}
                        >
                            <MDBox
                                //border="1px solid white"
                                paddingLeft={"2rem"}
                                my={0}
                                sx={({
                                    palette: { light, background },
                                    functions: { pxToRem },
                                    borders: { borderRadius },
                                }: Theme | any) => ({
                                    marginBottom: { xxl: "1rem", lg: "1rem", md: "2rem", xs: "2rem" },
                                    "& .react-kanban-column": {
                                        backgroundColor: "transparent",
                                        width: "150px",
                                        margin: `0 10px`,
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        marginBottom: 2,
                                        borderRadius: "1rem",
                                        position: "relative",
                                        overflow: "visible",
                                        whiteSpace: "normal !important",
                                        textOverflow: "clip !important",
                                        background: "transparent",
                                        backdropFilter: "blur(2px)",
                                        WebkitBackdropFilter: "blur(2px)",
                                        boxShadow:
                                            "rgba(212, 189, 109, 0.25) 1px 4px 5px 1px, rgba(212, 189, 109, 0.15) 0px -1px 1px 0px, rgba(212, 189, 109, 0.1) 0px 2px 5px 0px",
                                    },
                                })}
                            >
                                <MyBoard
                                    pageData={pageData}
                                    updatePageData={updatePageData}
                                    handleDuration={handleDuration}
                                    handleConversionRate={handleConversionRate}
                                    handleStageName={handleStageName}
                                    handleInstanceName={handleInstanceName}
                                    removeStage={removeStage}
                                    changeOrder={changeOrder}
                                />
                            </MDBox>
                        </ClickAwayListener>
                        <CustomButton
                            testId={testIds.pipelineApplyBtn}
                            props={{
                                minWidth: "8rem",
                                marginLeft: "2.625rem",
                                bottom: 0,
                                marginBottom: "2rem",
                                position: "relative",
                                marginTop: "auto",
                                width: "15%",
                                fontSize: "1rem",
                                fontWeight: 600,
                                visibility:
                                    !isVisible && JSON.stringify(newPipeline) === JSON.stringify(currentPipeline)
                                        ? "visible"
                                        : "hidden",
                            }}
                            background={colors.primary.main}
                            title="Apply"
                            job={calculateConversions}
                        />
                    </>
                )}
            </>
            {parameterError.length > 0 && (
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: { xxl: "1.5rem", lg: "1.5rem", md: "1.5rem", xs: "1.25rem" },
                        color: colors.white.main,
                        paddingTop: "1rem",
                        paddingLeft: { xxl: "3rem", lg: "3rem", md: "3rem", xs: "1.5rem" },
                        paddingRight: { xxl: "3rem", lg: "3rem", md: "3rem", xs: "1.5rem" },
                    }}
                >
                    {parameterError}
                </Typography>
            )}
        </>
    );
}

export default Table;
