import { Duration, PipelineInstance, PipelinesResponse, Stage } from "graphql/types/graphql";

export function boardForDurations(
    instances: PipelineInstance[],
    sortedStages: Stage[],
    pipeline: Omit<Omit<PipelinesResponse["data"], "columnId">, "basePipelineId">,
    totalsPerInstances: any
) {
    return {
        columns: [
            {
                id: "instances",
                title: "Appendix",
                cards: [
                    ...instances.map((instance: any) => {
                        return {
                            id: instance.id,
                            template: instance.name,
                            value2: instance.name,
                            value3: instance.name,
                            column: "instances",
                            isConversion: false,
                            isFirst: false,
                        };
                    }),
                ],
            },

            ...sortedStages.map((stage: any) => {
                return {
                    id: stage.id,
                    title: stage.name,
                    cards: [
                        ...pipeline.durations
                            .filter((duration: Duration) => duration.stageId === stage.id)
                            .map((duration: any) => {
                                return {
                                    pipelineInstanceId: instances.find((item: any) => {
                                        return duration.pipelineInstanceId === item.id;
                                    }).id,
                                    template: duration.duration,
                                    value2: duration.conversionRate,
                                    value3: duration.numberOfDeals,
                                    column: duration.stageId,
                                    stageId: duration.stageId,
                                    isConversion: false,
                                    orderNumber: instances.find((item: any) => {
                                        return duration.pipelineInstanceId === item.id;
                                    }).orderNumber,
                                    isFirst: false,
                                };
                            })
                            .sort(function (a: any, b: any) {
                                const x = instances.find((item: PipelineInstance) => {
                                    return item.id === a.pipelineInstanceId;
                                });

                                const y = instances.find((item: PipelineInstance) => {
                                    return item.id === b.pipelineInstanceId;
                                });

                                return x.averageAmount - y.averageAmount;
                            }),
                    ],
                };
            }),
            {
                id: "total",
                title: "Overall",
                cards: [
                    ...instances.map((instance: any) => {
                        return {
                            id: instance.id + "total",
                            template: totalsPerInstances.find((total: any) => {
                                return total.id === instance.id;
                            }).total,
                            value2: totalsPerInstances.find((total: any) => {
                                return total.id === instance.id;
                            }).totalConversionRate,
                            value3: totalsPerInstances.find((total: any) => {
                                return total.id === instance.id;
                            }).totalNumberOfDeals,
                            column: "total",
                            isConversion: false,
                            isFirst: false,
                        };
                    }),
                ],
            },
        ],
    };
}
