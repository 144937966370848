import MDBox from "components/MDBox";
import { MenuItem, TextField } from "@mui/material";

import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";

import { CrmPipelinesResponse, CustomColumn, InstancesResponse, StagesResponse } from "graphql/types/graphql";

import { testIds } from "utils/testIds";
import { PageData } from "../../utils/types";
import CustomButton from "components/CustomButton";
import colors from "assets/theme-dark/base/colors";

function Header(props: {
    pipelineChanged: boolean;
    undoChanges: () => void;
    saveChanges: () => void;
    getStagesHandler: (id: string) => void;
    setupTemplate: () => void;
    pageData: PageData;
    updatePageData: (object: Partial<PageData>) => void;
}): JSX.Element {
    const { pipelineChanged, undoChanges, saveChanges, setupTemplate, pageData, updatePageData } = props;
    const [controller] = useMaterialUIController();
    const { currentPipeline, columnIndex, template, isVisible, crmPipes, parameterError, fatalError, newPipeline } =
        pageData;

    const [formValue, setFormValue] = useState("");
    const handeSetFormValue = ({ currentTarget }: any) => setFormValue(currentTarget.value);

    const DropDowns = () => {
        return (
            <>
                <TextField
                    inputProps={{ "data-testid": testIds.instancesOfDropdown }}
                    value={template?.columns[columnIndex]?.columnName}
                    size="small"
                    sx={{
                        width: { xxl: "35%", lg: "35%", md: "35%", xs: "100%" },
                        minWidth: "12rem",
                        marginTop: { xxl: "0", lg: "0", md: "0", xs: "2rem" },
                    }}
                    select
                    label="Instances Of"
                >
                    {template?.columns.map((option: CustomColumn, index: number) => (
                        <MenuItem
                            key={option.columnId}
                            value={option.columnName}
                            onClick={() =>
                                updatePageData({
                                    columnIndex: index,
                                })
                            }
                        >
                            {option.columnName}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    inputProps={{ "data-testid": testIds.stagesOfDropdown }}
                    value={crmPipes[columnIndex]?.name}
                    size="small"
                    sx={{
                        marginTop: { xxl: "0", lg: "0", md: "0", xs: "1rem" },
                        width: { xxl: "35%", lg: "35%", md: "35%", xs: "100%" },
                        minWidth: "12rem",
                        pointerEvents: "auto",
                    }}
                    select
                    label="Stages Of"
                >
                    {crmPipes?.map((option: CrmPipelinesResponse, index: number) => (
                        <MenuItem
                            key={option.id}
                            value={option.name}
                            onClick={() => {
                                updatePageData({
                                    crmPipeIndex: index,
                                });
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </>
        );
    };

    return (
        <>
            {
                <MDBox
                    height="6rem"
                    width="100%"
                    marginTop="1rem"
                    gap={2}
                    marginBottom="1rem"
                    position="relative"
                    sx={{
                        padding: 0,
                        marginBottom: { xxl: "1rem", lg: "1rem", md: "4rem", xs: "6rem" },
                    }}
                >
                    <MDBox
                        sx={{
                            width: "100%",
                            display: { xxl: "flex", lg: "flex", md: "flex", xs: "block" },
                            //flexDirection: "row",
                            alignItems: "center",
                            visibility: isVisible ? "visible" : "hidden",
                            gap: 2,
                            //border: "1px solid white",
                        }}
                    >
                        {template?.columns?.length > 0 && DropDowns()}
                    </MDBox>

                    <MDBox
                        sx={{
                            display: "flex",
                            flexDirection: { xxl: "row", lg: "row", md: "row", xs: "column" },
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            //border: "1px solid white",
                        }}
                    >
                        <MDBox
                            sx={{
                                display: (parameterError + fatalError).length > 0 || pipelineChanged ? "none" : "flex",
                                justifyContent: "center",
                                //border: "1px solid white",
                                flexDirection: { xxl: "row", lg: "row", md: "row", xs: "row" },
                                gap: 10,
                                opacity: !pipelineChanged ? "visible" : "hidden",
                                visibility: !pipelineChanged ? "visible" : "hidden",
                            }}
                        >
                            <CustomButton
                                testId={testIds.pipelineUndoBtn}
                                title="Undo"
                                background={colors.warning.main}
                                job={() => {
                                    if (currentPipeline === null) {
                                        setupTemplate();
                                    } else {
                                        undoChanges();
                                    }
                                }}
                                props={{
                                    width: "8rem",
                                    zIndex: 0,
                                    display: currentPipeline ? "block" : "none",
                                }}
                            />
                            <CustomButton
                                testId={testIds.pipelineSaveBtn}
                                title="Save"
                                background={colors.success.main}
                                job={() => {
                                    saveChanges();
                                }}
                                props={{
                                    width: "8rem",
                                    zIndex: 0,
                                    display:
                                        JSON.stringify(currentPipeline) !== JSON.stringify(newPipeline)
                                            ? "block"
                                            : "none",
                                }}
                            />
                        </MDBox>
                    </MDBox>
                </MDBox>
            }
        </>
    );
}

export default Header;
