import Pipelines from "layouts/applications/pipelines/Pipelines";
import CrmData from "layouts/applications/CRM data/CrmData";
import Invoice from "layouts/applications/invoice";
import HowAmIProjected from "layouts/applications/how am i projected";
import ManageUsers from "layouts/applications/manage users";

import TableViewIcon from "@mui/icons-material/TableView";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import BarChartIcon from "@mui/icons-material/BarChart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TableChartIcon from "@mui/icons-material/TableChart";
import FunctionsIcon from "@mui/icons-material/Functions";
import GroupIcon from "@mui/icons-material/Group";
import { CLIENT_ADMIN, CLIENT_USER, CRUXY_ADMIN, CRUXY_USER } from "common/names";
import PublicIcon from "@mui/icons-material/Public";
import { WHY, WHERE } from "common/constants";
import Where from "layouts/applications/where";
import Why from "layouts/applications/why";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const iconStyle = { width: "1.5rem", height: "1.5rem" };

const routes = [
    {
        type: "collapse",
        name: "Pipelines",
        key: "pipelines",
        route: "/pipelines",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],

        component: (key: string) => <Pipelines key={key} />,

        icon: (color: string) => <ViewWeekIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "CRM Data",
        key: "crm-tables",
        route: "/crm-tables",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
        component: <CrmData />,
        icon: (color: string) => <TableViewIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Invoice Data",
        key: "invoice-table",
        route: "/invoice-table",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
        component: (key: string) => <Invoice key={key} />,
        icon: (color: string) => <ReceiptIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },
    // {
    //     type: "collapse",
    //     name: "Match Logs",
    //     key: "match-logs",
    //     route: "/match-logs",
    //     availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
    //     component: <Wizard />,
    //     icon: <JoinInnerIcon sx={iconStyle} />,
    //     noCollapse: true,
    // },
    {
        type: "collapse",
        name: "Where Am I?",
        key: "where-am-i",
        route: "/where-am-i",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
        component: <Where />,
        icon: (color: string) => <BarChartIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },

    {
        type: "collapse",
        name: "Why?",
        key: "why",
        route: "/why",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
        component: <Why />,
        icon: (color: string) => <TableChartIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "How Am I Projected?",
        key: "expectation-reality",
        route: "/expectation-reality",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
        component: <HowAmIProjected />,
        icon: (color: string) => <FunctionsIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },
    // {
    //     type: "collapse",
    //     name: "Forog A Fold",
    //     key: "forog-a-fold",
    //     route: "/forog-a-fold",
    //     availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER],
    //     component: <ForogAFold />,
    //     icon: (color: string) => <PublicIcon sx={{ ...iconStyle, color: color }} />,
    //     noCollapse: true,
    // },
    { type: "divider", key: "divider-1", availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN, CLIENT_USER] },
    {
        type: "collapse",
        name: "Manage Users",
        key: "manage-users",
        route: "/manage-users",
        availableFor: [CRUXY_ADMIN, CRUXY_USER, CLIENT_ADMIN],
        component: <ManageUsers />,
        icon: (color: string) => <GroupIcon sx={{ ...iconStyle, color: color }} />,
        noCollapse: true,
    },
];

export default routes;
