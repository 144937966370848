import { Checkbox, MenuItem, TextField, Typography } from "@mui/material";

import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import colors from "assets/theme-dark/base/colors";
import SubmitAddButton from "./SubmitAddButton";
import SubmitEditButton from "./SubmitEditButton";
import { CrmTables } from "graphql/types/graphql";
import { scrollbar } from "utils/scrollBar";
import { testIds } from "utils/testIds";

const operators = [
    {
        value: "EQUAL",
        label: "equals",
    },
    {
        value: "NOT_EQUAL",
        label: "not equal",
    },
    {
        value: "LESS_OR_EQUAL",
        label: "less or equal",
    },
    {
        value: "GREATER_OR_EQUAL",
        label: "greater or equal",
    },
    {
        value: "LESS_THAN",
        label: "less than",
    },
    {
        value: "GREATER_THAN",
        label: "greater than",
    },
    {
        value: "CONTAINS",
        label: "contains",
    },
    // {
    //     value: "REGEX",
    //     label: "like(regex)",
    // },
];

const relationTypes = [
    {
        value: "AND",
        label: "AND",
    },
    {
        value: "OR",
        label: "OR",
    },
];

const styles = {
    textfield: {
        width: { xxl: "48%", lg: "48%", md: "48%", xs: "100%" },
    },
    inputProps: {
        style: {
            //fontSize: "1rem",
        },
    },
};

function InputFields(props: {
    subTree: any;
    relation: string;
    tree: any;
    selectedSubTree: any;
    setTree: (value: any) => void;
    setUpTree: (value: any) => void;
    isEditing: boolean;
    crmColumns: string[];
    tables: CrmTables[];
    variableOptions1: string[];
    variableOptions2: string[];
    tableOption1: CrmTables;
    tableOption2: CrmTables;
    setTableOption1: (value: CrmTables) => void;
    setTableOption2: (value: CrmTables) => void;
    currentTable: CrmTables;
    aliasGiver: (rule: any, relation: string, alias: string, id: string) => any;
}) {
    const {
        subTree,
        relation,
        tree,
        setTree,
        selectedSubTree,
        setUpTree,
        isEditing,
        tables,
        variableOptions1,
        variableOptions2,
        tableOption1,
        tableOption2,
        setTableOption1,
        setTableOption2,
        currentTable,
        aliasGiver,
    } = props;
    const [isConstant, setIsConstant] = useState(false);
    const [isNumber, setIsNumber] = useState(true);

    const [variable1, setVariable1] = useState<string>();
    const [variable2, setVariable2] = useState<string | number | undefined>();
    const [operator, setOperator] = useState<string>();
    const [relationOperator, setRelationOperator] = useState<string>();

    useEffect(() => {
        setVariable2(undefined);
    }, [isNumber]);

    return (
        <MDBox
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: { xxl: "95%", lg: "95%", md: "95%", xs: "100%" },
            }}
        >
            <MDBox
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xxl: "row", lg: "row", md: "row", xs: "column" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: { xxl: 0, lg: 0, md: 0, xs: 1 },
                }}
            >
                {currentTable.name === "Deals" ? (
                    <MDBox sx={{ ...styles.textfield, position: "relative" }}>
                        <TextField
                            data-testid={testIds.table1Input}
                            //inputProps={{ "data-testid": testIds.table1Input }}
                            sx={{ width: "100%" }}
                            select
                            label="Table"
                            value={tableOption1.name}
                        >
                            {tables.map((option: CrmTables) => (
                                <MenuItem value={option.name} onClick={() => setTableOption1(option)} key={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/* <div
                            style={{
                                width: "100%",
                                height: "110%",
                                backgroundColor: colors.background.card,
                                position: "absolute",
                                top: "-10%",
                                opacity: 0.75,
                                zIndex: 999,
                                display: tableOption2.name === "Companies" ? "block" : "none",
                            }}
                        /> */}
                    </MDBox>
                ) : (
                    <Typography sx={{ width: "48%", fontWeight: 600, color: colors.white.main, fontSize: "1.25rem" }}>
                        Fields of:
                        <span
                            style={{
                                marginLeft: "0.5rem",
                                color: colors.secondary.main,
                                fontWeight: 600,
                                fontSize: "1.25rem",
                            }}
                        >
                            {currentTable.name}
                        </span>
                    </Typography>
                )}
                <TextField
                    //size="small"
                    data-testid={testIds.variableInput1}
                    //inputProps={{ "data-testid": testIds.variableInput1 }}
                    InputProps={{ ...styles.inputProps, ...scrollbar }}
                    sx={{ ...styles.textfield, ...scrollbar }}
                    id="outlined-select-currency"
                    select
                    SelectProps={{
                        ...scrollbar,
                        MenuProps: {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                                ...scrollbar,
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: "200px",
                                    //...scrollbar,
                                },
                            },
                        },
                    }}
                    label="Variables"
                >
                    {variableOptions1.map((option) => (
                        <MenuItem
                            onClick={() => setVariable1(tableOption1.name.toUpperCase() + "." + option)}
                            key={option}
                            value={option}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </MDBox>
            <MDBox
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: { xxl: "row", lg: "row", md: "row", xs: "column" },
                    gap: { xxl: 0, lg: 0, md: 0, xs: 1 },
                }}
            >
                <TextField
                    //size="small"
                    data-testid={testIds.operatorInput}
                    //inputProps={{ "data-testid": testIds.operatorInput }}
                    onChange={(e: any) => setOperator(e.target.value)}
                    sx={{ ...styles.textfield, marginRight: "0.5rem" }}
                    id="outlined-select-currency"
                    InputProps={styles.inputProps}
                    select
                    label="Operators"
                >
                    {operators.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    //size="small"
                    data-testid={testIds.relationInput}
                    //inputProps={{ "data-testid": testIds.relationInput }}
                    InputProps={styles.inputProps}
                    onChange={(e: any) => setRelationOperator(e.target.value)}
                    sx={{ ...styles.textfield, visibility: !relation && !isEditing ? "visible" : "hidden" }}
                    select
                    label="Relation"
                >
                    {relationTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </MDBox>
            <MDBox
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    visibility: operator === "REGEX" || operator === "CONTAINS" ? "hidden" : "visible",
                }}
            >
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ color: "white !important" }}>Constant:</Typography>
                    <Checkbox
                        data-testid={testIds.constantCheck}
                        color="primary"
                        checked={isConstant}
                        onChange={() => setIsConstant(!isConstant)}
                    />
                </MDBox>

                <MDBox sx={{ display: "flex", alignItems: "center", visibility: isConstant ? "visible" : "hidden" }}>
                    <Checkbox
                        data-testid={testIds.isNumberCheck}
                        color="primary"
                        checked={isNumber}
                        onChange={() => setIsNumber(!isNumber)}
                    />
                    <Typography sx={{ color: "white !important" }}>:Number</Typography>
                </MDBox>
            </MDBox>
            {isConstant || operator === "CONTAINS" || operator === "REGEX" ? (
                <TextField
                    //size="small"
                    //data-testid={testIds.variable2TextField}
                    inputProps={{ "data-testid": testIds.variable2TextField }}
                    InputProps={styles.inputProps}
                    type={isConstant && operator !== "CONTAINS" && operator !== "REGEX" && isNumber ? "number" : "text"}
                    onChange={
                        isConstant && operator !== "CONTAINS" && operator !== "REGEX" && isNumber
                            ? (e: any) => {
                                  if (e.target.value.length === 0) {
                                      setVariable2("");
                                      return;
                                  }
                                  setVariable2(Number(e.target.value));
                              }
                            : (e: any) => setVariable2(e.target.value)
                    }
                    sx={{ width: "100%" }}
                    value={variable2}
                    label={"Constant"}
                />
            ) : (
                <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {currentTable.name === "Deals" && (
                        <TextField
                            data-testid={testIds.table2Input}
                            //inputProps={{ "data-testid": testIds.table2Input }}
                            InputProps={styles.inputProps}
                            sx={{ width: "48%" }}
                            select
                            label="Table"
                            value={tableOption2.name}
                        >
                            {tables.map((option: CrmTables) => (
                                <MenuItem value={option.name} onClick={() => setTableOption2(option)} key={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        data-testid={testIds.variableInput2}
                        //inputProps={{ "data-testid": testIds.variableInput2 }}
                        InputProps={styles.inputProps}
                        sx={{
                            width: currentTable.name === "Deals" ? "48%" : "100%",
                            //...scrollbar,
                        }}
                        select
                        SelectProps={{
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                    ...scrollbar,
                                },
                                PaperProps: {
                                    style: {
                                        maxHeight: "200px",
                                        //...scrollbar,
                                    },
                                },
                            },
                        }}
                        label="Variables"
                    >
                        {variableOptions2.map((option) => (
                            <MenuItem
                                onClick={() => setVariable2(tableOption2.name.toUpperCase() + "." + option)}
                                key={option}
                                value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </MDBox>
            )}
            {!isEditing ? (
                <SubmitAddButton
                    aliasGiver={aliasGiver}
                    variable1={variable1}
                    variable2={variable2}
                    relation={relation}
                    relationOperator={relationOperator}
                    operator={operator}
                    tree={tree}
                    selectedSubTree={selectedSubTree}
                    setTree={setTree}
                    setUpTree={setUpTree}
                    subTree={subTree}
                    isConstant={isConstant}
                    isEditing={isEditing}
                />
            ) : (
                <SubmitEditButton
                    aliasGiver={aliasGiver}
                    variable1={variable1}
                    variable2={variable2}
                    relation={relation}
                    relationOperator={relationOperator}
                    operator={operator}
                    tree={tree}
                    selectedSubTree={selectedSubTree}
                    setTree={setTree}
                    setUpTree={setUpTree}
                    subTree={subTree}
                    isConstant={isConstant}
                />
            )}
        </MDBox>
    );
}

export default InputFields;
