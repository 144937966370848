import { IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { PageData } from "../utils/types";
import colors from "assets/theme-dark/base/colors";
import { testIds } from "utils/testIds";
import MDBox from "components/MDBox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CasinoIcon from "@mui/icons-material/Casino";
import FunctionsIcon from "@mui/icons-material/Functions";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useEffect, useState } from "react";

const typographyStyle = (selected: boolean) => {
    return {
        userSelect: "none",
        fontSize: "0.75rem",
        fontWeight: 600,
        backgroundColor: "transparent",
        margin: 0,
        height: "3rem",
        position: "relative",
        width: "100%",
        border: `1px solid white`,
        borderRight: 0,
        borderLeft: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        lineHeight: 1.9,
        paddingRight: "0.25rem",
        color: selected ? colors.secondary.main : colors.white.main,
        zIndex: 999,
    };
};

const pseudoStyle = (selected: boolean) => {
    return {
        position: "absolute",
        width: "2.121rem",
        height: "2.121rem",
        borderTop: `1px solid white`,
        borderRight: `1px solid white`,
        borderLeft: 0,
        borderBottom: 0,
        backgroundColor: "rgb(1,9,21)",
    };
};

const iconBtnStyle = (selected: boolean) => {
    return {
        border: "1px solid white",
        borderRadius: 0,
        backgroundColor: "transparent",
        // "&:hover": {
        //     backgroundColor: selected ? colors.white.main : "transparent",
        // },
        // backgroundColor: selected ? colors.white.main : "transparent",
    };
};

function BreadCrumb({
    isConversionVisible,
    updatePageData,
    isVisible,
}: {
    isConversionVisible: number;
    updatePageData: (object: Partial<PageData>) => void;
    isVisible: boolean;
}) {
    const [distance, setDistance] = useState(0);
    const [timeOutIndex, setTimeoutIndex] = useState(0);

    function timeIndexHandler(index: number) {
        setTimeout(() => {
            setTimeoutIndex(index);
        }, 150);
    }
    useEffect(() => {
        if (isVisible) {
            updatePageData({
                isConversionVisible: 0,
            });
            setDistance(0);
            setTimeoutIndex(0);
        }
    }, [isVisible]);

    return (
        <>
            <MDBox
                sx={{
                    marginTop: "1rem",
                    display: "none",
                    "@media (max-width:1000px)": {
                        display: "block",
                    },
                    userSelect: "none",
                    paddingLeft: { xxl: "3rem", lg: "3rem", md: "3rem", xs: "1.5rem" },
                    //marginLeft: { xxl: "0", lg: "0", md: "0", xs: "2rem" },
                }}
            >
                <MDBox sx={{ display: "flex" }}>
                    <IconButton
                        sx={{
                            ...iconBtnStyle(isConversionVisible === 0),
                        }}
                        onClick={() =>
                            updatePageData({
                                isConversionVisible: 0,
                            })
                        }
                        value={0}
                    >
                        <AccessTimeIcon
                            sx={{
                                color: isConversionVisible === 0 ? colors.secondary.main : colors.white.main,
                                width: "2rem",
                                height: "2rem",
                            }}
                        />
                    </IconButton>
                    <IconButton
                        sx={{
                            ...iconBtnStyle(isConversionVisible === 1),
                        }}
                        onClick={() =>
                            updatePageData({
                                isConversionVisible: 1,
                            })
                        }
                        value={1}
                    >
                        <CasinoIcon
                            sx={{
                                color: isConversionVisible === 1 ? colors.secondary.main : colors.white.main,
                                width: "2rem",
                                height: "2rem",
                            }}
                        />
                    </IconButton>
                    <IconButton
                        sx={{
                            ...iconBtnStyle(isConversionVisible === 2),
                        }}
                        onClick={() =>
                            updatePageData({
                                isConversionVisible: 2,
                            })
                        }
                        value={2}
                    >
                        <FunctionsIcon
                            sx={{
                                color: isConversionVisible === 2 ? colors.secondary.main : colors.white.main,
                                width: "2rem",
                                height: "2rem",
                            }}
                        />
                    </IconButton>
                    <IconButton
                        sx={{
                            ...iconBtnStyle(isConversionVisible === 3),
                        }}
                        onClick={() =>
                            updatePageData({
                                isConversionVisible: 3,
                            })
                        }
                        value={3}
                    >
                        <SignalCellularAltIcon
                            sx={{
                                color: isConversionVisible === 3 ? colors.secondary.main : colors.white.main,
                                width: "2rem",
                                height: "2rem",
                            }}
                        />
                    </IconButton>
                </MDBox>
            </MDBox>
            <MDBox
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginTop: "1rem",
                    position: "relative",
                    boxShadow:
                        "rgba(255, 255, 255, 0.1) 0px 8px 24px, rgba(255, 255, 255, 0.1) 0px 16px 56px, rgba(255, 255, 255, 0.1) 0px 24px 80px",
                    "@media (max-width:1000px)": {
                        display: "none",
                    },
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        transition: "all 0.125s ease-out",
                        left: `${isConversionVisible * 8}rem`,
                        zIndex: 10,
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            width: "8rem",
                            height: "3rem",
                            backgroundColor: "white",
                            transform: "translate(0,-50%)",
                        }}
                    ></div>
                    <div
                        style={{
                            position: "absolute",
                            width: "2.121rem",
                            height: "2.121rem",
                            transform: "translate(-50%,-50%) rotate(45deg)",
                            left: "8rem",
                            borderBottom: 0,
                            backgroundColor: colors.white.main,
                        }}
                    ></div>
                </div>
                {/* {} */}
                <div
                    style={{
                        display: "flex",
                        //border: "1px solid green",
                        position: "relative",
                        height: "100%",
                        minWidth: "8rem",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        data-testid={testIds.breadCrumbOption}
                        onClick={() => {
                            updatePageData({
                                isConversionVisible: 0,
                            });
                            setDistance(0);
                            timeIndexHandler(0);
                        }}
                        sx={{
                            ...typographyStyle(isConversionVisible === 0),
                            "&:hover": { cursor: "pointer" },

                            borderLeft: `2px solid white`,
                        }}
                    >
                        Durations (days)
                    </Typography>
                    <div
                        //@ts-ignore
                        style={{
                            ...pseudoStyle(isConversionVisible === 0),
                            transform: "translate(50%,-50%) rotate(45deg)",
                            top: "50%",
                            right: 0,
                            zIndex: isConversionVisible === 1 && timeOutIndex === 1 ? 15 : 8,
                        }}
                    ></div>
                </div>
                <div
                    style={{
                        display: "flex",
                        //border: "1px solid green",
                        position: "relative",
                        height: "100%",
                        minWidth: "8rem",
                    }}
                >
                    <Typography
                        data-testid={testIds.breadCrumbOption}
                        onClick={() => {
                            updatePageData({
                                isConversionVisible: 1,
                            });
                            setDistance(8);
                            timeIndexHandler(1);
                        }}
                        sx={{
                            ...typographyStyle(isConversionVisible === 1),
                            "&:hover": { cursor: "pointer" },
                        }}
                    >
                        Conversions (%)
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        //border: "1px solid green",
                        position: "relative",
                        height: "100%",
                        minWidth: "8rem",
                    }}
                >
                    <div
                        //@ts-ignore
                        style={{
                            ...pseudoStyle(isConversionVisible === 1),
                            transform: "translate(-50%,-50%) rotate(45deg)",
                            top: "50%",
                            zIndex: isConversionVisible === 2 && timeOutIndex === 2 ? 15 : 6,
                        }}
                    ></div>
                    <Typography
                        data-testid={testIds.breadCrumbOption}
                        onClick={() => {
                            updatePageData({
                                isConversionVisible: 2,
                            });
                            setDistance(16);
                            timeIndexHandler(2);
                        }}
                        sx={{
                            ...typographyStyle(isConversionVisible === 2),
                            "&:hover": { cursor: "pointer" },
                        }}
                    >
                        Number of Deals
                    </Typography>
                    <div
                        //@ts-ignore
                        style={{
                            ...pseudoStyle(isConversionVisible === 3),
                            transform: "translate(50%,-50%) rotate(45deg)",
                            display: isVisible ? "block" : "none",
                            top: "50%",
                            right: 0,
                            zIndex: 1,
                        }}
                    ></div>
                </div>
                {!isVisible && (
                    <div
                        style={{
                            display: "flex",
                            //border: "1px solid green",
                            position: "relative",
                            height: "100%",
                            minWidth: "8rem",
                        }}
                    >
                        <div
                            //@ts-ignore
                            style={{
                                ...pseudoStyle(isConversionVisible === 2),
                                transform: "translate(-50%,-50%) rotate(45deg)",
                                top: "50%",
                                zIndex: isConversionVisible === 3 && timeOutIndex === 3 ? 15 : 3,
                            }}
                        ></div>
                        <Typography
                            data-testid={testIds.breadCrumbOption}
                            onClick={() => {
                                updatePageData({
                                    isConversionVisible: 3,
                                });
                                setDistance(24);
                                timeIndexHandler(3);
                            }}
                            sx={{
                                ...typographyStyle(isConversionVisible === 3),
                                "&:hover": { cursor: "pointer" },
                            }}
                        >
                            Visual
                        </Typography>
                        <div
                            //@ts-ignore
                            style={{
                                ...pseudoStyle(isConversionVisible === 3),
                                transform: "translate(50%,-50%) rotate(45deg)",

                                top: "50%",
                                right: 0,
                                zIndex: 1,
                            }}
                        ></div>
                    </div>
                )}
            </MDBox>
        </>
    );
}

export default BreadCrumb;
