import { useContext, useEffect, useState } from "react";

import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import { usePipelinesQuery } from "graphql/types/graphql";
import { Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import { context } from "utils/Provider";
import Tabs from "./components/Tab";

import { scrollbar } from "utils/scrollBar";
import usePipelines from "layouts/applications/pipelines/utils/usePipelines";
import TableContent from "./components/TableContent";
import _ from "lodash";
import convertToInput from "./utils/convertToInput";
import errorDecider from "./utils/errorDecider";
import { PageData, Pipeline } from "./utils/types";
import { linearGradientStyle } from "utils/linearGradientsStyle";

const pipeline: Pipeline = {
    pipelineId: "",
    input: {
        pipeline: {
            durations: [],
            schema: {
                pipelineInstances: [],
                stages: [],
            },
        },
    },
};

const initial: PageData = {
    board: undefined,
    currentPipeline: pipeline,
    newPipeline: pipeline,
    currentlyEditing: "",
    pipelineIndex: 0,
    crmPipeIndex: 0,
    columnIndex: 0,
    template: undefined,
    selectedColumn: undefined,
    selectedCrmPipe: undefined,
    isVisible: false,
    crmPipes: [],
    isConversionVisible: 0,
    calculatedNewPipeline: undefined,
    fatalError: "",
    parameterError: "",
};

function Pipelines(): JSX.Element {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const { setIsLoading, selectedCompany, isLoading } = useContext(context);
    const [pageData, setPageData] = useState<PageData>(initial);

    const { pipelineIndex, crmPipeIndex, columnIndex, isConversionVisible, fatalError } = pageData;

    function updatePageData(object: Partial<PageData>) {
        setPageData((current: PageData) => {
            return { ...current, ...object };
        });
    }

    const handleChange = (newValue: any) => {
        updatePageData({
            calculatedNewPipeline: undefined,
            pipelineIndex: newValue,
        });
    };

    const { error, loading, data } = usePipelinesQuery({
        variables: { cruxyId: selectedCompany.cruxyId },
    });

    const {
        changeOrder,
        saveExistingTag,
        callForDeletePipeline,
        saveNew,
        saveContent,
        undoChanges,
        handleInstanceName,
        handleStageName,
        removeStage,
        handleDuration,
        handleConversionRate,
        boardMaker,
        calculateConversions,
        getStagesHandler,
        preparePipelineData,
        setupTemplate,
    } = usePipelines(setIsLoading, data, updatePageData, pageData, selectedCompany);

    useEffect(() => {
        updatePageData({
            pipelineIndex: 0,
            crmPipeIndex: 0,
            columnIndex: 0,
            template: undefined,
            isConversionVisible: 0,
            parameterError: "",
            fatalError: "",
        });
    }, [selectedCompany]);

    useEffect(() => {
        setIsLoading(true);
        errorDecider("CLEAN", updatePageData);
        if (error) {
            console.log(JSON.stringify(error));
            errorDecider("ERROR", updatePageData);
            setIsLoading(false);
        }
        if (data && data?.pipelines) {
            if (data.pipelines.length === 0) {
                errorDecider("NO_PIPELINES", updatePageData);
                setIsLoading(false);
                return;
            }
            if (data?.pipelines[pipelineIndex]?.data === null) {
                setupTemplate();
            } else {
                preparePipelineData(data?.pipelines[pipelineIndex]);
                setIsLoading(false);
            }
        }
    }, [data, error, pipelineIndex, crmPipeIndex, columnIndex, isConversionVisible]);

    useEffect(() => {
        if (!data?.pipelines[pipelineIndex]?.data) {
            updatePageData({
                isVisible: true,
                isConversionVisible: 0,
            });
        } else {
            updatePageData({
                isVisible: false,
            });
        }
    }, [pipelineIndex, data]);

    return (
        <>
            <Tabs
                handleChange={handleChange}
                pipelines={data?.pipelines ? convertToInput(data?.pipelines) : []}
                pipelineIndex={pipelineIndex}
                saveNew={saveNew}
                saveExistingHeader={saveExistingTag}
                deletePipeline={callForDeletePipeline}
            />
            <MDBox
                //border={`4px solid ${colors.background.card}`}
                minHeight="700px"
                position={"relative"}
                sx={{
                    ...scrollbar,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 0,
                    ...linearGradientStyle,
                }}
            >
                {!isLoading &&
                    (fatalError?.length > 0 || !data ? (
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "1.5rem",
                                color: colors.white.main,
                                paddingTop: "1rem",
                                paddingLeft: "3rem",
                            }}
                        >
                            {fatalError}
                        </Typography>
                    ) : (
                        <TableContent
                            pageData={pageData}
                            updatePageData={updatePageData}
                            setupTemplate={setupTemplate}
                            data={data}
                            getStagesHandler={getStagesHandler}
                            undoChanges={undoChanges}
                            saveChanges={saveContent}
                            boardMaker={boardMaker}
                            handleDuration={handleDuration}
                            handleConversionRate={handleConversionRate}
                            handleStageName={handleStageName}
                            handleInstanceName={handleInstanceName}
                            removeStage={removeStage}
                            changeOrder={changeOrder}
                            calculateConversions={calculateConversions}
                        />
                    ))}
            </MDBox>
        </>
    );
}

export default Pipelines;
