import { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import colors from "assets/theme-dark/base/colors";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { context } from "utils/Provider";
import {
    ClientsResponse,
    User,
    UserRole,
    useRolesQuery,
    useSignUpMutation,
    useUsersQuery,
} from "graphql/types/graphql";
import { enqueueSnackbar } from "notistack";
import { CRUXY_ADMIN, CRUXY_USER } from "common/names";
import UsersTable from "./components/UsersTable";
import { DebouncedFunc, debounce } from "lodash";
import { linearGradientStyle } from "utils/linearGradientsStyle";

interface FormValue {
    cruxyId: string;
    role: string;
    email: string;
    password: string;
    repeatPassword: string;
}

const LoginSchema = Yup.object().shape({
    cruxyId: Yup.string().required("CruxyId required!"),
    role: Yup.string().required("Role required!"),
    email: Yup.string().email("Invalid email!").required("Email required!"),
    password: Yup.string().required("Password required!"),
    repeatPassword: Yup.string()
        .required("Confirm password required!")
        .test("", "Password fields must match", function (value) {
            return this.parent.password === value;
        }),
});

function ManageUsers(): JSX.Element {
    const { companies, token, setIsLoading, user } = useContext(context);

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorRepeatPass, setErrorRepeatPass] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    const [errorUser, setErrorUser] = useState<string>(" ");

    const [users, setUsers] = useState<User[]>();
    const [roles, setRoles] = useState<string[]>();

    const [role, setRole] = useState<string | null>(null);
    const [cruxyId, setCruxyId] = useState<string | null>(null);

    const [signUp] = useSignUpMutation();

    const {
        data: dataUsers,
        loading: loadingUsers,
        error: errorUsers,
    } = useUsersQuery({
        variables: {
            filter: {
                cruxyId: cruxyId,
                //@ts-ignore
                role: UserRole[role],
            },
        },
    });
    const { data, loading, error } = useRolesQuery();
    const debounceRef = useRef<DebouncedFunc<(criteria: any) => void> | undefined>();

    useEffect(() => {
        if (data?.roles) {
            setRoles(data.roles);
        }
    }, [data, error]);

    useEffect(() => {
        if (dataUsers?.users) {
            setUsers(dataUsers?.users);
        }
    }, [dataUsers, errorUsers]);

    useEffect(() => {
        setIsLoading(loading || loadingUsers);
    }, [loading, loadingUsers]);

    async function signUpHandler(values: FormValue) {
        try {
            setIsLoading(true);
            // setIsLoading(false);
            // return;
            const response = await signUp({
                variables: {
                    cruxyId: values.cruxyId,
                    //@ts-ignore
                    role: UserRole[values.role],
                    email: values.email,
                    password: values.password,
                },
            });
            if (response) {
                enqueueSnackbar("User added successfully!", { variant: "success" });
                setIsLoading(false);
            }
        } catch (error: any) {
            console.log("err", JSON.stringify(error));
            setIsLoading(false);
            enqueueSnackbar(error.graphQLErrors[0].message ? error.graphQLErrors[0].message : "Something went wrong!", {
                variant: "error",
            });
        }
    }

    return (
        <>
            <MDBox
                sx={{
                    flex: 1,
                    backgroundColor: "transparent",
                    padding: "2rem",
                    display: "flex",
                    //flexDirection: "column",
                    //alignItems: "center",
                    gap: 3,
                    //justifyContent: "center",
                    ...linearGradientStyle,
                }}
            >
                {roles && users && (
                    <>
                        <MDBox sx={{ width: "50%" }}>
                            <MDBox
                                sx={{
                                    display: "flex",
                                    width: "100%",
                                    //alignItems: "center",
                                    height: "6rem",
                                    //border: "1px solid white",
                                    gap: 4,
                                }}
                            >
                                <TextField
                                    //InputProps={{ sx: { height: 50 } }}
                                    size="small"
                                    select
                                    label="Company"
                                    sx={{ width: "20rem" }}
                                >
                                    {companies.map((filter: ClientsResponse) => {
                                        return (
                                            <MenuItem
                                                key={filter.cruxyId}
                                                onClick={() => {
                                                    setCruxyId(filter.cruxyId);
                                                }}
                                                value={filter.name}
                                            >
                                                {filter.name}
                                            </MenuItem>
                                        );
                                    })}
                                    <MenuItem
                                        key={"all"}
                                        onClick={() => {
                                            setCruxyId(null);
                                        }}
                                        value={"All"}
                                    >
                                        {"All"}
                                    </MenuItem>
                                </TextField>
                                <TextField
                                    size="small"
                                    sx={{
                                        width: "15%",
                                        minWidth: "140px",
                                        marginBottom: "1rem",
                                        //marginTop: "1rem",
                                    }}
                                    value={role}
                                    select
                                    label="Role"
                                >
                                    {roles.map((filter: string) => {
                                        return (
                                            <MenuItem
                                                key={filter}
                                                onClick={() => {
                                                    setRole(filter);
                                                }}
                                                value={filter}
                                            >
                                                {filter}
                                            </MenuItem>
                                        );
                                    })}
                                    <MenuItem
                                        key={"all"}
                                        onClick={() => {
                                            setRole(null);
                                        }}
                                        value={"All"}
                                    >
                                        {"All"}
                                    </MenuItem>
                                </TextField>
                            </MDBox>
                            <UsersTable users={users} />
                        </MDBox>
                        <MDBox sx={{ width: "50%" }}>
                            <Typography
                                sx={{
                                    color: colors.white.main,
                                    fontSize: "1.5rem",
                                    fontWeight: 600,
                                    marginBottom: "4rem",
                                }}
                            >
                                New User
                            </Typography>
                            <Formik
                                initialValues={{
                                    email: "",
                                    password: "",
                                    repeatPassword: "",
                                    cruxyId: user.cruxyId,
                                    role: "",
                                }}
                                validationSchema={LoginSchema}
                                onSubmit={async (values) => {
                                    signUpHandler(values);
                                }}
                            >
                                <Form style={{ width: "100%" }}>
                                    {Boolean(user.role === CRUXY_ADMIN || user.role === CRUXY_USER) && (
                                        <Field sx={{ width: "100%" }} name="cruxyId">
                                            {(props: FieldProps<string, FormValue>) => {
                                                return (
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <TextField
                                                            sx={{ width: "100%", marginBottom: "1.5rem" }}
                                                            size="small"
                                                            name="cruxyId"
                                                            select
                                                            SelectProps={{
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "center",
                                                                    },
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: "200px",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            label={"Cruxy ID"}
                                                            defaultValue="Cruxy ID"
                                                            required
                                                            value={props.field.value}
                                                            onChange={(e) => {
                                                                props.form.setFieldValue(
                                                                    props.field.name,
                                                                    e.target.value,
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {companies?.map((option: ClientsResponse) => (
                                                                <MenuItem key={option.cruxyId} value={option?.cruxyId}>
                                                                    {option?.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    )}
                                    <Field sx={{ width: "100%" }} name="role">
                                        {(props: FieldProps<string, FormValue>) => {
                                            return (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <TextField
                                                        size="small"
                                                        sx={{ width: "100%", marginBottom: "1.5rem" }}
                                                        name="role"
                                                        select
                                                        SelectProps={{
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center",
                                                                },
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: "200px",
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                        label={"Role"}
                                                        defaultValue="Role"
                                                        required
                                                        value={props.field.value}
                                                        onChange={(e) => {
                                                            props.form.setFieldValue(
                                                                props.field.name,
                                                                e.target.value,
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {roles?.map((option: string) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            );
                                        }}
                                    </Field>
                                    <Field sx={{ width: "100%" }} name="email">
                                        {(props: FieldProps<string, FormValue>) => {
                                            return (
                                                <TextField
                                                    size="small"
                                                    onBlur={() => setErrorEmail(true)}
                                                    inputProps={{ "data-testid": "emailForm" }}
                                                    sx={{ width: "100%", marginBottom: "1.5rem" }}
                                                    name="Email"
                                                    label={"Email"}
                                                    color="secondary"
                                                    autoFocus
                                                    required
                                                    value={props.field.value}
                                                    onChange={(e) => {
                                                        setErrorUser(" ");
                                                        setErrorEmail(false);
                                                        props.form.setFieldValue(
                                                            props.field.name,
                                                            e.target.value,
                                                            true
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    </Field>
                                    <Field name="password">
                                        {(props: FieldProps<string, FormValue>) => {
                                            return (
                                                <TextField
                                                    size="small"
                                                    onBlur={() => setErrorPass(true)}
                                                    inputProps={{
                                                        "data-testid": "passwordForm",
                                                    }}
                                                    sx={{ width: "100%", marginBottom: "1.5rem" }}
                                                    color="secondary"
                                                    name="Password"
                                                    label={"Password"}
                                                    type="password"
                                                    required
                                                    value={props.field.value}
                                                    onChange={(e) => {
                                                        setErrorUser(" ");
                                                        setErrorPass(false);
                                                        props.form.setFieldValue(
                                                            props.field.name,
                                                            e.target.value,
                                                            true
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    </Field>
                                    <Field name="repeatPassword">
                                        {(props: FieldProps<string, FormValue>) => {
                                            return (
                                                <TextField
                                                    size="small"
                                                    onBlur={() => setErrorRepeatPass(true)}
                                                    inputProps={{
                                                        "data-testid": "passwordForm",
                                                    }}
                                                    sx={{ width: "100%" }}
                                                    color="secondary"
                                                    name="repeatPassword"
                                                    label={"Confirm Password"}
                                                    type="password"
                                                    required
                                                    value={props.field.value}
                                                    onChange={(e) => {
                                                        setErrorUser(" ");
                                                        setErrorRepeatPass(false);
                                                        props.form.setFieldValue(
                                                            props.field.name,
                                                            e.target.value,
                                                            true
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    </Field>

                                    <MDBox mt={2} sx={{ minHeight: "2rem" }}>
                                        <ErrorTypography
                                            errorEmail={errorEmail}
                                            erroPass={errorPass}
                                            errorRepeatPass={errorRepeatPass}
                                        />
                                    </MDBox>

                                    <MDBox mt={2} mb={1}>
                                        <MDButton
                                            type="submit"
                                            variant="gradient"
                                            color="secondary"
                                            size="large"
                                            fullWidth
                                        >
                                            Add User
                                        </MDButton>
                                    </MDBox>
                                </Form>
                            </Formik>
                        </MDBox>
                    </>
                )}
            </MDBox>
        </>
    );
}

export default ManageUsers;

function ErrorTypography(props: { errorEmail: boolean; erroPass: boolean; errorRepeatPass: boolean }): JSX.Element {
    const { errorEmail, erroPass, errorRepeatPass } = props;
    const context = useFormikContext<FormValue>();
    const item = context.errors;

    if (Object.keys(item).length === 0) {
        return (
            <MDTypography mt={2} color="error">
                {" "}
            </MDTypography>
        );
    }
    if (item?.email && errorEmail) {
        return (
            <MDTypography mt={2} color="error">
                {item.email}
            </MDTypography>
        );
    }
    if (item?.password && erroPass) {
        return (
            <MDTypography mt={2} color="error">
                {item.password}
            </MDTypography>
        );
    }
    if (item?.repeatPassword && errorRepeatPass) {
        return (
            <MDTypography mt={2} color="error">
                {item.repeatPassword}
            </MDTypography>
        );
    }
    return (
        <MDTypography mt={2} color="error">
            {" "}
        </MDTypography>
    );
}
