import { debounce } from "lodash";
import { CompanyFilters, dealFilters } from "./filters";

export default function searchChange(
    e: React.ChangeEvent<HTMLInputElement>,
    debounceRef: any,
    filterBy: string,
    setDealName: (value: string) => void,
    setFiscalYear: (value: string) => void,
    setType: (value: string) => void,
    setCountry: (value: string) => void,
    setName: (value: string) => void
) {
    debounceRef.current?.cancel();
    debounceRef.current = debounce((criteria) => {
        switch (filterBy) {
            case dealFilters[0]:
                setDealName(criteria.length > 0 ? criteria : null);
                break;
            case dealFilters[1]:
                setFiscalYear(criteria.length > 0 ? criteria : null);
                break;
            case dealFilters[2]:
                setType(criteria.length > 0 ? criteria : null);
                break;
            case CompanyFilters[0]:
                setCountry(criteria.length > 0 ? criteria : null);
                break;
            case CompanyFilters[1]:
                setName(criteria.length > 0 ? criteria : null);
                break;
        }
    }, 800);
    debounceRef.current(e.target.value);
}
