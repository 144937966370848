export const testIds = {
    columnKanban: "columnKanban",
    breadCrumbSelect: "breadcrumbSelect",
    breadCrumbOption: "breadCrumbOption",
    card: "card",
    pipelineApplyBtn: "pipelineApplyBtn",
    cardInput: "cardInput",
    tab: "tab",
    columnDiv: "columnDiv",
    columnInput: "columnInput",
    pipelineUndoBtn: "pipelineUndoBtn",
    pipelineSaveBtn: "pipelineSaveBtn",
    newPipeBtn: "newPipeBtn",
    deletePipeBtn: "deletePipeBtn",
    instancesOfDropdown: "instancesOfDropdown",
    stagesOfDropdown: "stagesOfDropdown",
    randomDiv: "randomDiv",
    companiesMenu: "companiesMenu",
    tabInput: "tabInput",
    confirmBtn: "confirmBtn",
    tableRow: "tableRow",
    crmTableInput: "crmTableInput",
    entriesPerPage: "entriesPerPage",
    menuOption: "menuOption",
    previousPage: "previousPage",
    nextPage: "nextPage",
    addColumnInput: "addColumnInput",
    addColumnBtn: "addColumnBtn",
    columnList: "columnList",
    columnListDeleteBtn: "columnListDeleteBtn",
    columnListCalcBtn: "columnListCalcBtn",
    columnDropDown: "columnDropDown",
    formulaDropDown: "formulaDropDown",
    conditionBranch: "conditionBranch",
    columnName: "columnName",
    ruleName: "ruleName",
    columnNameInput: "columnNameInput",
    ruleNameInput: "ruleNameInput",
    ruleSaveBtn: "ruleSaveBtn",
    ruleUndoBtn: "ruleUndoBtn",
    ruleApplyBtn: "ruleApplyBtn",
    criteria: "criteria",
    editFrame: "editFrame",
    ruleEditBtn: "ruleEditBtn",
    ruleDeleteBtn: "ruleDeleteBtn",
    relationInput: "relationInput",
    table1Input: "table1Input",
    table2Input: "table2Input",
    variableInput1: "variableInput1",
    variableInput2: "variableInput2",
    operatorInput: "operatorInput",
    constantCheck: "constantCheck",
    isNumberCheck: "isNumberCheck",
    submitAddBtn: "submitAddBtn",
    submitEditBtn: "submitEditBtn",
    variable2TextField: "variable2TextField",
    rootBtn: "rootBtn",
    containsBadge: "containsBadge",
};
