import { objectPropHandler } from "./objectPropHandler";

export default function addNewObject(newSubTree: any, tree: any, selectedSubTree: any) {
    const key = objectPropHandler(tree);

    if (key !== "AND" && key !== "OR") {
        return newSubTree;
    }

    return {
        id: tree.id,
        [key]: tree[key].map((item: any, index: number) => {
            const key = objectPropHandler(item);

            if (key === "AND" || key === "OR") {
                if (item.id === selectedSubTree.id) {
                    return newSubTree;
                } else {
                    return addNewObject(newSubTree, item, selectedSubTree);
                }
            } else {
                if (item.id === selectedSubTree.id) {
                    return newSubTree;
                } else {
                    return item;
                }
            }
        }),
    };
}
