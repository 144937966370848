import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import confirmSnack from "utils/confirmSnack";
import removeSubObject from "../utils/removeSubObject";
import repairTree from "../utils/repairTree";
import { Typography } from "@mui/material";
import InputFields from "./InputFields";
import { CrmTables, Formula } from "graphql/types/graphql";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RuleColumn from "./RuleColumn";
import { objectPropHandler } from "../utils/objectPropHandler";
import { useEffect } from "react";
import { getSubTreeById } from "../utils/getSubTreeById";
import { testIds } from "utils/testIds";

interface Props {
    selectedSubTree: any;
    tree: any;
    deleteRuleHandler: () => void;
    setTree: (value: any) => void;
    setSelectedSubTree: (value: any) => void;
    isEditing: boolean;
    setIsEditing: (value: boolean) => void;
    selectedRule: Formula;
    relation: string;
    currentTable: CrmTables;
    setUpTree: (newTree: any, subTree?: any) => void;
    tables: CrmTables[];
    crmColumns: string[];
    variableOptions1: string[];
    variableOptions2: string[];
    tableOption1: CrmTables;
    tableOption2: CrmTables;
    setTableOption1: (value: CrmTables) => void;
    setTableOption2: (value: CrmTables) => void;
    setRelation: (value: string) => void;
    aliasGiver: (rule: any, relation: string, alias: string, id: string) => any;
}

function EditFrame(props: Props) {
    const {
        setRelation,
        setTableOption2,
        setTableOption1,
        tableOption2,
        tableOption1,
        variableOptions1,
        variableOptions2,
        crmColumns,
        tables,
        setUpTree,
        currentTable,
        selectedSubTree,
        tree,
        deleteRuleHandler,
        setTree,
        setSelectedSubTree,
        isEditing,
        setIsEditing,
        selectedRule,
        relation,
        aliasGiver,
    } = props;

    function deleteButtonDisplayHandler() {
        if (selectedSubTree.id === tree.id && selectedRule?.id === "newRule") {
            return true;
        }
        if (JSON.stringify(selectedSubTree).indexOf("A.New Rule") !== -1 && selectedSubTree.id === tree.id) {
            return true;
        }

        return false;
    }

    return (
        <MDBox
            data-testid={testIds.editFrame}
            sx={{
                //width: { xxl: "60%", lg: "60%", md: "100%", xs: "100%" },
                marginTop: { xxl: "0", lg: "0", md: "2rem", xs: "2rem" },
                border: {
                    xxl: `3px solid ${colors.primary.main}`,
                    lg: `3px solid ${colors.primary.main}`,
                    md: `3px solid ${colors.primary.main}`,
                    xs: "none",
                },
                position: "relative",
                display: "flex",
                flexDirection: { xxl: "row", lg: "row", md: "row", xs: "column" },
                justifyContent: "space-between",
                padding: { xxl: "1rem", lg: "1rem", md: "1rem", xs: "0" },
                borderRadius: "8px",
                boxShadow: `inset  0 0 0.5rem 0 ${colors.primary.main}, 0 0 0.5rem 0 ${colors.primary.main}`,
            }}
        >
            <MDBox
                sx={{
                    width: { xxl: "60%", lg: "60%", md: "60%", xs: "100%" },
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                }}
            >
                <MDBox
                    sx={{
                        color: colors.white.main,
                        display: "flex",
                        marginBottom: "1rem",
                        width: "8rem",
                        justifyContent: "flex-start",
                        gap: 1,
                    }}
                >
                    <MDButton
                        data-testid={testIds.ruleDeleteBtn}
                        onClick={() => {
                            if (
                                selectedSubTree.id === tree.id ||
                                ["OR", "AND"].indexOf(objectPropHandler(tree)) === -1
                            ) {
                                const question = "Are you sure you want to delete this rule?";
                                confirmSnack(deleteRuleHandler, question);
                                return;
                            }

                            const newTree = removeSubObject(tree, selectedSubTree);
                            const key = objectPropHandler(newTree);

                            const repairedTree = repairTree(newTree[key], key, newTree.id);

                            const aliasedTree = aliasGiver(
                                repairedTree[objectPropHandler(repairedTree)],
                                objectPropHandler(repairedTree),
                                "C",
                                repairedTree.id
                            );
                            const subAliasedTree = getSubTreeById(repairedTree, aliasedTree, selectedSubTree);

                            setUpTree(repairedTree, subAliasedTree);
                        }}
                        iconOnly
                        sx={{
                            borderRadius: "50%",
                            width: "2rem",
                            height: "2rem",
                            boxShadow: `inset  0 0 0.5rem 0 ${colors.white.main}, 0 0 0.5rem 0 ${colors.white.main}`,
                            display: deleteButtonDisplayHandler() ? "none" : "block",
                        }}
                    >
                        <DeleteIcon fontSize="large" />
                    </MDButton>
                    <MDButton
                        data-testid={testIds.ruleEditBtn}
                        iconOnly
                        color={isEditing ? "secondary" : undefined}
                        sx={{
                            borderRadius: "50%",
                            width: "2rem",
                            height: "2rem",
                            boxShadow: `0 0 0.5rem 0 ${colors.white.main}`,
                            display:
                                objectPropHandler(selectedSubTree) === "AND" ||
                                objectPropHandler(selectedSubTree) === "OR"
                                    ? "none"
                                    : "block",
                        }}
                        onClick={() => {
                            setIsEditing(!isEditing);
                        }}
                    >
                        <EditIcon fontSize="large" />
                    </MDButton>
                </MDBox>
                <MDBox
                    sx={{
                        width: "7rem",
                        visibility: relation !== "" ? "visible" : "hidden",
                        color: colors.white.main,
                        marginBottom: "2rem",
                        height: "3rem",
                    }}
                >
                    <Typography sx={{ fontWeight: "600", fontSize: "1rem", color: colors.secondary.main }}>
                        Relation type:
                    </Typography>
                    <MDBox
                        sx={{
                            border: "1px solid white",
                            textAlign: "center",
                            color: colors.white.main,
                            borderRadius: "5px",
                            fontSize: "1rem",
                            boxShadow: `inset  0 0 0.25rem 0 ${colors.white.main}, 0 0 0.25rem 0 ${colors.white.main}`,
                            //textShadow: `0 0 0.125rem hsl(0 0% 100% / 0.5), 0 0 0.125rem ${colors.white.main}`,
                        }}
                    >
                        {relation}
                    </MDBox>
                </MDBox>
                <InputFields
                    aliasGiver={aliasGiver}
                    currentTable={currentTable}
                    subTree={selectedSubTree}
                    relation={relation}
                    tree={tree}
                    setTree={setTree}
                    setUpTree={setUpTree}
                    selectedSubTree={selectedSubTree}
                    isEditing={isEditing}
                    tables={tables}
                    crmColumns={crmColumns}
                    variableOptions1={variableOptions1}
                    variableOptions2={variableOptions2}
                    tableOption1={tableOption1}
                    tableOption2={tableOption2}
                    setTableOption1={setTableOption1}
                    setTableOption2={setTableOption2}
                />
            </MDBox>
            <MDBox
                sx={{
                    width: { xxl: "40%", lg: "40%", md: "40%", xs: "100%" },
                    display: "flex",
                    alignItems: { xxl: "end", lg: "end", md: "end", xs: "flex-start" },
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    //border: "1px solid white",
                }}
            >
                <MDBox
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <RuleColumn
                        relation={relation}
                        setRelation={setRelation}
                        selectedSubTree={selectedSubTree}
                        setSelectedSubTree={setSelectedSubTree}
                    />
                </MDBox>
            </MDBox>
        </MDBox>
    );
}

export default EditFrame;
