/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";
import boxShadows from "assets/theme-dark/base/boxShadows";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { background } = colors;
const { borderRadius } = borders;
const { md } = boxShadows;

// types
type Types = any;

const tabs: Types = {
    styleOverrides: {
        root: {
            position: "relative",
            backgroundColor: background.card,
            //borderRadius: borderRadius.xl,
            minHeight: "unset",
            padding: pxToRem(4),
        },

        flexContainer: {
            height: "100%",
            position: "relative",
            zIndex: 10,
        },

        fixed: {
            overflow: "unset !important",
            overflowX: "unset !important",
        },

        vertical: {
            "& .MuiTabs-indicator": {
                width: "100%",
            },
        },

        indicator: {
            height: "100%",
            //borderRadius: borderRadius.lg,
            backgroundColor: background.default,
            //boxShadow: md,
            transition: "all 250ms ease",
        },
    },
};

export default tabs;
