import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
    ClientsResponse,
    PipelinesResponse,
    Projection,
    useExpectationLazyQuery,
    usePipelinesQuery,
    useRealityLazyQuery,
} from "graphql/types/graphql";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { context } from "utils/Provider";
import { enqueueSnackbar } from "notistack";
import { Typography } from "@mui/material";
import colors from "assets/theme-dark/base/colors";
import "./style.css";
import Charts from "./components/Charts";
import React from "react";
import { User } from "utils/types";
import { linearGradientStyle } from "utils/linearGradientsStyle";

interface Initials {
    pipelines: PipelinesResponse[];
    instances: string[];
    expectations: Projection[];
    reality: Projection[];
    index: number;
}

function HowAmIProjected(): JSX.Element {
    const { setIsLoading, user, selectedCompany, token, refreshAccessToken, setToken } = useContext(context);

    const { error, loading, data } = usePipelinesQuery({ variables: { cruxyId: selectedCompany.cruxyId } });

    const [index, setIndex] = useState(0);

    const [getExpectation] = useExpectationLazyQuery();
    const [getReality] = useRealityLazyQuery();

    const [props, setProps] = useState<Initials>();
    const [noPipelines, setNoPipelines] = useState(false);

    const indexRef: any = useRef(null);
    indexRef.current = index;

    useEffect(() => {
        refresh();
    }, [selectedCompany]);

    useEffect(() => {
        if (indexRef.current === 0) {
            //setInitials();
        } else {
            setIndex(0);
        }
        setNoPipelines(false);
    }, [selectedCompany]);

    useEffect(() => {
        if (error) enqueueSnackbar("Something went wrong!", { variant: "error" });
        if (data) {
            if (
                data?.pipelines.length > 0 &&
                data.pipelines.filter((pipeline) => pipeline.data !== null).length !== 0
            ) {
                setInitials();
            } else {
                setNoPipelines(true);
            }
        }
    }, [data, error, index]);

    async function refresh() {
        const data = await refreshAccessToken();
        if (!data) {
            enqueueSnackbar("Could not refresh credentials!", { variant: "error" });
        } else {
            //@ts-ignore
            setToken(data);
        }
    }

    async function setInitials() {
        if (!data) return;
        const pipes = data.pipelines.filter((pipeline) => pipeline.data !== null);
        //if (pipes.length === 0) return;
        const initial: Initials = {
            pipelines: [],
            instances: [],
            expectations: [],
            reality: [],
            index: 0,
        };
        const expectations = await getExpectationHandler(pipes[index].id);
        const reality = await getRealityHandler(pipes[index].id);
        if (expectations && reality) {
            initial.pipelines = pipes;
            initial.expectations = expectations;
            initial.reality = reality;
            initial.instances = pipes[index].data.schema.pipelineInstances.map((instances) => instances.name);
            initial.index = index;

            setProps(initial);
        }
    }

    async function getExpectationHandler(id: string) {
        try {
            setIsLoading(true);
            const response = await getExpectation({
                variables: { cruxyId: selectedCompany.cruxyId, pipelineId: id },
            });
            if (response) {
                setIsLoading(false);
                return response.data.expectations;
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    async function getRealityHandler(id: string) {
        try {
            setIsLoading(true);
            const response = await getReality({
                variables: { cruxyId: selectedCompany.cruxyId, pipelineId: id },
            });
            if (response) {
                setIsLoading(false);
                return response.data.reality;
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const setIndexCb = useMemo(() => setIndex, []);
    const setIsLoadingCb = useMemo(() => setIsLoading, []);

    return (
        <>
            {!noPipelines ? (
                <ChartsMemo
                    token={token}
                    setIndex={setIndexCb}
                    setIsLoading={setIsLoadingCb}
                    user={user}
                    properties={props}
                    selectedCompany={selectedCompany}
                />
            ) : (
                <MDBox
                    sx={{
                        flex: 1,
                        display: "flex",
                        padding: "3rem",
                        ...linearGradientStyle,
                    }}
                >
                    <Typography sx={{ fontWeight: "600", color: colors.white.main, fontSize: "1.25rem" }}>
                        <span style={{ fontWeight: "600", color: colors.primary.main, fontSize: "1.25rem" }}>
                            {selectedCompany.name}
                        </span>{" "}
                        has no pipelines to work with!
                    </Typography>
                </MDBox>
            )}
        </>
    );
}

const ChartsMemo = React.memo(function ChartMemo({
    token,
    user,
    setIndex,
    setIsLoading,
    properties,
    selectedCompany,
}: {
    token: string;
    setIndex: (value: number) => void;
    setIsLoading: (value: boolean) => void;
    user: User;
    properties: Initials;
    selectedCompany: ClientsResponse;
}) {
    return (
        <>
            {properties?.pipelines.length > 0 && properties?.instances.length > 0 && (
                <Charts
                    token={token}
                    setIndex={setIndex}
                    setIsLoading={setIsLoading}
                    user={user}
                    properties={properties}
                    selectedCompany={selectedCompany}
                />
            )}
        </>
    );
});

export default HowAmIProjected;
